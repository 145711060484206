import { style } from 'typestyle';
import {
    CONTENT_MAX_WIDTH_PX,
    MARGINS_PX
} from '../../../constants';

export const contentClassName = style({
    $debugName: 'content.component',
    
    margin: '0 auto',
    width: '100%',
    maxWidth: CONTENT_MAX_WIDTH_PX + 'px',
    boxSizing: 'border-box',
    padding: `0 ${MARGINS_PX}px`
});
