import { LOCAL_STORAGE_DATA_NAME, ROUTE_PAGE_LOGOUT } from '../constants';
import { decode } from '../utils';
import { AppSession, AppState } from './types';

const getInitialSession = (): AppSession | null => {
    const savedSessionData = window.localStorage.getItem(LOCAL_STORAGE_DATA_NAME);
    let parsedData: {
        username: string,
        token: string
    } | null = null;

    if (window.location.pathname !== ROUTE_PAGE_LOGOUT) {
        if (savedSessionData && (parsedData = decode(savedSessionData))) {
            if (parsedData.hasOwnProperty('username') && parsedData.hasOwnProperty('token')) {
                return {
                    username: parsedData.username,
                    token: parsedData.token
                };
            }
        }
    }

    return null;
};

const session = getInitialSession();

export const initialState: AppState = {
    session,
    dataReady: false,
    countryProfiles: [],
    taxonomies: {
        dates: [],
        countries: [],
        typesOfQuota: [],
        sanctions: [],
        electoralSystemTypes: [],
        rankingPlacement: [],
        legalSources: [],
        regions: [],
        quotaForInputOnly: [],
        parliamentStructure: [],
        quotaTargetMet: [],
        provisionsOnGenderQuotas_yesNo: []
    },
    featuredCountryId: null,
    pages: []
};
