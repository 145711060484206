import React from 'react';
import {
    COLOR_01,
    COLOR_10,
    COUNTRY_COMPARISON_RESULTS_MAX_SELECTION,
    NOT_APPLICABLE_TEXT
} from '../../../constants';
import { useAppState } from '../../../state/hook';
import { ComponentWithFilterHandlersProps, CountryProfile, Taxonomies } from '../../../state/types';
import { getLatestDates, getTaxonomyName } from '../../../utils';
import DynamicNumber, { N } from '../../common/dynamic-number/dynamic-number.component';
import { Cell, Grid } from '../../common/grid/grid.component';
import InfoDate from '../../common/info-date/info-date.component';
import LegalProvisions from '../../common/legal-provisions/legal-provisions.component';
import PageSection from '../../common/page-section/page-section.component';
import ProgressBar from '../../common/progress-bar/progress-bar.component';
import ResultsButtonpad from '../../common/results-buttonpad/results-buttonpad.component';
import Table from '../../common/table/table.component';
import {
    countryComparisonResultsTableHeadCellClassName,
    countryComparisonResultsCountryNameClassName,
    countryComparisonResultsCountryNameCaptionClassName,
    countryComparisonResultsListClassName,
    countryComparisonResultsQuotaValueCellClassName
} from './country-comparison-results.style';

interface CountryComparisonResultsProps extends ComponentWithFilterHandlersProps {
    countryProfileList: CountryProfile[]
}

const CountryComparisonResults: React.FC<CountryComparisonResultsProps> = ({
    countryProfileList,
    filters,
    onChangeFilters
}) => {
    const [{taxonomies}] = useAppState();
    const selectedResults = countryProfileList.slice(0, COUNTRY_COMPARISON_RESULTS_MAX_SELECTION);
    const selectedCountryIds = filters.countryIds.length ? filters.countryIds : selectedResults.map(countryProfile => countryProfile.countryId);
    const columnWidthPercentage: number = 100 / Math.min(selectedCountryIds.length, COUNTRY_COMPARISON_RESULTS_MAX_SELECTION);

    const findProfile = (countryId: number) => {
        for (const countryProfile of countryProfileList) {
            if (countryProfile.countryId === countryId) {
                return countryProfile;
            }
        }

        return null;
    };

    const createRow = (
        headerName: string | React.ReactNode,
        keyNameOrContentForIteration: (keyof CountryProfile) | ((result: CountryProfile) => React.ReactNode),
        taxonomyName?: keyof Taxonomies,
        fallbackContent?: (countryId: number) => React.ReactNode
    ) => (
        <tr>
            <th className={ countryComparisonResultsTableHeadCellClassName }>
                { headerName }
            </th>
            { selectedCountryIds.map((countryId, index) => {
                const selectedResult: CountryProfile | null = findProfile(countryId);

                if (!selectedResult) {
                    return (
                        <td key={ index } style={ {width: `${columnWidthPercentage}%`} }>
                            { fallbackContent && fallbackContent(countryId) }
                        </td>
                    )
                }

                if (typeof keyNameOrContentForIteration === 'string') {
                    if (taxonomyName) {
                        return (
                            <td key={ index } style={ {width: `${columnWidthPercentage}%`} }>
                                { getTaxonomyName(
                                    taxonomies,
                                    taxonomyName,
                                    selectedResult[keyNameOrContentForIteration] as number
                                ) }
                            </td>
                        )
                    }
                    else {
                        return (
                            <td key={ index }>
                                { String(selectedResult[keyNameOrContentForIteration]) }
                            </td>
                        )
                    }
                }
                else {
                    return (
                        <td key={ index }>
                            { keyNameOrContentForIteration(selectedResult) }
                        </td>
                    );
                }
            }) }
        </tr>
    );

    const downloadDataHeaders = ['Country', ...selectedResults.map(profile => getTaxonomyName(taxonomies, 'countries', profile.countryId))];
    const resultsButtonpad = (
        <ResultsButtonpad
            pdf={ {columnSizePercentages: [10, ...downloadDataHeaders.map(() => 90 / (downloadDataHeaders.length - 1))]} }
            downloadData={ {
                fileName: 'country-comparison',
                fileTitle: 'Country comparison',
                headers: downloadDataHeaders,
                content: [
                    ['Parliament structure', ...selectedResults.map(profile => getTaxonomyName(taxonomies, 'parliamentStructure', profile.parliamentStructureId))],
                    ['Electoral system', ...selectedResults.map(profile => getTaxonomyName(taxonomies, 'electoralSystemTypes', profile.electoralSystemTypeId))],
                    ['Electoral system (sub-type)', ...selectedResults.map(profile => profile.electoralSystemSubType)],
                    ['Type of legislated quota', ...selectedResults.map(profile => profile.typeOfQuotaIds.map(typeOfQuotaId => getTaxonomyName(taxonomies, 'typesOfQuota', typeOfQuotaId)).join(', '))],
                    ['Quota target', ...selectedResults.map(profile => profile.quotaTarget + '%')],
                    ['Women\'s representation', ...selectedResults.map(profile => profile.womensRepresentation === null ? NOT_APPLICABLE_TEXT : profile.womensRepresentation + '%')],
                    ['Quota met', ...selectedResults.map(profile => getTaxonomyName(taxonomies, 'quotaTargetMet', profile.quotaTargetMetId))],
                    ['Provision: Constitution', ...selectedResults.map(profile => `${profile.constitutionLegalSource}. ${profile.constitutionLegalProvisions}`)],
                    ['Provision: Electoral law', ...selectedResults.map(profile => `${profile.electoralLegalSource}. ${profile.electoralLegalProvisions}`)],
                    ['Provision: Party law', ...selectedResults.map(profile => `${profile.partyLegalSource}. ${profile.partyLegalProvisions}`)],
                    ['Provision: Parity law', ...selectedResults.map(profile => `${profile.parityLegalSource}. ${profile.parityLegalProvisions}`)],
                    ['Provision: Financing law', ...selectedResults.map(profile => `${profile.financingLegalSource}. ${profile.financingLegalProvisions}`)],
                    ['Provision: Political agreement', ...selectedResults.map(profile => `${profile.politicalLegalSource}. ${profile.politicalLegalProvisions}`)],
                ]
            } }
            date={ filters.date }
            onChangeDate={ date => onChangeFilters({
                ...filters, date
            }) }/>
    );

    console.log({selectedResults});

    return (
        <PageSection title='Country comparison results' aside={ resultsButtonpad }>
            <div id='print'>
                <Table>
                    <tbody>
                        { createRow('Country', result => (
                            <span className={ countryComparisonResultsCountryNameClassName }>
                                { getTaxonomyName(taxonomies, 'countries', result.countryId) }
                            </span>
                        ), undefined, countryId => (
                            <>
                                <span className={ countryComparisonResultsCountryNameClassName }>
                                    { getTaxonomyName(taxonomies, 'countries', countryId) }
                                </span>
                                <span className={ countryComparisonResultsCountryNameCaptionClassName }>
                                    No gender quota for selected year
                                </span>
                            </>
                        )) }
                        { createRow('Parliament structure', 'parliamentStructureId', 'parliamentStructure') }
                        { createRow('Electoral system', 'electoralSystemTypeId', 'electoralSystemTypes') }
                        { createRow('Electoral system (sub-type)', 'electoralSystemSubType') }
                        { createRow('Type of legislated quota', result => (
                            result.typeOfQuotaIds.map((typeOfQuotaId, index) => (
                                <div key={ index }>
                                    { getTaxonomyName(taxonomies, 'typesOfQuota', typeOfQuotaId) }
                                </div>
                            ))
                        ) ) }
                        { createRow((
                            <ul className={ countryComparisonResultsListClassName }>
                                <li><div>Quota target</div></li>
                                <li><div>Women's representation</div></li>
                            </ul>
                        ), result => (
                            <>
                                <Grid>
                                    <Cell size={ 6 }>
                                        <ProgressBar progress={ result.quotaTarget } color={ COLOR_10 }/>
                                    </Cell>
                                    <Cell size={ 6 } className={ countryComparisonResultsQuotaValueCellClassName }>
                                        <N>{ result.quotaTarget }</N>%
                                    </Cell>
                                </Grid>
                                <Grid>
                                    <Cell size={ 6 }>
                                        { (result.womensRepresentation !== null) && (
                                            <ProgressBar progress={ result.womensRepresentation || 0 } color={ COLOR_01 }/>
                                        ) }
                                    </Cell>
                                    <Cell size={ 6 } className={ countryComparisonResultsQuotaValueCellClassName }>
                                        <span>
                                            {
                                                result.womensRepresentation === null
                                                ? NOT_APPLICABLE_TEXT
                                                : <><DynamicNumber value={ result.womensRepresentation }/>%</>
                                            }
                                        </span>
                                    </Cell>
                                </Grid>
                            </>
                        )) }
                        { createRow('Quota met', 'quotaTargetMetId', 'quotaTargetMet') }
                        { createRow((
                            <>
                                Provisions
                                <div>
                                    Note: Please click on the provisions to view and compare
                                </div>
                            </>
                        ), result => (
                            <LegalProvisions countryProfile={ result } showAll/>
                        )) }
                    </tbody>
                </Table>
                <InfoDate dates={ getLatestDates(selectedResults) }/>
            </div>
        </PageSection>
    );
};

export default CountryComparisonResults;
