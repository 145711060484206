import React, { useState } from 'react';
import { classes } from 'typestyle';
import {
    iconSortDown,
    iconSortDownActive,
    iconSortUp,
    iconSortUpActive
} from '../../../assets';
import { useAppState } from '../../../state/hook';
import { ComponentWithFilterHandlersProps, CountryProfile, CountryProfileResult, Taxonomies } from '../../../state/types';
import {
    getCandidateQuotaDetails,
    getTaxonomyName,
    getReservedSeatsDetails,
    getLatestDates
} from '../../../utils';
import InfoDate from '../../common/info-date/info-date.component';
import PageSection from '../../common/page-section/page-section.component';
import ResultsButtonpad from '../../common/results-buttonpad/results-buttonpad.component';
import Table from '../../common/table/table.component';
import {
    countryResultsTableImportantTextClassName,
    countryResultsTableImportantTextBlueClassName,
    countryResultsTableHeadClassName,
    countryResultsTableHeadSortableClassName,
    countryResultsTableSortableIconsClassName,
    countryResultsEmptyResultMessageClassName
} from './quota-analysis-results.style';
import { MESSAGE_NOT_AVAILABLE_IN_DATE, MESSAGE_NO_RESULTS, NOT_APPLICABLE_TEXT } from '../../../constants';

interface CountryResultsProps extends ComponentWithFilterHandlersProps {
    results: CountryProfileResult[]
}

const CountryResults: React.FC<CountryResultsProps> = ({
    results,
    filters,
    onChangeFilters
}) => {
    const [{taxonomies}] = useAppState();
    const [sortConfig, changeSortConfig] = useState<{
        propName: keyof CountryProfile,
        ascendant: boolean,
        taxonomy: keyof Taxonomies | null
    }>({
        propName: 'countryId',
        ascendant: true,
        taxonomy: 'countries'
    });

    const compareValues = (valueA: string | number, valueB: string | number): 1 | -1 => (
        sortConfig.ascendant
            ? (valueA > valueB ? 1 : -1)
            : (valueA > valueB ? -1 : 1)
    );

    results.sort((a, b) => {
        if ((sortConfig.taxonomy !== null) && (sortConfig.propName === 'countryId')) {
            return compareValues(
                getTaxonomyName(taxonomies, sortConfig.taxonomy, a.countryId),
                getTaxonomyName(taxonomies, sortConfig.taxonomy, b.countryId)
            );
        }
        else if (a.countryProfile && b.countryProfile) {
            const propValueA = a.countryProfile[sortConfig.propName];
            const propValueB = b.countryProfile[sortConfig.propName];

    
            if (sortConfig.taxonomy) {
                const taxonomyName = sortConfig.taxonomy;
    
                if (typeof propValueA === 'number' && typeof propValueB === 'number') {
                    return compareValues(
                        getTaxonomyName(taxonomies, taxonomyName, propValueA),
                        getTaxonomyName(taxonomies, taxonomyName, propValueB)
                    );
                }
    
                if (Array.isArray(propValueA) && Array.isArray(propValueB)) {
                    return compareValues(
                        propValueA.map(id => getTaxonomyName(taxonomies, taxonomyName, id)).join(', '),
                        propValueB.map(id => getTaxonomyName(taxonomies, taxonomyName, id)).join(', ')
                    );
                
                }
            }
    
            if (!Array.isArray(propValueA) && !Array.isArray(propValueB)) {
                return compareValues(propValueA || 0, propValueB || 0);
            }

            return 0;
        }
        
        return 1;
    });

    const headContent = (
        headName: string,
        sortableProperty?: keyof CountryProfile,
        sortableTaxonomy?: keyof Taxonomies
    ) => {
        return (
            <span
                className={ classes(countryResultsTableHeadClassName, sortableProperty && countryResultsTableHeadSortableClassName) }
                { ...(sortableProperty ? {
                    onClick: event => {
                        event.preventDefault();

                        changeSortConfig({
                            propName: sortableProperty,
                            ascendant: sortConfig.propName === sortableProperty ? !sortConfig.ascendant : true,
                            taxonomy: sortableTaxonomy || null
                        });
                    }
                } : {}) }
            >
                { headName }
                { sortableProperty && (
                    <div className={ countryResultsTableSortableIconsClassName }>
                        <img src={ (sortConfig.propName === sortableProperty) && sortConfig.ascendant ? iconSortUpActive : iconSortUp } alt="Sort up"/>
                        <img src={ (sortConfig.propName === sortableProperty) && !sortConfig.ascendant ? iconSortDownActive : iconSortDown } alt="Sort down"/>
                    </div>
                ) }
            </span>
        );
    };

    const bodyContent = (content: React.ReactNode | string) => (
        <span>
            { content }
        </span>
    );

    const resultsButtonpad = (
        <ResultsButtonpad pdf csv
            downloadData={ {
                fileTitle: 'Quota Analysis',
                fileName: 'quota-analysis',
                headers: [
                    'Country',
                    'Type of quota',
                    'Quota target',
                    "Women's representation",
                    'Placement rules for candidate quotas',
                    'Sanctions for candidate quotas',
                    'Methods of filling reserved seats',
                    'Electoral system'
                ],
                content: results.map(result => {
                    if (result.countryProfile) {
                        const profile = result.countryProfile;
                        return [
                            getTaxonomyName(taxonomies, 'countries', profile.countryId),
                            profile.typeOfQuotaIds.map(typeOfQuotaId => getTaxonomyName(taxonomies, 'typesOfQuota', typeOfQuotaId)).join(', '),
                            profile.quotaTarget + '%',
                            profile.womensRepresentation === null ? NOT_APPLICABLE_TEXT : (profile.womensRepresentation + '%'),
                            getCandidateQuotaDetails(profile),
                            getTaxonomyName(taxonomies, 'sanctions', profile.sanctionId),
                            getReservedSeatsDetails(profile),
                            getTaxonomyName(taxonomies, 'electoralSystemTypes', profile.electoralSystemTypeId)
                        ]
                    }
                    else {
                        return [
                            getTaxonomyName(taxonomies, 'countries', result.countryId),
                            MESSAGE_NOT_AVAILABLE_IN_DATE,
                            '',
                            '',
                            '',
                            '',
                            '',
                            ''
                        ];
                    }
                })
            } }
            date={ filters.date }
            onChangeDate={ date => onChangeFilters({
                ...filters, date
            }) }
            />
    );

    return (
        <PageSection
            title='Country information results'
            aside={ resultsButtonpad }
            children={
                <div id='print'>
                    <Table>
                        <thead>
                            <tr>
                                <th>{ headContent('Country', 'countryId', 'countries') }</th>
                                <th>{ headContent('Type of quota', 'typeOfQuotaIds', 'typesOfQuota') }</th>
                                <th>{ headContent('Quota target', 'quotaTarget') }</th>
                                <th>{ headContent('Women\'s Representation', 'womensRepresentation') }</th>
                                <th>{ headContent('Placement rules for candidate quotas') }</th>
                                <th>{ headContent('Sanctions for candidate quotas') }</th>
                                <th>{ headContent('Methods of filling reserved seats') }</th>
                                <th>{ headContent('Electoral system') }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { results.length ? (
                                results.map((countryResult, i) => {
                                    const countryProfile = countryResult.countryProfile;

                                    return (
                                        <tr key={ i }>
                                            <td>
                                                { bodyContent(<>
                                                    <span className={ countryResultsTableImportantTextClassName }>
                                                        { getTaxonomyName(taxonomies, 'countries', countryResult.countryId) }
                                                    </span>
                                                </>) }
                                            </td>
                                            { countryProfile ? (
                                                <>
                                                    <td>{ bodyContent(countryProfile.typeOfQuotaIds.map(typeOfQuotaId => getTaxonomyName(taxonomies, 'typesOfQuota', typeOfQuotaId)).join(', ')) }</td>
                                                    <td>
                                                        { bodyContent(
                                                            <span className={ countryResultsTableImportantTextClassName }>
                                                                { countryProfile.quotaTarget }%
                                                            </span>
                                                        ) }
                                                    </td>
                                                    <td>
                                                        { bodyContent(
                                                            <span className={ countryResultsTableImportantTextBlueClassName }>
                                                                {
                                                                    countryProfile.womensRepresentation === null
                                                                        ? NOT_APPLICABLE_TEXT
                                                                        : countryProfile.womensRepresentation + '%'
                                                                }
                                                            </span>
                                                        ) }
                                                    </td>
                                                    <td>{ bodyContent(getCandidateQuotaDetails(countryProfile)) }</td>
                                                    <td>{ bodyContent(getTaxonomyName(taxonomies, 'sanctions', countryProfile.sanctionId)) }</td>
                                                    <td>{ bodyContent(getReservedSeatsDetails(countryProfile)) }</td>
                                                    <td>{ bodyContent(getTaxonomyName(taxonomies, 'electoralSystemTypes', countryProfile.electoralSystemTypeId)) }</td>
                                                </>
                                            ) : (
                                                <td colSpan={ 7 }>
                                                    <span className={ countryResultsEmptyResultMessageClassName }>
                                                        { countryResult.message }
                                                    </span>
                                                </td>
                                            ) }
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={ 8 }>
                                        <span className={ countryResultsEmptyResultMessageClassName }>
                                            { MESSAGE_NO_RESULTS } 
                                        </span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <InfoDate dates={ 
                        getLatestDates(
                            results
                                .map(result => result.countryProfile)
                                .filter(profile => profile !== null) as CountryProfile[]
                        )
                    }/>
                </div>
            }/>
    )
};

export default CountryResults;
