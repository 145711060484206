import React from 'react';
import { classes } from 'typestyle';
import { iconSearch } from '../../../assets';
import {
    fieldWrapperClassName,
    inputIconClassName,
    inputClassName,
    inputIconLeftClassName
} from './form.style';

interface SearchFieldProps {
    value: string,
    placeholder?: string,
    onChange(newValue: string): void,
    onEnterKey?(currentValue: string): void,
    onKeyDown?(event: React.KeyboardEvent): void
    onFocus?(event: React.FocusEvent): void
    onBlur?(event: React.FocusEvent): void
}

const SearchField: React.FC<SearchFieldProps> = ({
    value,
    placeholder,
    onChange,
    onEnterKey,
    onKeyDown,
    onFocus,
    onBlur
}) => {
    const onKeyDownEvent: React.InputHTMLAttributes<HTMLInputElement> = (onEnterKey || onKeyDown) ? {
        onKeyDown: event => {
            if (onEnterKey && (event.key === 'Enter')) {
                event.preventDefault();
                onEnterKey(value);
            }

            if (onKeyDown) {
                onKeyDown(event);
            }
        }
    } : {};

    const onFocusEvent: React.InputHTMLAttributes<HTMLInputElement> = onFocus ? {
        onFocus
    } : {};

    const onBlurEvent: React.InputHTMLAttributes<HTMLInputElement> = onBlur ? {
        onBlur
    } : {};

    return (
        <div className={ fieldWrapperClassName }>
            <div className={ classes(inputIconClassName, inputIconLeftClassName) }>
                <img
                    alt='Search'
                    src={ iconSearch }
                    height='15px'/>
            </div>
            <input
                type='text'
                className={ inputClassName }
                value={ value }
                placeholder={ placeholder }
                onChange={ event => {
                    onChange(event.target.value)
                } }
                { ...onKeyDownEvent }
                { ...onFocusEvent }
                { ...onBlurEvent }/>
        </div>
    );
};

export default SearchField;
