import { style } from 'typestyle';
import {
    COLOR_01,
    COLOR_12,
    COLOR_20,
    FONT_MAIN,
    FONT_WEIGHT_LIGHT,
    FONT_WEIGHT_MEDIUM,
    FONT_WEIGHT_REGULAR,
    MARGINS_PX,
    PAGE_MENU_HEIGHT_PX
} from '../../../constants';
import { desktopStyles, mobileOnlyStyles, tabletStyles } from '../../../utils';

const menuButtonWidthPx = 50;

export const mainNavClassName = style({
    display: 'block',
    position: 'relative',
    height: '100%',
    width: menuButtonWidthPx + 'px'
}, tabletStyles({
    width: 'auto'
}));

export const mainNavWrapperClassName = style({
    display: 'block',
    position: 'fixed',
    width: '100%',
    maxWidth: '480px',
    background: 'rgba(255, 255, 255, .8)',
    top: 0,
    left: '100%',
    height: '100%',
    padding: `${PAGE_MENU_HEIGHT_PX}px 0 0 0`,
    boxSizing: 'border-box',
    borderLeft: 'solid 1px #cdcdcd',
    transition: 'transform 400ms'
}, tabletStyles({
    position: 'relative',
    borderLeft: 'none',
    left: 0,
    maxWidth: 'none',
    padding: 0,
    background: 'transparent',
    backdropFilter: 'none',
    transition: 'none'
}));

export const mainNavOptionsClassName = style({
    height: '100%'
}, tabletStyles({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative'
}));

export const mainNavOptionItemClassName = style({
}, tabletStyles({
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    marginLeft: MARGINS_PX + 'px'
}), desktopStyles({
    marginRight: MARGINS_PX + 'px'
}));

export const mainNavOptionClassName = style({
    display: 'block',
    padding: `${MARGINS_PX}px ${MARGINS_PX * 2}px`,
    fontSize: '16px',
    borderTop: 'solid 1px #cdcdcd',
    borderBottom: 'solid 1px #cdcdcd',
    margin: '-1px 0 0 0',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_REGULAR,
    color: COLOR_12,
    position: 'relative'
}, tabletStyles({
    display: 'inline-block',
    position: 'static',
    padding: 0,
    margin: 0,
    border: 'none',
    fontSize: '14px',
    $nest: {
        '&:hover': {
            color: '#656565'
        }
    }
}), desktopStyles({
    fontSize: '16px'
}));

export const mainNavOptionUnderlineClassName = style({
    display: 'block',
    width: '4px',
    height: '100%',
    background: 'transparent',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'none'
}, tabletStyles({
    height: '4px',
    width: 0,
    background: 'transparent',
    left: 'auto',
    top: 'auto',
    bottom: 0,
    right: 0,
    transition: 'width 300ms'
}));

export const mainNavOptionActiveClassName = style({
    fontWeight: FONT_WEIGHT_MEDIUM,

    $nest: {
        [`.${mainNavOptionUnderlineClassName}`]: {
            background: COLOR_01
        }
    }
}, tabletStyles({
    $nest: {
        [`.${mainNavOptionUnderlineClassName}`]: {
            width: '100%'
        }
    }
}));

export const mainNavToggleButtonClassName = style({
    display: 'block',
    position: 'absolute',
    background: 'transparent',
    width: '50px',
    height: '100%',
    right: 0,
    border: 'none',
    cursor: 'pointer',

    $nest: {
        '& > span': {
            display: 'block',
            width: '60%',
            height: '4px',
            borderRadius: '2px',
            background: '#979797',
            left: '20%',
            top: 'calc(50% - 2px)',
            position: 'absolute',
            transition: '400ms',

            $nest: {
                '&:nth-child(1)': {
                    transform: 'translateY(-8px)'
                },
                '&:nth-child(3)': {
                    transform: 'translateY(8px)'
                }
            }
        },
        '&:hover > span': {
            background: '#656565'
        }
    }
}, tabletStyles({
    display: 'none'
}));

export const mainNavActiveBackgroundClassName = style({
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, .4)',
    backdropFilter: 'blur(10px)',
    opacity: 0,
    pointerEvents: 'none',
    transition: '400ms'
}, tabletStyles({
    display: 'none'
}));

export const mainNavActiveClassName = style(mobileOnlyStyles({
    $nest: {
        [`& > .${mainNavActiveBackgroundClassName}`]: {
            opacity: 1,
            pointerEvents: 'all'
        },
        [`& > .${mainNavWrapperClassName}`]: {
            transform: 'translateX(-100%)'
        },
        [`& > .${mainNavToggleButtonClassName} > span`]: {
            $nest: {
                '&:nth-child(1)': {
                    width: '50%',
                    left: '25%',
                    transform: 'rotate(-45deg)'
                },
                '&:nth-child(2)': {
                    width: '4px',
                    left: 'calc(50% - 2px)'
                },
                '&:nth-child(3)': {
                    width: '50%',
                    left: '25%',
                    transform: 'rotate(45deg)'
                }
            }
        }
    }
}));
