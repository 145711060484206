import React from 'react';
import { classes } from 'typestyle';
import {
    gridClassName,
    cellClassName,
    cellStackClassName,
    cellFillClassName,
    cellSize01ClassName,
    cellSize02ClassName,
    cellSize03ClassName,
    cellSize04ClassName,
    cellSize05ClassName,
    cellSize06ClassName,
    cellSize07ClassName,
    cellSize08ClassName,
    cellSize09ClassName,
    cellSize10ClassName,
    cellSize11ClassName,
    cellSize12ClassName,
    cellSizeTablet01ClassName,
    cellSizeTablet02ClassName,
    cellSizeTablet03ClassName,
    cellSizeTablet04ClassName,
    cellSizeTablet05ClassName,
    cellSizeTablet06ClassName,
    cellSizeTablet07ClassName,
    cellSizeTablet08ClassName,
    cellSizeTablet09ClassName,
    cellSizeTablet10ClassName,
    cellSizeTablet11ClassName,
    cellSizeTablet12ClassName,
    cellSizeDesktop01ClassName,
    cellSizeDesktop02ClassName,
    cellSizeDesktop03ClassName,
    cellSizeDesktop04ClassName,
    cellSizeDesktop05ClassName,
    cellSizeDesktop06ClassName,
    cellSizeDesktop07ClassName,
    cellSizeDesktop08ClassName,
    cellSizeDesktop09ClassName,
    cellSizeDesktop10ClassName,
    cellSizeDesktop11ClassName,
    cellSizeDesktop12ClassName,
    cellStackTabletClassName,
    cellFillTabletClassName,
    cellStackDesktopClassName,
    cellFillDesktopClassName
} from './grid.style';

interface GridProps {
    children?: React.ReactNode
}

interface CellProps {
    size?: CellSize,
    sizeTablet?: CellSize,
    sizeDesktop?: CellSize,
    children?: React.ReactNode,
    className?: string
}

type CellSize = CellBehaviour | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type CellBehaviour = 'stack' | 'fill';

export const Grid: React.FC<GridProps> = ({
    children
}) => (
    <div className={ gridClassName }>
        { children }
    </div>
);

export const Cell: React.FC<CellProps> = ({
    size = 'stack',
    sizeTablet,
    sizeDesktop,
    children,
    className
}) => (
    <div
        className={ classes(
            className,
            cellClassName,
            (() => {
                switch(size) {
                    case 'stack': return cellStackClassName;
                    case 'fill': return cellFillClassName;
                    case 1: return cellSize01ClassName;
                    case 2: return cellSize02ClassName;
                    case 3: return cellSize03ClassName;
                    case 4: return cellSize04ClassName;
                    case 5: return cellSize05ClassName;
                    case 6: return cellSize06ClassName;
                    case 7: return cellSize07ClassName;
                    case 8: return cellSize08ClassName;
                    case 9: return cellSize09ClassName;
                    case 10: return cellSize10ClassName;
                    case 11: return cellSize11ClassName;
                    case 12: return cellSize12ClassName;
                }
            })(),
            (() => {
                switch(sizeTablet) {
                    case 'stack': return cellStackTabletClassName;
                    case 'fill': return cellFillTabletClassName;
                    case 1: return cellSizeTablet01ClassName;
                    case 2: return cellSizeTablet02ClassName;
                    case 3: return cellSizeTablet03ClassName;
                    case 4: return cellSizeTablet04ClassName;
                    case 5: return cellSizeTablet05ClassName;
                    case 6: return cellSizeTablet06ClassName;
                    case 7: return cellSizeTablet07ClassName;
                    case 8: return cellSizeTablet08ClassName;
                    case 9: return cellSizeTablet09ClassName;
                    case 10: return cellSizeTablet10ClassName;
                    case 11: return cellSizeTablet11ClassName;
                    case 12: return cellSizeTablet12ClassName;
                }
            })(),
            (() => {
                switch(sizeDesktop) {
                    case 'stack': return cellStackDesktopClassName;
                    case 'fill': return cellFillDesktopClassName;
                    case 1: return cellSizeDesktop01ClassName;
                    case 2: return cellSizeDesktop02ClassName;
                    case 3: return cellSizeDesktop03ClassName;
                    case 4: return cellSizeDesktop04ClassName;
                    case 5: return cellSizeDesktop05ClassName;
                    case 6: return cellSizeDesktop06ClassName;
                    case 7: return cellSizeDesktop07ClassName;
                    case 8: return cellSizeDesktop08ClassName;
                    case 9: return cellSizeDesktop09ClassName;
                    case 10: return cellSizeDesktop10ClassName;
                    case 11: return cellSizeDesktop11ClassName;
                    case 12: return cellSizeDesktop12ClassName;
                }
            })()
        ) }
        children={ children }/>
);
