import { style } from 'typestyle';
import { COLOR_12, COLOR_WHITE } from '../../../constants';

export const checkboxClassName = style({
    display: 'inline-block',
    width: '14px',
    height: '14px',
    boxSizing: 'border-box',
    border: `solid 1px ${COLOR_12}`,
    backgroundColor: COLOR_WHITE,
    cursor: 'pointer'
});

export const checkboxActiveClassName = style({
    backgroundColor: COLOR_12
});

export const checkboxDisabledClassName = style({
    opacity: .3,
    cursor: 'not-allowed'
});
