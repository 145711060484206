import React from 'react';
import {
    sectionInfoClassName,
    sectionInfoImageClassName,
    sectionInfoTextClassName
} from './section-info.style';

interface SectionInfo {
    imageUrl?: string,
    imageText?: string,
    children?: React.ReactNode
}

const SectionInfo: React.FC<SectionInfo> = ({
    imageUrl,
    imageText,
    children
}) => (
    <div className={ sectionInfoClassName }>
        { imageUrl && (
            <div className={ sectionInfoImageClassName }>
                <img src={ imageUrl } alt={ imageText }/>
                <span>{ imageText }</span>
            </div>
        ) }

        <div className={ sectionInfoTextClassName }>
            { children }
        </div>
    </div>
);

export default SectionInfo;
