import { style } from 'typestyle';
import {
    COLOR_05,
    COLOR_07,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';
import { mixinFont } from '../../../style.mixin';

export const countryResultsClassName = style({
    borderTop: `solid 1px ${COLOR_07}`,
    padding: `${MARGINS_PX * 2}px 0`
});

export const countryResultsTableWrapperClassName = style({
    margin: `${MARGINS_PX * 2}px 0 0 0`
});

export const countryResultsTableImportantTextClassName = style({
    display: 'block',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: FONT_WEIGHT_BOLD
});

export const countryResultsTableImportantTextBlueClassName = style({
    display: 'block',
    fontSize: '16px',
    fontWeight: FONT_WEIGHT_BOLD,
    color: COLOR_05
});

export const countryResultsTableHeadClassName = style({
    fontSize: '11px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    userSelect: 'none'
});

export const countryResultsTableHeadSortableClassName = style({
    cursor: 'pointer',
    display: 'block',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '8px 8px 8px 0',
});

export const countryResultsTableSortableIconsClassName = style({
    display: 'block',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '6px',
    pointerEvents: 'none',

    $nest: {
        'img': {
            display: 'block',
            border: 0,
            margin: '2px 0',
            padding: 0,
            width: '6px'
        }
    }
});

export const countryResultsEmptyResultMessageClassName = style({
    display: 'block',
    margin: '2px 0 0 0',
    ...mixinFont(12, FONT_WEIGHT_MEDIUM)
});
