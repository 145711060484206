import React, {
    createRef,
    useEffect,
    useState
} from 'react';
import {
    donutClassName,
    donutInnerClassName,
    donutCoreClassName,
    donutOverlayClassName,
    donutOverlayCenterClassName,
    donutOverlayContentClassName
} from './donut.style';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';
import { createPortal } from 'react-dom';

ChartJS.register(ArcElement);

interface DonutProps {
    children?: React.ReactNode,
    values: DonutValue[],
    colors: string[]
    maxValue?: number,
    centerColor?: string,
    tooltipId?: string
}

type DonutValue = {
    amount: number,
    tooltipData?: string
};

const Donut: React.FC<DonutProps> = ({
    children,
    values,
    colors,
    maxValue,
    centerColor,
    tooltipId
}) => {
    const elementRef = createRef<HTMLDivElement>();
    const [hoveredDataIndex, changeHoveredDataIndex] = useState<number>(-1);
    const valueAmounts = values.map(donutValue => donutValue.amount);
    const maxValueData: number | undefined = maxValue && (
        maxValue - valueAmounts.reduce((a, b) => a + b, 0)
    );
    const data = [
        ...valueAmounts,
        maxValueData
    ];
    const backgroundColor = [
        ...colors,
        'transparent'
    ];

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [hoveredDataIndex]);
        
    return (
        <div ref={ elementRef } className={ donutClassName } { ...(tooltipId ? {
            'data-tip': '',
            'data-for': tooltipId
        } : {}) }>
            <div className={ donutInnerClassName }>
                <div className={ donutCoreClassName }>
                    <Pie
                        data={ {
                            datasets: [
                                {
                                    data,
                                    backgroundColor,
                                    borderWidth: 0
                                },
                            ],
                        } }
                        options={ {
                            onHover(event, elements) {
                                const element = elements[0];

                                if (!element) {
                                    changeHoveredDataIndex(-1);
                                    return;
                                }

                                if (
                                    element
                                    && (hoveredDataIndex !== element.index)
                                ) {
                                    changeHoveredDataIndex(element.index);
                                    ReactTooltip.show(elementRef.current as Element);
                                    
                                }
                            }
                        } }/>
                </div>
                <div className={ donutOverlayClassName }>
                    <div className={ donutOverlayCenterClassName } style={ centerColor ? {backgroundColor: centerColor} : {} }>
                        { children && (
                            <div className={ donutOverlayContentClassName }>
                                { children }
                            </div>
                        ) }
                    </div>

                </div>
            </div>
            { tooltipId && (
                createPortal(
                    (
                        <ReactTooltip
                            effect='solid'
                            multiline={ true }
                            delayShow={ 300 }
                            place='right'
                            className='app-tooltip'
                            id={ tooltipId }
                            overridePosition={ position => ({
                                left: position.left,
                                top: position.top
                            }) }
                            getContent={ () => values[hoveredDataIndex] ? values[hoveredDataIndex].tooltipData : undefined }
                        />
                    ),
                    document.querySelector('body') as HTMLBodyElement
                )
            ) }
        </div>
    );
};

export default Donut;
