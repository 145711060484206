import React from 'react';
import {
    LoggedRouter
} from './routes';
import {
    getTaxonomies,
    getCountryResults,
    getPages
} from './api/api';
import { useAppState } from './state/hook';
import SiteWrapper from './components/common/site-wrapper/site-wrapper.component';
import SiteHeader from './components/common/site-header/site-header.component';
import SiteContent from './components/common/site-content/site-content.component';
import SiteFooter from './components/common/site-footer/site-footer.component';
import { getDateList, getDateOptions } from './utils';
import Page from './components/common/page/page.component';
import Spinner from './components/common/spinner/spinner.component';
import ReactTooltip from 'react-tooltip';

function App() {
    const [{
        dataReady
    }, {
        setState
    }] = useAppState();

    React.useEffect(() => {
        (async () => {
            try {
                const taxonomies = await getTaxonomies();
                const countryProfiles = await getCountryResults();
                const pages = await getPages();

                setState({
                    countryProfiles,
                    taxonomies,
                    pages,
                    dataReady: true
                });
            }
            catch (error) {
                console.error('ERROR INITIALIZING APP')
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SiteWrapper>
            { dataReady ? (
                <>
                    <SiteHeader/>
                    <SiteContent>
                        <LoggedRouter/>
                    </SiteContent>
                </>
            ) : (
                <Page vCentered>
                    <Spinner/>
                </Page>
            ) }
            <SiteFooter/>
            <ReactTooltip
                effect='solid'
                multiline={ true }
                delayShow={ 300 }
                place='right'
                className='app-tooltip'/>
        </SiteWrapper>
    );
}

export default App;
