import React from 'react';
import { commentsClassName } from './comments.style';

interface CommentsProps {
    children?: React.ReactNode
}

const Comments: React.FC<CommentsProps> = ({
    children
}) => (
    <div className={ commentsClassName }>
        { children }
    </div>
);

export default Comments;
