import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { classes } from 'typestyle';
import {
    LEGAL_SOURCE_TRUE_ID,
    QUOTA_TYPE_CANDIDATE_QUOTA_ID,
    NOT_APPLICABLE_TEXT,
    QUOTA_TYPE_RESERVED_SEATS_ID,
    ROUTE_PAGE_COUNTRY_PROFILE
} from '../../../constants';
import { useAppFilters, useAppState } from '../../../state/hook';
import { CountryProfile, Taxonomy } from '../../../state/types';
import {
    getDefaultSelectedCountryId,
    getTaxonomyName,
    getTaxonomyNameList,
    textPrefix
} from '../../../utils';
import Box from '../../common/box/box.component';
import Comments from '../../common/comments/comments.component';
import Content from '../../common/content/content.component';
import CountryProfileDonuts from '../../common/country-profile-donuts/country-profile-donuts.component';
import { Cell, Grid } from '../../common/grid/grid.component';
import Icon from '../../common/icon/icon.component';
import InfoDate from '../../common/info-date/info-date.component';
import LegalProvisions from '../../common/legal-provisions/legal-provisions.component';
import PageHeader from '../../common/page-header/page-header.component';
import Page from '../../common/page/page.component';
import ResultsButtonpad from '../../common/results-buttonpad/results-buttonpad.component';
import Spacer from '../../common/spacer/spacer.component';
import TaxonomyListGrouped from '../../common/taxonomy-list/taxonomy-list-grouped.component';
import {
    countryProfileClassName,
    countryProfileFiltersCellClassName,
    countryProfileContentCellClassName,
    countryProfileFilterCountriesClassName,
    countryProfileDataWrapperClassName,
    countryProfileDataHeaderClassName,
    countryProfileDataContent1ClassName,
    countryProfileDataContent2ClassName,
    countryProfileCountryNameClassName,
    countryProfileRegionNameClassName,
    countryProfileSectionTitleClassName,
    countryProfileSectionContentClassName,
    countryProfileDataContentBoxClassName,
    countryProfileImplementationTitleClassName,
    countryProfileImplementationDescriptionClassName,
    countryProfileCheckListClassName,
    countryProfileCheckListActiveItemClassName,
    countryProfileCheckListItemClassName,
    countryProfileLegalProvisionsWrapperClassName,
    countryProfileDonutsWrapperClassName,
    countryProfilePageSmallTextClassName,
    noResultsTitleClassName,
    quotaTypeNameClassName
} from './country-profile-page.style';

const CountryProfilePage: React.FC = () => {
    const [{
        taxonomies,
        countryProfiles
    }, {
        getCountryProfile,
        getPage
    }] = useAppState();
    const [filters, changeFilters] = useAppFilters();
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const page = getPage('Country Profiles')
    const countryIdFromParams = Number(params.get('countryId'));
    const countryId: number | null = countryIdFromParams || getDefaultSelectedCountryId(countryProfiles);
    const countryName: string | null = countryId ? getTaxonomyName(taxonomies, 'countries', countryId) : null;
    const selectedCountryInfo = countryId
        ? (getCountryProfile(countryId, filters.date))
        : null;

    const ifCountryInfo = (callback: (profile: CountryProfile) => string): string => {
        if (selectedCountryInfo) {
            return callback(selectedCountryInfo);
        }

        return '';
    };

    const selectedCountryInfoTaxonomyNames = {
        country: ifCountryInfo(profile => getTaxonomyName(taxonomies, 'countries', profile.countryId)),
        region: ifCountryInfo(profile => getTaxonomyName(taxonomies, 'regions', profile.regionId)),
        parliamentStructure: ifCountryInfo(profile => getTaxonomyName(taxonomies, 'parliamentStructure', profile.parliamentStructureId)),
        electoralSystemType: ifCountryInfo(profile => getTaxonomyName(taxonomies, 'electoralSystemTypes', profile.electoralSystemTypeId)),
        quotaTargetMet: ifCountryInfo(profile => getTaxonomyName(taxonomies, 'quotaTargetMet', profile.quotaTargetMetId)),
        quotaForInputOnly: ifCountryInfo(profile => getTaxonomyName(taxonomies, 'quotaForInputOnly', profile.quotaForInputOnlyId)),
        typeOfQuotas: ifCountryInfo(profile =>  profile.typeOfQuotaIds.map(typeOfQuotaId => getTaxonomyName(taxonomies, 'typesOfQuota', typeOfQuotaId)).join(', '))
    };

    // useEffect(() => {
    //     if (!featuredCountryId) setRandomFeaturedCountryId();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const showTypeOfQuotaTitles: boolean = selectedCountryInfo
        ? (
            (selectedCountryInfo.typeOfQuotaIds.indexOf(QUOTA_TYPE_RESERVED_SEATS_ID) !== -1)
            && (selectedCountryInfo.typeOfQuotaIds.indexOf(QUOTA_TYPE_CANDIDATE_QUOTA_ID) !== -1)
        )
        : false;

    const sectionTitle = (text: string) => (
        <div className={ countryProfileSectionTitleClassName }>
            <Spacer>
                { text }
            </Spacer>
        </div>
    );

    const sectionContent = (children: React.ReactNode) => (
        <div className={ countryProfileSectionContentClassName }>
            { children }
        </div>
    );

    const checkListItem = (
        text: string,
        isActive?: boolean
    ) => (
        <li
            className={ classes(
                countryProfileCheckListItemClassName,
                isActive && countryProfileCheckListActiveItemClassName
            ) }>
                <Icon name='check'/>
                { text }
        </li>
    );

    const onClickCountryItem = (country: Taxonomy) => {
        changeFilters({
            date: null
        });
        navigate(ROUTE_PAGE_COUNTRY_PROFILE + '?countryId=' + country.id);
    };

    return (
        <Page>
            { page && (
                <PageHeader
                    title={ page.name }
                    description={ page.html }/>
            ) }
            <Content>
                <div className={ countryProfileClassName }>
                    <Grid>
                        <Cell size={ 12 } sizeTablet={ 'stack' } className={ countryProfileFiltersCellClassName }>
                            <Box transparent>
                                <Spacer>
                                    <div className={ countryProfileFilterCountriesClassName }>
                                        <TaxonomyListGrouped
                                            list={ taxonomies.countries }
                                            searchField='Search for a country...'
                                            onClickItem={ onClickCountryItem }/>
                                    </div>
                                </Spacer>
                                <div className={ countryProfilePageSmallTextClassName }>
                                    Only countries with legislated gender quotas included
                                </div>
                            </Box>
                        </Cell>
                        <Cell size={ 12 } sizeTablet={ 'fill' } className={ countryProfileContentCellClassName }>
                            { selectedCountryInfo ? (
                                <div className={ countryProfileDataWrapperClassName } id='download'>
                                    <div className={ countryProfileDataHeaderClassName }>
                                        <Grid>
                                            <Cell size={ 12 } sizeTablet={ 6 }>
                                                <div className={ countryProfileCountryNameClassName }>
                                                    { selectedCountryInfoTaxonomyNames.country }
                                                </div>
                                                <div className={ countryProfileRegionNameClassName }>
                                                    {  selectedCountryInfoTaxonomyNames.region }
                                                </div>
                                            </Cell>
                                            <Cell size={ 12 } sizeTablet={ 6 }>
                                                <Spacer/>
                                                    <ResultsButtonpad
                                                        pdf
                                                        downloadData={ {
                                                            fileName: selectedCountryInfoTaxonomyNames.country + '\'s profile',
                                                            fileTitle: selectedCountryInfoTaxonomyNames.country + '\'s profile',
                                                            headers: ['Country', selectedCountryInfoTaxonomyNames.country],
                                                            content: [
                                                                ['Region', selectedCountryInfoTaxonomyNames.region],
                                                                ['Parliament structure', selectedCountryInfoTaxonomyNames.parliamentStructure],
                                                                ['Electoral system', selectedCountryInfoTaxonomyNames.electoralSystemType],
                                                                ['Electoral system (sub-type)', selectedCountryInfo.electoralSystemSubType],
                                                                ['Type of legislated quota', selectedCountryInfoTaxonomyNames.typeOfQuotas],
                                                                ['Quota target', selectedCountryInfo.quotaTarget + '%'],
                                                                ['Women\'s representation', selectedCountryInfo.womensRepresentation + '%'],
                                                                ['Quota met', selectedCountryInfoTaxonomyNames.quotaTargetMet],
                                                                ['Provision: Constitution', `${selectedCountryInfo.constitutionLegalSource}. ${selectedCountryInfo.constitutionLegalProvisions}`],
                                                                ['Provision: Electoral law', `${selectedCountryInfo.electoralLegalSource}. ${selectedCountryInfo.electoralLegalProvisions}`],
                                                                ['Provision: Party law', `${selectedCountryInfo.partyLegalSource}. ${selectedCountryInfo.partyLegalProvisions}`],
                                                                ['Provision: Parity law', `${selectedCountryInfo.parityLegalSource}. ${selectedCountryInfo.parityLegalProvisions}`],
                                                                ['Provision: Financing law', `${selectedCountryInfo.financingLegalSource}. ${selectedCountryInfo.financingLegalProvisions}`],
                                                                ['Provision: Political agreement', `${selectedCountryInfo.politicalLegalSource}. ${selectedCountryInfo.politicalLegalProvisions}`],
                                                            ]
                                                        } }
                                                        date={ filters.date }
                                                        onChangeDate={ newDate => changeFilters({
                                                            date: newDate
                                                        }) }/>
                                                <Spacer/>
                                            </Cell>
                                        </Grid>
                                    </div>
                                    <div className={ countryProfileDataContent1ClassName }>
                                        <Spacer/>
                                        <Grid>
                                            <Cell size={ 12 } sizeDesktop={ 7 }>
                                                <Box className={ countryProfileDataContentBoxClassName }>
                                                    { sectionTitle('Quota type and electoral system') }
                                                    { sectionContent(
                                                        <>
                                                            <Grid>
                                                                <Cell size={ 12 } sizeTablet={ 'fill' } className={ countryProfileDonutsWrapperClassName }>
                                                                    <CountryProfileDonuts countryProfile={ selectedCountryInfo }/>
                                                                </Cell>
                                                                <Cell size={ 12 } sizeTablet={ 4 }>
                                                                    <div className={ countryProfileImplementationTitleClassName }>
                                                                        Electoral system
                                                                    </div>
                                                                    <div className={ countryProfileImplementationDescriptionClassName }>
                                                                        { selectedCountryInfoTaxonomyNames.electoralSystemType }
                                                                    </div>
                                                                    <div className={ countryProfileImplementationTitleClassName }>
                                                                        Electoral system sub-type
                                                                    </div>
                                                                    <div className={ countryProfileImplementationDescriptionClassName }>
                                                                        { selectedCountryInfo.electoralSystemSubType }
                                                                    </div>
                                                                    <div className={ countryProfileImplementationTitleClassName }>
                                                                        Type of quota
                                                                    </div>
                                                                    <div className={ countryProfileImplementationDescriptionClassName }>
                                                                        { getTaxonomyNameList(taxonomies, 'typesOfQuota', selectedCountryInfo.typeOfQuotaIds).map(
                                                                            (taxonomyName, index) => (
                                                                                <div key={ index }>
                                                                                    { taxonomyName }
                                                                                </div>
                                                                            )
                                                                        ) }
                                                                    </div>
                                                                </Cell>
                                                            </Grid>
                                                            <Comments>
                                                                <strong>Note:</strong> { selectedCountryInfo.comments }
                                                            </Comments>
                                                        </>
                                                    ) }
                                                </Box>
                                            </Cell>
                                            <Cell size={ 12 } sizeDesktop={ 5 }>
                                                <Box className={ countryProfileDataContentBoxClassName }>
                                                    { sectionTitle('Quota implementation') }
                                                    { sectionContent(
                                                        <>
                                                            { selectedCountryInfo.typeOfQuotaIds.indexOf(QUOTA_TYPE_RESERVED_SEATS_ID) > -1 && (
                                                                <>
                                                                    { showTypeOfQuotaTitles && (
                                                                        <div className={ quotaTypeNameClassName }>
                                                                            Reserved seats
                                                                        </div>
                                                                    ) }
                                                                    <div className={ countryProfileImplementationTitleClassName }>
                                                                        Method of filling seats
                                                                    </div>
                                                                    <div className={ countryProfileImplementationDescriptionClassName }>
                                                                        { selectedCountryInfo.methodOfFillingSeats }
                                                                    </div>
                                                                </>
                                                            ) }
                                                            { selectedCountryInfo.typeOfQuotaIds.indexOf(QUOTA_TYPE_CANDIDATE_QUOTA_ID) > -1 && (
                                                                <>
                                                                    { showTypeOfQuotaTitles && (
                                                                        <div className={ quotaTypeNameClassName }>
                                                                            Candidate quota
                                                                        </div>
                                                                    ) }
                                                                    { (selectedCountryInfo.rankingPlacementId !== 0) && (
                                                                        <>
                                                                            <div className={ countryProfileImplementationTitleClassName }>
                                                                                Ranking/placement rules
                                                                            </div>
                                                                            <div className={ countryProfileImplementationDescriptionClassName }>
                                                                                { selectedCountryInfo.rankingPlacementDetails }
                                                                            </div>
                                                                        </>
                                                                    ) }
                                                                    <div className={ countryProfileImplementationTitleClassName }>
                                                                        Sanctions for non-compliance
                                                                    </div>
                                                                    <div className={ countryProfileImplementationDescriptionClassName }>
                                                                        { selectedCountryInfo.sanctionId === 0 ? 'No' : 'Yes' }
                                                                        { textPrefix('. ', selectedCountryInfo.listsAreRejected) }
                                                                        { textPrefix('. ', selectedCountryInfo.otherSanctions) }
                                                                    </div>
                                                                    { (
                                                                        (selectedCountryInfo.quotaForInputOnlyId !== 0)
                                                                        &&
                                                                        (selectedCountryInfoTaxonomyNames.quotaForInputOnly !== NOT_APPLICABLE_TEXT)
                                                                    ) && (
                                                                        <>
                                                                            <div className={ countryProfileImplementationTitleClassName }>
                                                                                Quota on results
                                                                            </div>
                                                                            <div className={ countryProfileImplementationDescriptionClassName }>
                                                                                { selectedCountryInfoTaxonomyNames.quotaForInputOnly }
                                                                                { 
                                                                                    (selectedCountryInfoTaxonomyNames.quotaForInputOnly === 'Yes')
                                                                                    &&
                                                                                    (selectedCountryInfo.correctiveMeasureDetail.length > 0)
                                                                                    && (
                                                                                        <>
                                                                                            . { selectedCountryInfo.correctiveMeasureDetail }
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    ) }
                                                                </>
                                                            ) }
                                                        </>
                                                    ) }
                                                </Box>
                                            </Cell>
                                        </Grid>
                                        <Spacer/>
                                    </div>
                                    <div className={ countryProfileDataContent2ClassName }>
                                        <Box className={ countryProfileDataContentBoxClassName }>
                                            <Grid>
                                                <Cell size={ 12 } sizeDesktop={ 'stack' }>
                                                    { sectionTitle('Quota legal source') }
                                                    { sectionContent(
                                                        <ul className={ countryProfileCheckListClassName }>
                                                            { checkListItem('Constitution', selectedCountryInfo.constitutionProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) }
                                                            { checkListItem('Electoral law', selectedCountryInfo.electoralProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) }
                                                            { checkListItem('Parity law', selectedCountryInfo.parityProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) }
                                                            { checkListItem('Party law', selectedCountryInfo.partyProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) }
                                                            { checkListItem('Financing law', selectedCountryInfo.financingProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) }
                                                            { checkListItem('Political agreement', selectedCountryInfo.politicalProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) }
                                                        </ul>
                                                    ) }
                                                </Cell>
                                                <Cell size={ 12 } sizeDesktop={ 'fill' }>
                                                    { sectionTitle('Legal provisions') }
                                                    { sectionContent(
                                                        <div className={ countryProfileLegalProvisionsWrapperClassName }>
                                                            <LegalProvisions countryProfile={ selectedCountryInfo } initExpanded/>
                                                        </div>
                                                    ) }
                                                </Cell>
                                                <Cell size={ 12 }>
                                                    { sectionContent(
                                                        <InfoDate dates={ {
                                                            date: selectedCountryInfo.date,
                                                            ipuDate: selectedCountryInfo.ipuDate || selectedCountryInfo.date
                                                        } }/>
                                                    ) }
                                                </Cell>
                                            </Grid>
                                        </Box>
                                    </div>
                                </div>
                            ) : (
                                <div className={ countryProfileDataWrapperClassName }>
                                    <div className={ countryProfileDataHeaderClassName }>
                                        <Grid>
                                            <Cell size={ 12 } sizeTablet={ 6 }>
                                                <div className={ noResultsTitleClassName }>
                                                    No gender quota in place for <strong>&nbsp;{ countryName }&nbsp;</strong>for selected date.
                                                </div>
                                                <div className={ countryProfileRegionNameClassName }>
                                                </div>
                                            </Cell>
                                            <Cell size={ 12 } sizeTablet={ 6 }>
                                                <Spacer/>
                                                    <ResultsButtonpad
                                                        date={ filters.date }
                                                        onChangeDate={ newDate => changeFilters({
                                                            date: newDate
                                                        }) }/>
                                                <Spacer/>
                                            </Cell>
                                        </Grid>
                                    </div>
                                </div>
                            ) }

                        </Cell>
                    </Grid>
                </div>
            </Content>
        </Page>
    );
};

export default CountryProfilePage;
