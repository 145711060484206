import { style } from 'typestyle';
import { backgroundLogin } from '../../../assets';
import { COLOR_05, COLOR_27, COLOR_ERROR, COLOR_WHITE, FONT_WEIGHT_BOLD, MARGINS_PX } from '../../../constants';
import { mixinFont } from '../../../style.mixin';

export const loginPageBackgroundClassName = style({
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
});

export const loginPageBackgroundOverlayClassName = style({
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: COLOR_27,
    opacity: .9
});

export const loginPageCenterClassName = style({});

export const loginPageFormWrapperClassName = style({
    margin: '0 auto',
    maxWidth: '334px',
    borderRadius: '8px',
    background: COLOR_05,
    padding: `${MARGINS_PX}px`,
    boxSizing: 'border-box',
    
    $nest: {
        '*[disabled]': {
            opacity: .5,
            cursor: 'not-allowed'
        }
    }
});

export const loginPageFormInnerClassName = style({
    margin: '0 auto',
    maxWidth: '240px'
});

export const loginPageImageClassName = style({
    display: 'block',
    width: '100%',
    border: 0,
    margin: `${MARGINS_PX}px 0 ${MARGINS_PX * 2}px 0`,
    padding: 0
});

export const loginPageFieldClassName = style({
    display: 'block',
    width: '100%',
    border: 0,
    margin: `${MARGINS_PX}px 0`,
    padding: 0,
    borderBottom: 'solid 1px ' + COLOR_WHITE,
    color: COLOR_WHITE,
    background: 'transparent',
    boxSizing: 'border-box',
    height: '30px',
    outline: 'none'
});

export const loginPageButtonClassName = style({
    display: 'block',
    width: '100%',
    border: 0,
    margin: `${MARGINS_PX * 2}px 0 ${MARGINS_PX}px 0`,
    padding: 0,
    height: '36px',
    borderRadius: '8px',
    background: COLOR_WHITE,
    cursor: 'pointer',
    ...mixinFont(16, FONT_WEIGHT_BOLD, undefined, COLOR_05)
});

export const loginMessageClassName = style({
    textAlign: 'center',
    ...mixinFont(14, FONT_WEIGHT_BOLD, undefined, COLOR_ERROR)
});
