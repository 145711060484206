import { style } from 'typestyle';
import {
    COLOR_04,
    COLOR_05,
    COLOR_BLACK,
    FONT_MAIN,
    FONT_WEIGHT_BLACK,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    FONT_WEIGHT_REGULAR
} from '../../../constants';

export const titleClassName = style({
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_BLACK,
    color: COLOR_05,
    fontSize: '30px',
    lineHeight: '38px',
    textTransform: 'uppercase'
});

export const titleEllipsisClassName = style({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
});
