import { style } from 'typestyle';
import {
    COLOR_09,
    COLOR_11,
    COLOR_12,
    COLOR_WHITE,
    FONT_MAIN,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_REGULAR,
    MARGINS_PX
} from '../../../constants';

export const tableContainerClassName = style({
    position: 'relative'
});

export const tableBackgroundClassName = style({
    display: 'block',
    position: 'absolute',
    background: COLOR_WHITE,
    opacity: .50,
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0
});

export const tableScrollClassName = style({
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflowX: 'auto',
    overflowY: 'hidden'
});

export const tableClassName = style({
    position: 'relative',
    zIndex: 1,
    minWidth: '100%',

    $nest: {
        'th, td': {
            padding: `${MARGINS_PX}px`,
            fontFamily: FONT_MAIN,
            verticalAlign: 'top',
            fontSize: '12px',
            lineHeight: '14px'
        },
        'th': {
            backgroundColor: COLOR_WHITE,
            fontWeight: FONT_WEIGHT_BOLD,
            minHeight: '38px',
            color: COLOR_12,
            textAlign: 'left'
        },
        'td': {
            fontWeight: FONT_WEIGHT_REGULAR,
            color: COLOR_12
        },
        '> thead > tr > th': {
            verticalAlign: 'middle'
        },
        '& > tbody:last-child > tr:not(:last-child) > td, & > tbody > tr:not(:last-child) > th': {
            borderBottom: 'solid 1px black'
        }
    }
});

export const tableFixedClassName = style({
    tableLayout: 'fixed'
});
