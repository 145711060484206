import { getCurrentDate, getShortDate } from "../utils";

export interface DownloadCSVTableConfig {
    title: string,
    headers: string[],
    content: ((string | number)[])[],
    dataDate: string
}

const generateCSVLink = (dataTexts: string[]): string => (
    'data:text/csv;charset=utf-8,' + window.encodeURI(dataTexts.join('\r\n'))
);

const cellText = (text: string | number): string => (
    '"' + (
        String(text)
            .replace(/([\"(])(.+?)([\")])/g, (match, p1, p2, p3, offset, string) => (
                '“' + p2 + '”'
            ))
            .replaceAll('""', '“”')
            .replaceAll('"', '”')
            .replace(/&#039;/g, "'")
    ) + '"'
);

const oneLineText = (text: string, headers: string[]): string => (
    headers.map((header, index) => index === 0 ? cellText(text) : cellText('')).join(',')
);

const tableHeaderText = (headers: string[]): string => (
    headers.map(cellText).join(',')
);

const tableContentText = (content: ((string | number)[])[]): string => (
        content
            .map(arrayOfValues => (
                arrayOfValues
                    .map(cellText)
                    .join(',')
            ))
            .join('\r\n')
);

export const linkCSVTable = (config: DownloadCSVTableConfig): string => (
    generateCSVLink([
        oneLineText(config.title, config.headers),
        oneLineText('Source: UN Gender Quota Portal', config.headers),
        oneLineText('Date of download: ' + getShortDate(getCurrentDate()), config.headers),
        oneLineText('Reference date: ' + config.dataDate, config.headers),
        oneLineText('Hosted by UN Women', config.headers),
        tableHeaderText(config.headers),
        tableContentText(config.content)
    ])
);
