import React from 'react';
import { classes } from 'typestyle';
import {
    boxClassName,
    boxTransparentClassName
} from './box.style';

interface BlockProps {
    children?: React.ReactNode,
    className?: string,
    transparent?: boolean
}

const Box: React.FC<BlockProps> = ({
    children,
    className,
    transparent = false
}) => (
    <div
        className={ classes(
            boxClassName,
            transparent && boxTransparentClassName,
            className
        ) }
        children={ children }/>
);

export default Box;
