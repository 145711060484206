import { media } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import {
    DESKTOP_BREAKPOINT,
    MONTH,
    QUOTA_TYPE_CANDIDATE_QUOTA_ID,
    QUOTA_TYPE_EMPTY_TEXT,
    NOT_APPLICABLE_TEXT,
    QUOTA_TYPE_RESERVED_SEATS_ID,
    TABLET_BREAKPOINT
} from './constants';
import {
    CountryProfile,
    CountryProfileResult,
    Option,
    Taxonomies,
    Taxonomy
} from './state/types';

export const mobileOnlyStyles = (
    cssProperties: NestedCSSProperties
) => media({
    maxWidth: (TABLET_BREAKPOINT - 1) + 'px'
}, cssProperties);

export const tabletStyles = (
    cssProperties: NestedCSSProperties
) => media({
    minWidth: TABLET_BREAKPOINT + 'px'
}, cssProperties);

export const desktopStyles = (
    cssProperties: NestedCSSProperties
) => media({
    minWidth: DESKTOP_BREAKPOINT + 'px'
}, cssProperties);

export const textPrefix = (
    prefix: string,
    text?: string,
    fallbackString?: string
): string | undefined => (
    text?.trim().length ? `${prefix}${text}` : fallbackString
);

export const encode = (obj: {}): string => {
    return window.encodeURIComponent(window.btoa(JSON.stringify(obj)));
};

export const decode = (str: string): any => {
    return JSON.parse(window.atob(window.decodeURIComponent(str)));
};

export const getTaxonomyName = (
    taxonomies: Taxonomies,
    taxonomyType: keyof Taxonomies,
    taxonomyId: number
): string => {
    if (taxonomyId < 1) {
        return '';
    }

    for (let taxonomy of taxonomies[taxonomyType]) {
        if (taxonomy.id === taxonomyId) {
            return taxonomy.name;
        }
    }

    return `[Unknown ${taxonomyType}]`;
};

export const getTaxonomyNameList = (
    taxonomies: Taxonomies,
    taxonomyType: keyof Taxonomies,
    taxonomyIds: number[]
): string[] => taxonomyIds
    .map(taxonomyId => getTaxonomyName(taxonomies, taxonomyType, taxonomyId));

export const getDefaultSelectedCountryId = (
    profiles: CountryProfile[]
): number | null => {
    if (profiles.length) {
        return profiles[0].countryId;
    }

    return null;
};

export const getDateList = (
    profiles: CountryProfile[]
): string[] => {
    const dateList: string[] = [];

    profiles.forEach(profile => {
        if (dateList.indexOf(profile.date) === -1) {
            dateList.push(profile.date);
        }
    });

    return dateList;
};

export const getDateOptions = (
    countryProfiles: CountryProfile[],
    dateTaxonomies: Taxonomy[]
): Option[] => {
    const result: Option[] = [];

    return result;
};

export const getDateObj = (
    dateString: string
): Date => {
    const datetimeValues: number[] = dateString.split('-').map(Number);
    const year = datetimeValues[0];
    const month = datetimeValues[1];
    const day = datetimeValues[2];

    if (
        (year && !isNaN(year))
        && (month && !isNaN(month))
        && (day && !isNaN(day))
    ) {
        return new Date(year, month - 1, day, 0, 0, 0, 0);
    }

    throw new Error(`Value cannot be parsed as Date: ${dateString}`)
};

export const getShortDate = (
    dateString: string
): string => {
    const dateObj = getDateObj(dateString);

    return (
        String(dateObj.getDate()).padStart(2, '0')
        + '/'
        + String(dateObj.getMonth() + 1).padStart(2, '0')
        + '/'
        + ((yearArray: string[]): string => (
            String(yearArray[2]) + String(yearArray[3])
        ))(String(dateObj.getFullYear()).split(''))
    );
};

export const getLargeDate = (
    dateString: string
): string => {
    const dateObj = getDateObj(dateString);

    return (
        String(MONTH[dateObj.getMonth()])
        + ' '
        + String(dateObj.getDate())
        + ', '
        + String(dateObj.getFullYear())
    );
};

export const getCurrentDate = (): string => {
    const currentDate = new Date();

    return (
        currentDate.getFullYear()
        + '-'
        + (currentDate.getMonth() + 1)
        + '-'
        + currentDate.getDate()
    );
};

export const getCandidateQuotaDetails = (countryProfile: CountryProfile): string => (
    countryProfile.typeOfQuotaIds.indexOf(QUOTA_TYPE_CANDIDATE_QUOTA_ID) !== -1
    ? countryProfile.rankingPlacementDetails || QUOTA_TYPE_EMPTY_TEXT
    : NOT_APPLICABLE_TEXT
);

export const getReservedSeatsDetails = (countryProfile: CountryProfile): string => (
    countryProfile.typeOfQuotaIds.indexOf(QUOTA_TYPE_RESERVED_SEATS_ID) !== -1
    ? countryProfile.methodOfFillingSeats || QUOTA_TYPE_EMPTY_TEXT
    : NOT_APPLICABLE_TEXT
);

export const getRandomElementFromArray = <T = any>(array: Array<T>): T | undefined => {
    return array[Math.floor(Math.random() * array.length)];
};

export const filterMostRecentCountryProfiles = (
    countryProfileList: CountryProfile[],
    mostRecentDateFromTaxonomies: string | null
): CountryProfile[] => {
    if (mostRecentDateFromTaxonomies) {
        const mostRecentDateObjectFromTaxonomies = getDateObj(mostRecentDateFromTaxonomies);
        const resultDict: {
            [countryIdKey: string]: CountryProfile
        } = {};

        countryProfileList.forEach(countryProfile => {
            const countryIdKey = String(countryProfile.countryId);
            const currentDateObject = getDateObj(countryProfile.date);
    
            if (currentDateObject >= mostRecentDateObjectFromTaxonomies) {
                if (resultDict[countryIdKey]) {
                    if (currentDateObject > getDateObj(resultDict[countryIdKey].date)) {
                        resultDict[countryIdKey] = countryProfile;
                    }
                }
                else {
                    resultDict[countryIdKey] = countryProfile;
                }
            }
        });

        return Object.keys(resultDict).map(countryId => resultDict[countryId]);
    }

    return [];
};

export const getLatestDates = (
    countryProfileList: CountryProfile[]
): {date: string, ipuDate: string} => {
    const result: ReturnType<typeof getLatestDates> = {
        date: '', ipuDate: ''
    };
    let selectedDateObj: Date;
    let selectedIpuDateObj: Date;

    countryProfileList.forEach(countryProfile => {
        const { date, ipuDate } = countryProfile;

        if (date) {
            const dateObj = getDateObj(date);
            const ipuDateObj = ipuDate ? getDateObj(ipuDate) : dateObj;

            if (!result.date || !result.ipuDate) {
                result.date = date;
                selectedDateObj = dateObj;

                result.ipuDate = ipuDate || date;
                selectedIpuDateObj = ipuDateObj;
            }
            else {
                if (dateObj > selectedDateObj) {
                    result.date = date;
                    selectedDateObj = dateObj;
                }

                if (ipuDateObj > selectedIpuDateObj) {
                    result.ipuDate = ipuDate || date;
                    selectedIpuDateObj = ipuDateObj;
                }
            }
        }
    });

    return result;
};

export const getMostRecentDate = (dateList: string[]): string | null => {
    let selectedDate: string | null = null;

    dateList.forEach(dateString => {
        if (
            !selectedDate || (
                selectedDate && (
                    selectedDate < dateString
                )
            )
        ) {
            selectedDate = dateString;
        }
    });

    return selectedDate;
};

export const filterCountryResult = (
    filterCallback: (countryProfile: CountryProfile) => boolean
) => (
    (countryResult: CountryProfileResult): boolean => {
        if (countryResult.countryProfile) {
            return filterCallback(countryResult.countryProfile);
        }

        return true;
    }
);
