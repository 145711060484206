import { Taxonomy } from '../../../state/types';
import { TaxonomyGroup } from './taxonomy-list.types';

export const groupTaxonomies = (taxonomies: Taxonomy[]): [TaxonomyGroup[], string[]] => {
    const groupDict: {
        [key: string]: Taxonomy[]
    } = {};

    taxonomies.forEach(taxonomy => {
        const letter = taxonomy.name[0];

        if (!groupDict[letter]) {
            groupDict[letter] = [];
        }

        groupDict[letter].push(taxonomy);
    });

    const letters = Object.keys(groupDict);

    return [
        letters.map(letter => ({
            letter,
            taxonomies: groupDict[letter]
        })),
        letters
    ];
};

export const filterTaxonomies = (taxonomies: Taxonomy[], searchText: string): Taxonomy[] => taxonomies.filter(
    taxonomyData => taxonomyData.name.toLowerCase().includes(searchText)
);
