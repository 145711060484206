import React from 'react';
import { Link } from 'react-router-dom';
import { logoMainSVG } from '../../../assets';
import { ROUTE_PAGE_HOME } from '../../../constants';
import Content from '../content/content.component';
import MainNav from '../main-nav/main-nav.component';
import {
    mainMenuWrapperClassName,
    mainMenuClassName,
    mainMenuLeftSideClassName,
    topbarHomeLinkClassName,
    mainMenuRightSideClassName,
    mainMenuLogoImgClassName
} from './site-header.style';

const Topbar: React.FC = () => (
    <div className={ mainMenuWrapperClassName }>
        <Content className={ mainMenuClassName }>
            <div className={ mainMenuLeftSideClassName }>
                <Link to={ ROUTE_PAGE_HOME } className={ topbarHomeLinkClassName }>
                    <img
                        className={ mainMenuLogoImgClassName }
                        alt='GENDER QUOTA PORTAL'
                        src={ logoMainSVG }/>
                </Link>
            </div>
            <div className={ mainMenuRightSideClassName }>
                <MainNav/>
            </div>
        </Content>
    </div>
);

export default Topbar;
