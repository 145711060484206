import axios from 'axios';
import { API_BASE_URL } from '../constants';
import {
    parseTaxonomyGroups,
    parsePages,
    parseCountryProfiles
} from './api.parsers';
import { GetMethod } from './api.types';

const apiGet: GetMethod = (path, parser) => new Promise((resolve, reject) => {
    axios.get(API_BASE_URL + path).then(
        response => resolve(parser(response.data)),
        error => reject()
    );
});

export const getCountryResults = () => apiGet('/gender-data', parseCountryProfiles);
export const getTaxonomies = () => apiGet('/taxonomies', parseTaxonomyGroups);
export const getPages = () => apiGet('/pages', parsePages);
