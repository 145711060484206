import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_PAGE_HOME } from '../../../constants';
import { useAppState } from '../../../state/hook';

const LogoutPage: React.FC = () => {
    const [, appActions] = useAppState();
    useEffect(appActions.clearSession, []);
    return <Navigate to={ ROUTE_PAGE_HOME }/>
};

export default LogoutPage;
