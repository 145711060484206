import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import {
    COLOR_01,
    COLOR_05,
    COLOR_10,
    COLOR_WHITE,
    FONT_MAIN,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';

export const buttonStyles: NestedCSSProperties = {
    display: 'inline-block',
    position: 'relative',
    minHeight: '40px',
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: `12px ${MARGINS_PX}px`,
    textAlign: 'center',
    border: 'none',
    cursor: 'pointer'
};

export const buttonDefaultStyles: NestedCSSProperties = {
    color: COLOR_WHITE,
    backgroundColor: COLOR_05
};

export const buttonClassName = style(buttonStyles);

export const buttonDefaultClassName = style(buttonDefaultStyles);

export const buttonLightClassName = style({
    border: `solid 1px ${COLOR_10}`,
    color: COLOR_10,
    backgroundColor: 'transparent',
    padding: `10px ${MARGINS_PX}px`,
});

export const buttonBlockClassName = style({
    display: 'block'
});
