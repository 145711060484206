import React from 'react';
import { classes } from 'typestyle';
import Content from '../content/content.component';
import Title from '../title/title.component';
import {
    pageTitleClassName,
    pageTitleContentClassName,
    pageTitleLeftClassName,
    pageTitleRightClassName,
    pageTitleRightInnerClassName,
    pageTitleWithChildrenClassName,
    pageTitleChildrenClassName
} from './page-header.style';

interface PageTitleProps {
    title?: string,
    description?: string,
    children?: React.ReactNode
}

const PageHeader: React.FC<PageTitleProps> = ({
    title,
    description,
    children
}) => (
    <div className={ classes(pageTitleClassName, Boolean(children) && pageTitleWithChildrenClassName) }>
        <Content>
            { (title || description) && (
                <div className={ pageTitleContentClassName }>
                    <div className={ pageTitleLeftClassName }>
                        <Title>
                            { title }
                        </Title>
                    </div>
                    <div className={ pageTitleRightClassName }>
                        <div
                            className={ pageTitleRightInnerClassName }
                            dangerouslySetInnerHTML={ {__html: description || ''} }/>
                    </div>
                </div>
            ) }
           { children && (
                <div className={ pageTitleChildrenClassName }>
                    { children }
                </div>
           ) } 
        </Content>
    </div>
);

export default PageHeader;
