import React, { createRef, useEffect, useRef } from 'react';
import { counter } from './dynamic-number.service';

interface DynamicNumberProps {
    value: number,
    speed?: number
}

const DynamicNumber: React.FC<DynamicNumberProps> = ({
    value,
    speed = 1
}) => {
    const refElement = createRef<HTMLSpanElement>();
    const refPreviousValue = useRef<number>(0);

    const setValue = () => {
        if (refElement.current && (refPreviousValue.current !== value)) {
            const toCount = value > refPreviousValue.current
                ? (value - refPreviousValue.current)
                : (refPreviousValue.current - value);

            counter(
                toCount,
                speed,
                (growingValue, finish) => {
                    if (refElement.current) {
                        const printNumber = (number: number) => {
                            if (refElement.current) {
                                refElement.current.innerHTML = (number % 1 !== 0) ? number.toFixed(1) : number.toString();
                            }
                        };

                        if (value > refPreviousValue.current) {
                            printNumber(
                                refPreviousValue.current + growingValue
                            );
                        }
                        else if (value < refPreviousValue.current) {
                            printNumber(
                                refPreviousValue.current - growingValue
                            );
                        }
                    }

                    if (finish) {
                        refPreviousValue.current = value;
                    }
                }
            );
        } 
    };

    useEffect(setValue, [value]);

    return <span ref={ refElement }>0</span>;
}

export const N: React.FC<{children: string | number}> = ({
    children
}) => {
    const number = Number(children);

    if (isNaN(number)) {
        console.warn('N Component is not parsing a string with number format.');
        
        return <>{ children }</>;
    }

    return <DynamicNumber value={ number }/>
};

export default DynamicNumber;
