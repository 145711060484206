import { style } from 'typestyle';
import { COLOR_01, COLOR_07, FONT_MAIN, FONT_WEIGHT_REGULAR, MARGINS_PX } from '../../../constants';
import { tabletStyles } from '../../../utils';

export const pageSectionClassName = style({
    $debugName: 'pageSection.component',
    
    display: 'block',
    padding: `${MARGINS_PX * 2}px 0`,
    borderBottom: `solid 1px ${COLOR_07}`,

    $nest: {
        '&:last-child': {
            borderBottom: 'none'
        }
    }
});

export const pageSectionHeadClassName = style({
    display: 'block'
}, tabletStyles({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
}));

export const pageSectionHeadTitleClassName = style({
    display: 'block',
    padding: `0 0 ${MARGINS_PX * 2}px 0`,
    color: COLOR_01,
    fontSize: '22px',
    lineHeight: '25.5px',
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_REGULAR
}, tabletStyles({
    flexGrow: 1,
    flexShrink: 1,
    fontSize: '32px',
    lineHeight: '37.5px'
}));

export const pageSectionHeadAsideClassName = style({
    display: 'block',
    padding: `0 0 ${MARGINS_PX * 2}px 0`
});

export const pageSectionInnerClassName = style({
    display: 'block'
});

