import { style } from 'typestyle';
import { COLOR_05, COLOR_10, COLOR_29, FONT_WEIGHT_BLACK, FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM, MARGINS_PX } from '../../../constants';
import { mixinFont } from '../../../style.mixin';
import { tabletStyles } from '../../../utils';

export const donutsSmallLabelClassName = style({
    $nest: {
        'span:first-child': {
            ...mixinFont(60, FONT_WEIGHT_BLACK, 60, false),
            display: 'block',
            textAlign: 'center'
        },
        'span:last-child': {
            display: 'block',
            width: '100%',
            margin: '0 auto',
            textAlign: 'center',
            ...mixinFont(14, FONT_WEIGHT_MEDIUM, 12, false)
        }
    }
}, tabletStyles({
    $nest: {
        'span:first-child': {
            fontSize: '30px',
            lineHeight: '30px'
        },
        'span:last-child': {
            fontSize: '12px'
        }
    }
}));

export const donutsSmallLabel01ClassName = style({
    $nest: {
        'span': {
            color: COLOR_10
        }
    }
});

export const donutsSmallLabel02ClassName = style({
    $nest: {
        'span': {
            color: COLOR_29
        }
    }
});

export const donutsWrapperClassName = style({
}, tabletStyles({
    width: '100%',
    maxWidth: '160px',
    margin: '0 auto'
}));
