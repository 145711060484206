import {
    BASE_URL,
    COUNTRY_COUNT_AMERICAS,
    COUNTRY_COUNT_ASIA,
    COUNTRY_COUNT_AFRICA,
    COUNTRY_COUNT_EUROPE,
    COUNTRY_COUNT_OCEANIA,
    TAXONOMIES_LEGAL_SOURCES
} from '../constants';
import {
    CountryProfile,
    Page,
    Taxonomies,
    Taxonomy
} from '../state/types';
import {
    assertIsArray,
    assertPropertyExists,
    parseArray,
    parseArrayDataHandler,
    parseNumber,
    parseNumberOrNull,
    parseString
} from './api.typeguards';
import { DataParser } from './api.types';

const parseCountryProfile: DataParser<CountryProfile> = data => ({
    countryId: assertPropertyExists(data, 'country', parseNumber),
    typeOfQuotaIds: parseArray(assertPropertyExists(data, 'type_of_legislated_quotas')).map(parseNumber),
    sanctionId: assertPropertyExists(data, 'sanctions_for_non_compliance', parseNumber),
    electoralSystemTypeId: assertPropertyExists(data, 'electoral_system_type', parseNumber),
    rankingPlacementId: assertPropertyExists(data, 'ranking_placement', parseNumber),
    rankingPlacementDetails: assertPropertyExists(data, 'ranking_placement_details', parseString),
    correctiveMeasureDetail: assertPropertyExists(data, 'corrective_measure_detail', parseString),
    methodOfFillingSeats: assertPropertyExists(data, 'method_of_filling_seats', parseString),
    quotaTarget: assertPropertyExists(data, 'quota_target', parseNumber),
    womensRepresentation: assertPropertyExists(data, 'womens_representation', parseNumberOrNull),
    date: assertPropertyExists(data, 'date', parseString),
    ipuDate: assertPropertyExists(data, 'ipu_date', parseString),
    regionId: assertPropertyExists(data, 'm49_region', parseNumber),
    otherSanctions: assertPropertyExists(data, 'other_sanctions', parseString),
    listsAreRejected: assertPropertyExists(data, 'lists_are_rejected', parseString),
    electoralSystemSubType: assertPropertyExists(data, 'electoral_system_sub_type', parseString),
    comments: assertPropertyExists(data, 'comments', parseString),
    quotaTargetMetId: assertPropertyExists(data, 'quota_target_met', parseNumber),
    quotaForInputOnlyId: assertPropertyExists(data, 'quota_for_input_only', parseNumber),
    parliamentStructureId: assertPropertyExists(data, 'parliament_structure', parseNumber),

    constitutionProvisionsGenderQuotaId: assertPropertyExists(data, 'constitution_provisions_gender_quota', parseNumber),
    constitutionLegalSource: assertPropertyExists(data, 'constitution_legal_source', parseString),
    constitutionLegalProvisions: assertPropertyExists(data, 'constitution_legal_provisions', parseString),

    electoralProvisionsGenderQuotaId: assertPropertyExists(data, 'electoral_provisions_gender_quota', parseNumber),
    electoralLegalSource: assertPropertyExists(data, 'electoral_legal_source', parseString),
    electoralLegalProvisions: assertPropertyExists(data, 'electoral_legal_provisions', parseString),

    partyProvisionsGenderQuotaId: assertPropertyExists(data, 'party_provisions_gender_quota', parseNumber),
    partyLegalSource: assertPropertyExists(data, 'party_legal_source', parseString),
    partyLegalProvisions: assertPropertyExists(data, 'party_legal_provisions', parseString),

    parityProvisionsGenderQuotaId: assertPropertyExists(data, 'parity_provisions_gender_quota', parseNumber),
    parityLegalSource: assertPropertyExists(data, 'parity_legal_source', parseString),
    parityLegalProvisions: assertPropertyExists(data, 'parity_legal_provisions', parseString),

    financingProvisionsGenderQuotaId: assertPropertyExists(data, 'financing_provisions_gender_quota', parseNumber),
    financingLegalSource: assertPropertyExists(data, 'financing_legal_source', parseString),
    financingLegalProvisions: assertPropertyExists(data, 'financing_legal_provisions', parseString),

    politicalProvisionsGenderQuotaId: assertPropertyExists(data, 'political_provisions_gender_quota', parseNumber),
    politicalLegalSource: assertPropertyExists(data, 'political_legal_source', parseString),
    politicalLegalProvisions: assertPropertyExists(data, 'political_legal_provisions', parseString)
});

const parsePage: DataParser<Page> = data => ({
    name: assertPropertyExists(data, 'title', parseString),
    html: assertPropertyExists(data, 'text', String),
    imageUrl: ((imagePath) => imagePath.length ? BASE_URL + imagePath : '')(assertPropertyExists(data, 'image', String))
});

const parseTaxonomy: DataParser<Taxonomy> = data => ({
    id: assertPropertyExists(data,'id', parseNumber),
    name: assertPropertyExists(data, 'name', parseString)
});

export const parseCountryProfiles = parseArrayDataHandler(parseCountryProfile);

export const parsePages = parseArrayDataHandler(parsePage);

export const parseTaxonomyGroups: DataParser<Taxonomies> = data => {
    const result: Taxonomies = {
        dates: [],
        countries: [],
        typesOfQuota: [],
        sanctions: [],
        electoralSystemTypes: [],
        rankingPlacement: [],
        legalSources: [...TAXONOMIES_LEGAL_SOURCES],
        regions: [],
        quotaForInputOnly: [],
        parliamentStructure: [],
        quotaTargetMet: [],
        provisionsOnGenderQuotas_yesNo: []
    };

    assertIsArray(data).forEach(data => {
        try {
            const taxonomyType = assertPropertyExists(data, 'taxonomy', String);
            const taxonomy = parseTaxonomy(data);

            switch(taxonomyType) {
                case 'Dropdown Dates':
                    return result.dates.push(taxonomy);

                case 'Country':
                    return result.countries.push(taxonomy);

                case 'Type of legislated quotas':
                    return result.typesOfQuota.push(taxonomy);

                case 'Sanctions for non compliance':
                    return result.sanctions.push(taxonomy);

                case 'Electoral System':
                    return result.electoralSystemTypes.push(taxonomy);

                case 'Ranking/placement':
                    return result.rankingPlacement.push(taxonomy);

                case 'Quota for input only':
                    return result.quotaForInputOnly.push(taxonomy);

                case 'Parliament structure':
                    return result.parliamentStructure.push(taxonomy);

                case 'Quota target met':
                    return result.quotaTargetMet.push(taxonomy);

                case 'M49 Region':
                    return result.regions.push((() => {
                        let countryCount = 0;

                        switch(taxonomy.id) {
                            case 240: countryCount = COUNTRY_COUNT_AMERICAS; break;
                            case 241: countryCount = COUNTRY_COUNT_ASIA; break;
                            case 239: countryCount = COUNTRY_COUNT_AFRICA; break;
                            case 238: countryCount = COUNTRY_COUNT_EUROPE; break;
                            case 242: countryCount = COUNTRY_COUNT_OCEANIA; break;
                        }

                        return {
                            ...taxonomy,
                            countryCount
                        };
                    })());
            }

            const taxonomiesToSortAsc: Array<keyof Taxonomies> = [
                'countries', 'typesOfQuota', 'legalSources'
            ];

            const taxonomiesToSortDesc: Array<keyof Taxonomies> = [
                'dates'
            ];

            taxonomiesToSortAsc.forEach(taxonomyName => {
                result[taxonomyName].sort((a, b) => a.name > b.name ? 1 : -1);
            });

            taxonomiesToSortDesc.forEach(taxonomyName => {
                result[taxonomyName].sort((a, b) => a.name > b.name ? -1 : 1);
            });
        }
        catch (error) {
            console.warn(`Skipped taxonomy. Reason: ${error}`);
        }
    });

    return result;
};
