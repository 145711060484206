import React from 'react';
import { contentClassName } from './site-content.style';

interface SiteContentProps {
    children?: React.ReactNode
}

const SiteContent: React.FC<SiteContentProps> = ({
    children
}) => (
    <div
        className={ contentClassName }
        children={ children }/>
);

export default SiteContent;
