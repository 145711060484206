import React, { useState } from 'react';
import { COUNTRY_COMPARISON_RESULTS_MAX_SELECTION } from '../../../constants';
import { useAppState } from '../../../state/hook';
import { ComponentWithFilterHandlersProps } from '../../../state/types';
import Button from '../../common/button/button.component';
import TaxonomyFilter from '../../common/taxonomy-filter/taxonomy-filter.component';
import Title from '../../common/title/title.component';
import {
    countryComparisonTitleAndFiltersClassName,
    countryComparisonTitleAndFiltersTopClassName,
    countryComparisonTitleAndFiltersBottomClassName,
    countryComparisonTitleAndFiltersTopDescriptionClassName
} from './country-comparison-title-and-filters.style';

interface CountryComparisonTitleAndFiltersProps extends ComponentWithFilterHandlersProps {
    title: string,
    description: string
}

const CountryComparisonTitleAndFilters: React.FC<CountryComparisonTitleAndFiltersProps> = ({
    title,
    description,
    filters,
    onChangeFilters
}) => {
    const [{taxonomies}] = useAppState();
    const [filterCountryIds, changeFilterCountryIds] = useState<number[]>([...filters.countryIds]);

    const handleClickConfirmFiltersButton = () => {
        onChangeFilters({
            ...filters,
            countryIds: filterCountryIds
        });
    };

    return (
        <div className={ countryComparisonTitleAndFiltersClassName }>
            <div className={ countryComparisonTitleAndFiltersTopClassName }>
                <Title>{ title }</Title>
                <div
                    className={ countryComparisonTitleAndFiltersTopDescriptionClassName }
                    dangerouslySetInnerHTML={ {__html: description} }/>
                <Button onClick={ handleClickConfirmFiltersButton }>
                    Confirm selection
                </Button>
            </div>
            <div className={ countryComparisonTitleAndFiltersBottomClassName }>
                <TaxonomyFilter
                    title='Country'
                    taxonomies={ taxonomies.countries }
                    selectedIds={ filterCountryIds }
                    onChange={ changeFilterCountryIds }
                    grouped='Search for a country...'
                    maxSelections={ COUNTRY_COMPARISON_RESULTS_MAX_SELECTION }/>
            </div>
        </div>
    );
};

export default CountryComparisonTitleAndFilters;
