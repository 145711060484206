import React, { useState } from 'react';
import { classes } from 'typestyle';
import {
    collapsibleContentClassName,
    collapsibleContentTitleClassName,
    collapsibleContentContentClassName,
    collapsibleContentTitleOpenContentClassName,
    collapsibleContentTitleDisabledClassName,
    collapsibleContentLineClassName,
    collapsibleContentIconClassName
} from './collapsible-content.style';

interface CollapsibleContentProps {
    title: string,
    children?: React.ReactNode,
    initExpanded?: boolean,
    line?: boolean
}

const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
    title,
    children,
    initExpanded = false,
    line = true
}) => {
    const [showContent, changeShowContent] = useState<boolean>(initExpanded);

    return (
        <div className={ collapsibleContentClassName }>
            <div
                className={ classes(
                    collapsibleContentTitleClassName,
                    !children && collapsibleContentTitleDisabledClassName,
                    !!children && showContent && collapsibleContentTitleOpenContentClassName
                ) }
                onClick={ () => changeShowContent(!showContent) }
                children={ (
                    <>
                        { title }
                        { children && (
                            <span className={ collapsibleContentIconClassName }>
                                <span></span>
                                <span></span>
                            </span>
                        ) }
                    </>
                ) }/>
            { line && <div className={ collapsibleContentLineClassName }/> }
            { (children && showContent) && (
                <div className={ collapsibleContentContentClassName }>
                    { children }
                </div>
            ) }
        </div>
    );
};

export default CollapsibleContent;
