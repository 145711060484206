import React from 'react';
import { classes } from 'typestyle';
import Checkbox from '../checkbox/checkbox.component';
import {
    taxonomyListClassName,
    taxonomyListListClassName,
    taxonomyListListItemClassName,
    taxonomyListCheckboxContainerNameClassName,
    taxonomyListNameClassName,
    taxonomyListNameClickableClassName,
    taxonomyListSectionClassName
} from './taxonomy-list.style';
import { TaxonomyListProps } from './taxonomy-list.types';

const TaxonomyList: React.FC<TaxonomyListProps> = ({
    list,
    selectedIds,
    onChange,
    children,
    className
}) => {
    return (
        <div className={ classes(taxonomyListClassName, className) }>
            { children && (
                <div className={ taxonomyListSectionClassName }>
                    { children }
                </div>
            ) }
            <ul data-list className={ taxonomyListListClassName }>
                { list.map((taxonomyData, index) => (
                    <li key={ index } className={ taxonomyListListItemClassName }>
                        { selectedIds && (
                            <div className={ taxonomyListCheckboxContainerNameClassName }>
                                <Checkbox
                                    value={ selectedIds.indexOf(taxonomyData.id) !== -1 }
                                    onChange={ newValue => onChange && onChange(
                                        newValue ? [...selectedIds, taxonomyData.id] : selectedIds.filter(id => id !== taxonomyData.id)
                                    ) }/>
                            </div>
                        ) }
                        <div className={ taxonomyListNameClassName }>
                            <span className={ taxonomyListNameClickableClassName } onClick={ event => {
                                event.preventDefault();

                                if (selectedIds && onChange) {
                                    const newValue = !(selectedIds.indexOf(taxonomyData.id) !== -1);

                                    onChange(
                                        newValue
                                        ? [...selectedIds, taxonomyData.id]
                                        : selectedIds.filter(id => id !== taxonomyData.id)
                                    );
                                }
                            } }>
                                { taxonomyData.name }
                            </span>
                        </div>
                    </li>
                )) }
            </ul>
        </div>
    )
};

export default TaxonomyList;
