import React from 'react';
import { classes } from 'typestyle';
import {
    checkboxClassName,
    checkboxActiveClassName,
    checkboxDisabledClassName
} from './checkbox.style';

interface CheckboxProps {
    value: boolean,
    onChange?(newValue: boolean): void,
    disabled?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
    value,
    onChange,
    disabled
}) => (
    <div
        className={ classes(
            checkboxClassName,
            value && checkboxActiveClassName,
            disabled && checkboxDisabledClassName
        ) }
        onClick={ event => {
            event.preventDefault();
            
            if (onChange) {
                onChange(!value);
            }
        } }/>
);

export default Checkbox;
