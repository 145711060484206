import React from 'react';
import { siteWrapperClassName } from './site-wrapper.style';

interface SiteWrappProps {
    children?: React.ReactNode
}

const SiteWrapper: React.FC<SiteWrappProps> = ({
    children
}) => (
    <div className={ siteWrapperClassName }>
        { children }
    </div>
);

export default SiteWrapper;
