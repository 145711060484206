import { style } from 'typestyle';
import {
    COLOR_07,
    COLOR_12,
    COLOR_WHITE,
    FONT_MAIN,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';

export const filtersWrapperClassName = style({
    borderTop: `solid 1px ${COLOR_07}`,
    padding: `${MARGINS_PX * 2}px 0`,
    position: 'relative'
});

export const filtersHeaderClassName = style({
    padding: `0 0 ${MARGINS_PX * 2}px 0`
});

export const filterItemsBackground = style({
    display: 'none',
    position: 'absolute',
    backgroundColor: COLOR_07,
    width: '100%',
    height: '50px'
});

export const filterItemsClassName = style({
    padding: `0`,
    margin: `-${MARGINS_PX / 2}px`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
});

export const filterItemClassName = style({
    padding: (MARGINS_PX / 2) + 'px',
    boxSizing: 'border-box',
    display: 'block',
    width: '25%'
});

export const filterButtonPadClassName = style({
    padding: `${MARGINS_PX}px 0 0 0`,
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
    textAlign: 'right'
});

export const filterCountryRegionOptionsContainerClassName = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '-5px 0'
});

export const filterCountryRegionSelectAllCountriesContainerClassName = style({
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: '12px',
    color: COLOR_12,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    backgroundColor: COLOR_WHITE,
    border: 'none',
    margin: `5px ${MARGINS_PX}px 5px 0`,
    padding: 0,
    cursor: 'pointer',

    $nest: {
        'span': {
            margin: 0
        }
    }
});

export const filterCountryRegionToggleClassName = style({
    flexGrow: 1,
    textAlign: 'right'
});

export const filterCountryRegionToggleButtonClassName = style({
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: '12px',
    color: COLOR_12,
    display: 'inline',
    backgroundColor: COLOR_WHITE,
    border: 'none',
    margin: '5px 0',
    padding: 0,
    cursor: 'pointer'
});
