import { style } from 'typestyle';
import { COLOR_12, COLOR_WHITE, FONT_WEIGHT_BOLD, MARGINS_PX } from '../../../constants';

export const resutsButtonpadSelectClassName = style({
    height: '40px',
    lineHeight: '40px',
    padding: `0 ${MARGINS_PX}px`,
    borderRadius: '8px',
    background: COLOR_WHITE,
    boxShadow: '0 0 10px 3px rgb(0 0 0 / 15%)',
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: '14px',
    color: COLOR_12,
    position: 'relative'
});

export const resutsButtonpadButtonClassName = style({
    whiteSpace: 'nowrap',
    height: '40px',
    boxSizing: 'border-box',
    padding: `0 ${MARGINS_PX}px`,
    lineHeight: '40px',
    textDecoration: 'none'
});

export const resutsButtonpadIconClassName = style({
    margin: '0 0 0 6px'
});

export const resutsButtonpadIconDropdownClassName = style({
    transform: 'rotate(180deg)',
    float: 'right',
    marginTop: '17px'
});
