import React from 'react';
import { classes } from 'typestyle';
import Box from '../box/box.component';
import {
    tableBackgroundClassName,
    tableScrollClassName,
    tableClassName,
    tableContainerClassName,
    tableFixedClassName
} from './table.style';

interface TableProps {
    children?: React.ReactNode,
    fixedColumns?: boolean
}

const Table: React.FC<TableProps> = ({
    children,
    fixedColumns = false
}) => {
    return (
        <Box className={ tableContainerClassName }>
            <div className={ tableBackgroundClassName }/>
            <div className={ tableScrollClassName }>
                <table
                    border={ 0 }
                    cellSpacing='0'
                    className={ classes(
                        tableClassName,
                        fixedColumns && tableFixedClassName
                    ) }
                    children={ children }/>
            </div>
        </Box>
    );
};

export default Table;
