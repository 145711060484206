import { style } from 'typestyle';
import {
    COLOR_05,
    COLOR_21,
    COLOR_22,
    CONTENT_MAX_WIDTH_PX,
    FONT_WEIGHT_REGULAR,
    MARGINS_PX
} from '../../../constants';
import { tabletStyles } from '../../../utils';

export const pageTitleClassName = style({
    display: 'block',
    background: COLOR_21,
    position: 'relative',
    padding: 0,
    borderBottom: 'solid 1px ' + COLOR_22,
    overflow: 'hidden'
});

export const pageTitleContentClassName = style({
    display: 'block',
    margin: '0 auto',
    width: '100%',
    maxWidth: CONTENT_MAX_WIDTH_PX + 'px'
}, tabletStyles({
    $nest: {
        '&::after': {
            display: 'block',
            position: 'relative',
            content: '""',
            clear: 'both'
        }
    }
}));

export const pageTitleLeftClassName = style({
    margin: `${MARGINS_PX * 2}px 0 ${MARGINS_PX}px 0`
}, tabletStyles({
    display: 'block',
    float: 'left',
    minWidth: '25%',
    margin: `${MARGINS_PX * 2}px ${MARGINS_PX * 2}px ${MARGINS_PX * 2}px 0`
}));

export const pageTitleRightClassName = style({
    color: COLOR_05,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: '14px',
    lineHeight: '21px',
    margin: `${MARGINS_PX}px 0 ${MARGINS_PX * 2}px 0`
}, tabletStyles({
    display: 'block',
    overflow: 'hidden',
    margin: 0
}));

export const pageTitleRightInnerClassName = style({
    margin: 0
}, tabletStyles({
    margin: `${MARGINS_PX * 2}px 0`
}));

export const pageTitleWithChildrenClassName = style({
    
});

export const pageTitleChildrenClassName = style({
    margin: `${MARGINS_PX * 2}px 0`
});
