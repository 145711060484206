import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { logoFull4x, logoUNWomenPNG } from '../assets';
import { COLOR_05, COLOR_12, FONT_WEIGHT_REGULAR } from '../constants';
import { getCurrentDate, getLargeDate } from '../utils';

const createPDF = (): JsPDF => {
    const pdfInstance = new JsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'letter',
        putOnlyUsedFonts: true
    });

    return pdfInstance;
};

const addPDFHeader = (pdfInstance: JsPDF, dataDate: string): void => {
    pdfInstance.addImage(
        logoFull4x, 'PNG',
        10, 8,
        48, 0
    );
    pdfInstance.addImage(
        logoUNWomenPNG, 'PNG',
        186, 8,
        20, 0
    );
    pdfInstance.setFont('Helvetica');
    pdfInstance.setFontSize(8);
    pdfInstance.setTextColor(COLOR_05);
    pdfInstance.text(
        'Source: UN Gender Quota Portal',
        123.5,
        12.2
    );
    pdfInstance.text(
        'Reference date: ' + dataDate,
        65,
        12.2
    );
    pdfInstance.text(
        'Information downloaded: ' +
        getLargeDate(getCurrentDate()),
        65,
        15.2
    );
};

const addPDFTitle = (pdfInstance: JsPDF, text: string): number => {
    pdfInstance.setFont('Helvetica');
    pdfInstance.setFontSize(18);
    pdfInstance.setTextColor(COLOR_05);
    pdfInstance.text(
        text,
        10,
        28
    );

    return 40;
};

const addPDFFooter = (
    pdfInstance: JsPDF,
    pageAndMaxPage: [page: number, maxPage: number]
): void => {
    pdfInstance.setFont('Helvetica', undefined, FONT_WEIGHT_REGULAR);
    pdfInstance.setFontSize(9);
    pdfInstance.setTextColor(COLOR_12);
    pdfInstance.text(
        'Page ' + pageAndMaxPage[0] + ' - ' + pageAndMaxPage[1],
        189,
        273
    );
};

const replaceInvalidCharacters = (text: string | number): string => (
    String(text)
        .replaceAll('<br />', '\n')
);

const downloadPDFTable = (
    fileTitle: string,
    fileName: string,
    tableHeaders: string[],
    tableRows: (string | number)[][],
    dataDate: string,
    columnSizePercentages?: number[]
): void => {
    const pdfInstance = createPDF();
    const tableMargins = [22.5 + 12, 9, 10];
    const tableWidth = pdfInstance.internal.pageSize.getWidth() - (tableMargins[1] * 2);

    autoTable(pdfInstance, {
        theme: 'plain',
        margin: tableMargins,
        didDrawPage: () => {
            addPDFHeader(pdfInstance, dataDate);
            addPDFTitle(pdfInstance, fileTitle);
        },
        columnStyles: (() => {
            if (columnSizePercentages) {
                const result: {[key: number]: {cellWidth: number}} = {};

                columnSizePercentages.forEach((columnSizePercentage, index) => {
                    result[index] = {
                        cellWidth: (columnSizePercentage / 100) * tableWidth
                    }
                });

                return result;
            }
        })(),
        didParseCell: data => {
            data.cell.styles.textColor = COLOR_12;
            data.cell.styles.fillColor = '#ffffff';

            switch(data.section) {
                case 'head':
                    data.cell.styles.fontSize = 6;
                    break;

                case 'body':
                    data.cell.styles.fontSize = 7;
                    break;
            }
        },
        willDrawCell: data => {
            if (data.row.section === 'body') {
                pdfInstance.setDrawColor('#c2c2c2');
                pdfInstance.setLineWidth(.5);
                pdfInstance.line(
                    data.cell.x,
                    data.cell.y,
                    data.cell.x + data.cell.width,
                    data.cell.y
                );
            }
        },
        head: [tableHeaders.map(headerString => headerString.toUpperCase())],
        body: tableRows.map(row => row.map(replaceInvalidCharacters))
    });

    const maxPage: number = pdfInstance.getNumberOfPages();

    for (let currentPage = 1; currentPage <= maxPage; currentPage++) {
        pdfInstance.setPage(currentPage);
        addPDFFooter(pdfInstance, [currentPage, maxPage]);
    }

    pdfInstance.save(fileName + '.pdf');
};

export const downloadPDFHandler = (
    fileTitle: string,
    fileName: string,
    tableHeaders: string[],
    tableRows: (string | number)[][],
    dataDate: string,
    columnSizePercentages?: number[]
) => (): void => downloadPDFTable(
    fileTitle,
    fileName,
    tableHeaders,
    tableRows,
    dataDate,
    columnSizePercentages
);
