import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import {
    ROUTE_PAGE_COUNTRY_PROFILE,
    ROUTE_PAGE_HOME,
    ROUTE_PAGE_LOGIN,
    ROUTE_PAGE_ABOUT,
    ROUTE_PAGE_QUOTA_ANALYSIS,
    ROUTE_PAGE_COUNTRY_COMPARISON,
    ROUTE_PAGE_LOGOUT,
    ROUTE_PAGE_TERMS_OF_USE,
    ROUTE_PAGE_PRIVACY_NOTICE
} from './constants';
import AboutPage from './components/pages/about-page/about-page.component';
import CountryComparisonPage from './components/pages/country-comparison/country-comparison-page.component';
import CountryProfilePage from './components/pages/country-profile-page/country-profile-page.component';
import HomePage from './components/pages/home-page/home-page.component';
import LoginPage from './components/pages/login-page/login-page.component';
import QuotaAnalysisPage from './components/pages/quota-analysis/quota-analysis-page.component';
import LogoutPage from './components/pages/logout-page/logout-page.component';
import TermsOfUsePage from './components/pages/terms-of-use-page/terms-of-use-page.component';
import PrivacyNoticePage from './components/pages/privacy-notice-page/privacy-notice-page.component';

export const LoggedRouter: React.FC = () => {
    return (
        <Routes>
            <Route path={ ROUTE_PAGE_HOME } element={ <HomePage/> }/>
            <Route path={ ROUTE_PAGE_COUNTRY_PROFILE } element={ <CountryProfilePage/> }/>
            <Route path={ ROUTE_PAGE_QUOTA_ANALYSIS } element={ <QuotaAnalysisPage/> }/>
            <Route path={ ROUTE_PAGE_COUNTRY_COMPARISON } element={ <CountryComparisonPage/> }/>
            <Route path={ ROUTE_PAGE_ABOUT } element={ <AboutPage/> }/>
            <Route path={ ROUTE_PAGE_TERMS_OF_USE } element={ <TermsOfUsePage/> }/>
            <Route path={ ROUTE_PAGE_PRIVACY_NOTICE } element={ <PrivacyNoticePage/> }/>
            <Route path={ ROUTE_PAGE_LOGOUT } element={ <LogoutPage/> }/>
            <Route path='*' element={ <Navigate to={ ROUTE_PAGE_HOME }/> }/>
        </Routes>
    );
};

export const NotLoggedRouter: React.FC = () => {
    return (
        <Routes>
            <Route path={ ROUTE_PAGE_LOGIN } element={ <LoginPage/> }/>
            <Route path={ ROUTE_PAGE_LOGOUT } element={ <LogoutPage/> }/>
            <Route path='*' element={ <Navigate to={ ROUTE_PAGE_LOGIN }/> }/>
        </Routes>
    );
};
