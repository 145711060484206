import { style } from 'typestyle';
import {
    PAGE_CONTENT_ZINDEX,
    MARGINS_PX,
    PAGE_MENU_HEIGHT_PX,
    PAGE_MENU_ZINDEX,
    TABLET_PAGE_MENU_HEIGHT_PX,
    DESKTOP_PAGE_MENU_HEIGHT_PX,
    COLOR_WHITE
} from '../../../constants';
import {
    desktopStyles, mobileOnlyStyles, tabletStyles,
} from '../../../utils';

export const mainMenuWrapperClassName = style({
    height: PAGE_MENU_HEIGHT_PX + 'px',
    display: 'block',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    width: '100%',
    zIndex: PAGE_MENU_ZINDEX,
    boxShadow: '0px 0px 10px 0px rgb(58 144 192 / 80%)'
}, tabletStyles({
    height: TABLET_PAGE_MENU_HEIGHT_PX + 'px',
    backdropFilter: 'blur(10px)',
    background: 'rgba(255, 255, 255, .8)'
}), desktopStyles({
    height: DESKTOP_PAGE_MENU_HEIGHT_PX + 'px'
}));

export const mainMenuClassName = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    margin: '0 auto',
    height: '100%',
    boxSizing: 'border-box',
    padding: `10px ${MARGINS_PX}px`,
}, mobileOnlyStyles({
    backgroundColor: COLOR_WHITE
}), desktopStyles({
    padding: `${MARGINS_PX}px`
}));

export const mainMenuLeftSideClassName = style({
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
});

export const mainMenuRightSideClassName = style({
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: MARGINS_PX + 'px'
}, tabletStyles({
    paddingLeft: 0
}));

export const topbarHomeLinkClassName = style({
    textDecoration: 'none'
});

export const mainMenuLogoImgClassName = style({
    display: 'block',
    height: '100%',
    width: 'auto',
    maxWidth: '100%'
});

export const contentClassName = style({
    display: 'block',
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: PAGE_CONTENT_ZINDEX,
});
