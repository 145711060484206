import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { classes } from 'typestyle';
import {
    ROUTE_PAGE_ABOUT,
    ROUTE_PAGE_COUNTRY_COMPARISON,
    ROUTE_PAGE_COUNTRY_PROFILE,
    ROUTE_PAGE_QUOTA_ANALYSIS
} from '../../../constants';
import {
    mainNavClassName,
    mainNavWrapperClassName,
    mainNavOptionsClassName,
    mainNavOptionClassName,
    mainNavToggleButtonClassName,
    mainNavActiveBackgroundClassName,
    mainNavActiveClassName,
    mainNavOptionActiveClassName,
    mainNavOptionUnderlineClassName,
    mainNavOptionItemClassName
} from './main-nav.style';

const MainNav: React.FC = () => {
    const [menuOpen, changeMenuOpen] = useState<boolean>(false);
    const handleClickToggleButton = (event: React.MouseEvent) => {
        event.preventDefault();
        changeMenuOpen(!menuOpen);
    };
    const handleClickLink = () => menuOpen && changeMenuOpen(false);
    const mainNavOption = (
        name: string,
        link: string
    ) => (
        <span className={ mainNavOptionItemClassName }>
            <NavLink
                className={ ({isActive}) => mainNavOptionClassName + (isActive ? ` ${mainNavOptionActiveClassName}` : '') }
                onClick={ handleClickLink }
                to={ link }>

                    { name }
                    <span className={ mainNavOptionUnderlineClassName }></span>
            </NavLink>
        </span>
    );

    return (
        <div className={ classes(mainNavClassName, menuOpen && mainNavActiveClassName) }>
            <div
                className={ mainNavActiveBackgroundClassName }
                onClick={ () => changeMenuOpen(false) }/>
            <div className={ mainNavWrapperClassName }>
                <nav className={ mainNavOptionsClassName }>
                    { mainNavOption('Country Profiles', ROUTE_PAGE_COUNTRY_PROFILE) }
                    { mainNavOption('Quota Analysis', ROUTE_PAGE_QUOTA_ANALYSIS) }
                    { mainNavOption('Country Comparison', ROUTE_PAGE_COUNTRY_COMPARISON) }
                    { mainNavOption('About', ROUTE_PAGE_ABOUT) }
                </nav>
            </div>
            <button type='button' className={ mainNavToggleButtonClassName } onClick={ handleClickToggleButton }>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
    );
};

export default MainNav;
