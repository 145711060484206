import React from 'react';
import { classes } from 'typestyle';
import { iconDropdown } from '../../../assets';
import { Option } from '../../../state/types';
import {
    dropdownImgClassName,
    fieldWrapperClassName,
    inputClassName,
    inputIconClassName,
    inputIconRightClassName,
    selectInvisibleClassName
} from './form.style';

interface SelectProps {
    value: string,
    options: Option[],
    children?: React.ReactNode,
    onChange(newValue: string): void
}

const Select: React.FC<SelectProps> = ({
    value,
    options,
    children,
    onChange
}) => (
    <div className={ fieldWrapperClassName }>
        { children ? (
            children
        ) : (
            <>
                <div className={ classes(inputIconClassName, inputIconRightClassName) }>
                    <img
                        src={ iconDropdown }
                        alt='Dropdown icon'
                        width='10px'
                        className={ dropdownImgClassName }/>
                </div>
                <input
                    type='text'
                    className={ inputClassName }
                    value={ value }
                    readOnly/>
            </>
        ) }
        { options.length && (
            <select
                className={ selectInvisibleClassName }
                value={ value }
                onChange={ event => onChange(event.target.value) }
                children={ options.map((option, index) => (
                    <option key={ index } value={ (typeof option === 'string') ? option : option.value }>
                        { (typeof option === 'string') ? option : option.label }
                    </option>
                )) }/>
        ) }
    </div>
);

export default Select;
