import React from 'react';
import { classes } from 'typestyle';
import {
    spacerClassName,
    spacerContentClassName,
    spacer2XClassName,
    spacer2XContentClassName
} from './spacer.style';

interface SpacerProps {
    x2?: boolean,
    children?: React.ReactNode
}

const Spacer: React.FC<SpacerProps> = ({
    x2 = false,
    children
}) => (
    <div className={ classes(spacerClassName, x2 && spacer2XClassName) }>
        { children && (
            <div className={ classes(spacerContentClassName, x2 && spacer2XContentClassName) }>
                { children }
            </div>
        ) }
    </div>
);

export default Spacer;
