import { style } from 'typestyle';
import {
    COLOR_06,
    COLOR_19,
    DESKTOP_PAGE_FOOTER_HEIGHT_PX,
    DESKTOP_PAGE_MENU_HEIGHT_PX,
    PAGE_CONTENT_ZINDEX,
    PAGE_FOOTER_HEIGHT_PX,
    PAGE_MENU_HEIGHT_PX,
    TABLET_PAGE_FOOTER_HEIGHT_PX,
    TABLET_PAGE_MENU_HEIGHT_PX
} from '../../../constants';
import {
    desktopStyles,
    tabletStyles
} from '../../../utils';

export const pageClassName = style({
    $debugName: 'page.component',
    
    display: 'block',
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: PAGE_CONTENT_ZINDEX,
    margin: '0 auto',
    padding: `${PAGE_MENU_HEIGHT_PX}px 0 ${PAGE_FOOTER_HEIGHT_PX}px`,
    width: '100%',
    minHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto'
}, tabletStyles({
    padding: `${TABLET_PAGE_MENU_HEIGHT_PX}px 0 ${TABLET_PAGE_FOOTER_HEIGHT_PX}px`
}), desktopStyles({
    padding: `${DESKTOP_PAGE_MENU_HEIGHT_PX}px 0 ${DESKTOP_PAGE_FOOTER_HEIGHT_PX}px`
}));

export const pageInnerClassName = style({
    position: 'relative',
    backgroundColor: COLOR_06,
    minHeight: `calc(100vh - ${PAGE_MENU_HEIGHT_PX + PAGE_FOOTER_HEIGHT_PX}px)`,
    zIndex: 2
}, tabletStyles({
    minHeight: `calc(100vh - ${TABLET_PAGE_MENU_HEIGHT_PX + TABLET_PAGE_FOOTER_HEIGHT_PX}px)`
}), desktopStyles({
    minHeight: `calc(100vh - ${DESKTOP_PAGE_MENU_HEIGHT_PX + DESKTOP_PAGE_FOOTER_HEIGHT_PX}px)`
}));

export const pageBackgroundChildrenClassName = style({
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: 0,

    $nest: {
        [`& + .${pageInnerClassName}`]: {
            background: 'transparent'
        }
    }
});

export const pageInnerVcenteredClassName = style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
}, tabletStyles({
}), desktopStyles({
}));

export const pageWithoutHeaderClassName = style({
    padding: `0 0 ${PAGE_FOOTER_HEIGHT_PX}px`,

    $nest: {
        [`.${pageInnerClassName}`]: {
            minHeight: `calc(100vh - ${PAGE_FOOTER_HEIGHT_PX}px)`
        }
    }
}, tabletStyles({
    padding: `0 0 ${TABLET_PAGE_FOOTER_HEIGHT_PX}px`,

    $nest: {
        [`.${pageInnerClassName}`]: {
            minHeight: `calc(100vh - ${TABLET_PAGE_FOOTER_HEIGHT_PX}px)`
        }
    }
}), desktopStyles({
    padding: `0 0 ${DESKTOP_PAGE_FOOTER_HEIGHT_PX}px`,

    $nest: {
        [`.${pageInnerClassName}`]: {
            minHeight: `calc(100vh - ${DESKTOP_PAGE_FOOTER_HEIGHT_PX}px)`
        }
    }
}));