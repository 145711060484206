import he from 'he';
import { DataParser, TypeGuard } from './api.types';

export const assertPropertyExists = <T = any>(
    value: any,
    key: string,
    properyParser?: TypeGuard<T>
): T => {
    if (value.hasOwnProperty(key)) {
        return properyParser ? properyParser(value[key]) : value[key];
    }

    throw new Error(`Missing property: "${key}"`);
};

export const assertIsArray = <T = any>(
    value: any,
    itemParser?: TypeGuard<T>
): T[] => {
    if (Array.isArray(value)) {
        return itemParser ? value.map(itemParser) : value;
    }

    throw new Error(`Value is not an iterable object: "${String(value)}"`);
};

export const parseString: TypeGuard<string> = raw => he.decode(String(raw));
export const parseNumber: TypeGuard<number> = raw => Number(raw);
export const parseNumberOrNull: TypeGuard<number | null> = raw => raw ? Number(raw) : null;
export const parseArray: TypeGuard<any[]> = raw => String(raw).split(',');

export const parseArrayDataHandler = <T>(
    dataParser: DataParser<T>
): DataParser<T[]> => {
    return (data) => {
        const result: T[] = [];

        assertIsArray(data).forEach(dataItem => {
            try {
                const parsedItem = dataParser(dataItem);
                result.push(parsedItem);
            }
            catch (error) {
                console.error('Error: ' + error + '. Skipping object');
            }
        });

        return result;
    };
};
