import { style, keyframes } from 'typestyle';
import { COLOR_05 } from '../../../constants';

const firstLoadKeyframeName = keyframes({
    '0%': {
        maxWidth: 0,
    },
    '100%': {
        maxWidth: '100%',
    }
});

export const progressBarClassName = style({
    display: 'block',
    height: '12px',
    position: 'relative',
    width: '100%'
});

export const progressBarCoreClassName = style({
    display: 'block',
    position: 'absolute',
    width: 0,
    height: '100%',
    background: COLOR_05,
    transition: 'width 300ms',
    animation: `${firstLoadKeyframeName} 1200ms linear`,
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px'
});
