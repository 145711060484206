import React, { useEffect } from 'react';
import { classes } from 'typestyle';
import ReactTooltip from 'react-tooltip';
import {
    progressBarClassName,
    progressBarCoreClassName
} from './progress-bar.style';

interface ProgressBarProps {
    progress: number,
    className?: string,
    color?: string,
    tooltipData?: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({
    progress,
    className,
    color,
    tooltipData
}) => {
    const progressValue = progress + '%';
    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <div className={ classes(progressBarClassName, className) }>
            <div
                className={ progressBarCoreClassName }
                style={ {
                    backgroundColor: color,
                    width: progressValue
                } }
                { ...(tooltipData ? {
                    'data-tip': tooltipData
                } : {}) }/>
        </div>
    );
};

export default ProgressBar;
