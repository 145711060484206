import React from 'react';
import { 
    spinnerWrapper,
    spinnerClassName
} from './spinner.style';

const Spinner: React.FC = () => (
    <div className={ spinnerWrapper }>
        <span className={ spinnerClassName }/>
    </div>
);

export default Spinner;
