import React from 'react';
import { classes } from 'typestyle';
import { contentClassName } from './content.style';

interface ContentProps {
    children: React.ReactNode;
    className?: string
}

const Content: React.FC<ContentProps> = ({
    children,
    className
}) => (
    <div
        className={ classes(contentClassName, className) }
        children={ children }/>
);

export default Content;
