import React from 'react';
import { classes } from 'typestyle';
import ProgressBar from '../progress-bar/progress-bar.component';
import { getPercentageValue, getRange } from './bars.service';
import {
    barsClassName,
    barsSideClassName,
    barsSideLeftClassName,
    barsSideRightClassName,
    barsItemWrapperClassName,
    barsItemClassName,
    barsProgressBarClassName,
    barsLabelClassName,
    barsLabelInnerClassName,
    barsRangeClassName,
    barsRangeValueClassName,
    barsItemsClassName,
    barsLinesClassName,
    barsLineClassName
} from './bars.style';

interface BarsProps {
    data: [value: number, label: React.ReactNode, tooltipData?: string][],
    className?: string
}

const Bars: React.FC<BarsProps> = ({
    data,
    className
}) => {
    const rangeMax: number = (() => {
        let calculatedMax: number = 0;

        data.forEach(bar => {
            if (bar[0] > calculatedMax) {
                calculatedMax = bar[0];
            }
        });
    
        return Math.ceil(calculatedMax / 10) * 10;
    })();
    const range = getRange(rangeMax);
    const rangeStepSizePercentage: number = 100 / (range.length - 1);

    return (
        <div className={ classes(barsClassName, className) }>
            <div className={ classes(barsSideClassName, barsSideLeftClassName) }>
                <div className={ barsLinesClassName }>
                    { range.map((value, index) => (
                        <div
                            key={ index }
                            className={ barsLineClassName }
                            style={ {
                                left: (index * rangeStepSizePercentage) + '%'
                            } }/>
                    )) }
                </div>
                <div className={ barsItemsClassName }>
                    { data.map((barData, index) => {
                        const percentageValue = getPercentageValue(rangeMax, barData[0]);

                        return (
                            <div key={ index } className={ barsItemWrapperClassName } style={ {height: (100 / data.length) + '%'} }>
                                <div className={ barsItemClassName }>
                                    <ProgressBar
                                        className={ barsProgressBarClassName }
                                        progress={ percentageValue }
                                        tooltipData={ barData[2] }/>
                                </div>
                            </div>
                        )
                    }) }
                </div>
                <div className={ barsRangeClassName }>
                    { range.map((value, index) => (
                        <div key={ index } className={ barsRangeValueClassName } style={ {left: (index * rangeStepSizePercentage) + '%'} }>
                            { value }
                        </div>
                    )) }
                </div>
            </div>
            <div className={ classes(barsSideClassName, barsSideRightClassName) }>
                <div className={ barsItemsClassName }>
                    { data.map((barData, index) => (
                        <div key={ index } className={ barsItemWrapperClassName }>
                            <div className={ barsItemClassName }>
                                <div className={ barsLabelClassName }>
                                    <div className={ barsLabelInnerClassName }>
                                        { barData[1] }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) }
                </div>
                <div className={ barsRangeClassName }/>
            </div>
        </div>
    );
};

export default Bars;