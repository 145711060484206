import { style } from 'typestyle';
import {
    COLOR_01,
    COLOR_05,
    COLOR_12,
    FONT_WEIGHT_BLACK,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_LIGHT,
    FONT_WEIGHT_REGULAR,
    MARGINS_PX
} from '../../../constants';
import { mixinFont } from '../../../style.mixin';
import { tabletStyles } from '../../../utils';

const contentHeightPx: number = 560;

export const countryProfileClassName = style({
    padding: `${MARGINS_PX}px 0`
});

export const countryProfileFiltersCellClassName = style();

export const countryProfileFilterCountriesClassName = style({
    height: '180px',
    width: '100%'
}, tabletStyles({
    height: contentHeightPx + 'px',
    width: '240px'
}));

export const countryProfileDataWrapperClassName = style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    width: '100%'
});

export const countryProfileDataHeaderClassName = style({
    flexShrink: 0,
    flexGrow: 0
});

export const countryProfileDataContent1ClassName = style({
    flexShrink: 0,
    flexGrow: 0
});

export const countryProfileDataContent2ClassName = style({
    flexShrink: 1,
    flexGrow: 1
});

export const countryProfileDataContentBoxClassName = style({
    height: '100%'
});

export const countryProfileContentCellClassName = style({
});

export const countryProfileDonutsWrapperClassName = style({
    width: '60% !important',
    flexBasis: '60%',
    flexShrink: '0 !important'
});

export const countryProfileImplementationTitleClassName = style({
    ...mixinFont(12, FONT_WEIGHT_BOLD)
});

export const countryProfileImplementationDescriptionClassName = style({
    ...mixinFont(12, FONT_WEIGHT_REGULAR),
    marginBottom: MARGINS_PX + 'px'
});

export const countryProfileCountryNameClassName = style({
    ...mixinFont(32, FONT_WEIGHT_BOLD, undefined, COLOR_05)
});

export const countryProfileRegionNameClassName = style({
    ...mixinFont(16, FONT_WEIGHT_BOLD, undefined, COLOR_05)
});

export const countryProfileSectionTitleClassName = style({
    ...mixinFont(16, FONT_WEIGHT_BLACK)
});

export const countryProfileSectionContentClassName = style({
    margin: `0 ${MARGINS_PX}px ${MARGINS_PX}px`
});

export const countryProfileActiveListItemClassName = style({
    color: COLOR_01
});

export const countryProfileCheckListClassName = style({
    display: 'block',
    listStyle: 'none',
    margin: 0,
    padding: 0
});

export const countryProfileCheckListItemClassName = style({
    display: 'block',
    position: 'relative',
    margin: `0 0 ${MARGINS_PX / 2}px 0`,
    padding: '0 0 0 20px',
    ...mixinFont(12, FONT_WEIGHT_LIGHT),

    $nest: {
        'img': {
            display: 'none'
        }
    }
});

export const countryProfileCheckListActiveItemClassName = style({
    color: COLOR_01 + '!important',

    $nest: {
        'img': {
            display: 'block!important',
            position: 'absolute',
            left: 0,
            top: '3px'
        }
    }
});

export const countryProfileLegalProvisionsWrapperClassName = style({
    ...mixinFont(12, FONT_WEIGHT_REGULAR)
});

export const countryProfilePageSmallTextClassName = style({
    display: 'block',
    opacity: .5,
    padding: `0 ${MARGINS_PX}px ${MARGINS_PX}px`,
    maxWidth: '270px',
    boxSizing: 'border-box',
    ...mixinFont(12, FONT_WEIGHT_REGULAR, 15, COLOR_12)
});

export const noResultsTitleClassName = style({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
    ...mixinFont(16, FONT_WEIGHT_REGULAR, 16, COLOR_12)
});

export const quotaTypeNameClassName = style({
    ...mixinFont(16, FONT_WEIGHT_BLACK, 20, COLOR_05),
    margin: `0 0 ${MARGINS_PX / 2}px 0`
});
