import React from 'react';
import { classes } from 'typestyle';
import {
    pageClassName,
    pageWithoutHeaderClassName,
    pageInnerClassName,
    pageInnerVcenteredClassName,
    pageBackgroundChildrenClassName
} from './page.style';

interface PageProps {
    children: React.ReactNode,
    vCentered?: boolean,
    withoutHeader?: boolean,
    backgroundChildren?: React.ReactNode
}

const Page: React.FC<PageProps> = ({
    children,
    vCentered = false,
    withoutHeader = false,
    backgroundChildren
}) => (
    <div className={ classes(
        pageClassName,
        withoutHeader && pageWithoutHeaderClassName
    ) }>
        { backgroundChildren && (
            <div
                className={ pageBackgroundChildrenClassName }
                children={ backgroundChildren }/>
        ) }
        <div
            className={ classes(
                pageInnerClassName,
                vCentered && pageInnerVcenteredClassName
            ) }
            children={ children }/>
    </div>
);

export default Page;
