import { style } from 'typestyle';
import {
    COLOR_05,
    COLOR_24,
    COLOR_28,
    COLOR_WHITE,
    FONT_MAIN,
    FONT_WEIGHT_BLACK,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';
import { mixinFont } from '../../../style.mixin';
import { desktopStyles, tabletStyles } from '../../../utils';

export const globalStatisticsClassName = style({
    borderTop: `solid 1px ${COLOR_WHITE}`,
    padding: `${MARGINS_PX * 2}px 0`
});

export const globalStatisticsDropdownTitleClassName = style({
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: '32px',
    color: COLOR_05,
    lineHeight: '32px',
    width: '100%',
    whiteSpace: 'normal'
}, tabletStyles({
    whiteSpace: 'nowrap'
}));

export const globalStatisticsDropdownIconClassName = style({
    height: '12px',
    transform: 'rotate(180deg)',
    margin: '0 0 2px 10px'
});

const gridBorder: string = `solid 1px ${COLOR_28}`;
export const globalStatisticsGridWrapperNameClassName = style({
    $nest: {
        '& > div': {
            margin: `0px`,

            $nest: {
                '& > div': {
                    borderBottom: gridBorder,
                    padding: `${MARGINS_PX}px 0`,

                    $nest: {
                        '&:last-child': {
                            borderBottom: 0
                        }
                    }
                }
            }
        }
    }
}, tabletStyles({
    $nest: {
        '& > div': {

            $nest: {
                '& > div': {
                    padding: `${MARGINS_PX}px`,

                    $nest: {
                        '&:nth-child(1), &:nth-child(3)': {
                            borderRight: gridBorder
                        },
                        '&:nth-child(3)': {
                            borderBottom: 0
                        }
                    }
                }
            }
        }
    }
}), desktopStyles({
    $nest: {
        '& > div': {

            $nest: {
                '& > div': {
                    padding: `0 ${MARGINS_PX}px`,
                    borderRight: gridBorder,

                    $nest: {
                        '&:nth-child(1), &:nth-child(2)': {
                            borderBottom: 0
                        },
                        '&:last-child': {
                            borderRight: 0
                        }
                    }
                }
            }
        }
    }
}));

export const globalStatisticsDataNameClassName = style({
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: '16px',
    color: COLOR_24,
    margin: `0 0 ${MARGINS_PX}px 0`
});

export const globalStatisticsListClassName = style({
    display: 'block',
    padding: 0,
    margin: 0,
    border: 0
});

export const globalStatisticsListNumberClassName = style({
    flexShrink: 0,
    flexGrow: 0,
    textAlign: 'right',
    padding: `${MARGINS_PX / 4}px 0`,
    verticalAlign: 'middle',
    ...mixinFont(42, FONT_WEIGHT_BLACK, 42, COLOR_05)
});

export const globalStatisticsListDescriptionClassName = style({
    flexShrink: 1,
    flexGrow: 1,
    textAlign: 'left',
    padding: `0 0 0 ${MARGINS_PX}px`,
    verticalAlign: 'middle',
    ...mixinFont(12, FONT_WEIGHT_MEDIUM, undefined, COLOR_05)
});

export const globalStatisticsNoteClassName = style({
    margin: `${MARGINS_PX}px 0 0 0`,
    ...mixinFont(10, FONT_WEIGHT_MEDIUM, undefined, COLOR_05)
});

export const globalStatisticsBarsClassName = style({
    minHeight: '200px'
});

export const globalStatisticsSubtitleClassName = style({
    ...mixinFont(18, FONT_WEIGHT_MEDIUM, 20),
    margin: '8px 0 0 0',
    opacity: .4
});
