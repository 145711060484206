import React from 'react';
import { classes } from 'typestyle';
import { COLOR_10, COLOR_29, NOT_APPLICABLE_TEXT } from '../../../constants';
import { CountryProfile } from '../../../state/types';
import Donut from '../donut/donut.component';
import { Cell, Grid } from '../grid/grid.component';
import {
    donutsSmallLabel01ClassName,
    donutsSmallLabel02ClassName,
    donutsSmallLabelClassName,
    donutsWrapperClassName
} from './country-profile-donuts.style';

interface CountryProfileDonutsProps {
    countryProfile: CountryProfile
}

const CountryProfileDonuts: React.FC<CountryProfileDonutsProps> = ({
    countryProfile
}) => {
    const donutSmallLabel = (bigText: string, smallText: string, extraClassName: string) => (
        <div className={ classes(donutsSmallLabelClassName, extraClassName) }>
            <span>{ bigText }</span>
            <span>{ smallText }</span>
        </div>
    );

    return (
        <Grid>
            <Cell size={ 12 } sizeTablet={ 6 }>
                <div className={ donutsWrapperClassName }>
                    <Donut
                        maxValue={ 100 }
                        values={ [{amount: countryProfile.quotaTarget}] }
                        colors={ [COLOR_10] }
                        children={ donutSmallLabel(
                            `${Math.round(countryProfile.quotaTarget)}%`,
                            'Quota target',
                            donutsSmallLabel01ClassName
                        ) }/>
                </div>
            </Cell>
            <Cell size={ 12 } sizeTablet={ 6 }>
                <div className={ donutsWrapperClassName }>
                    <Donut
                        maxValue={ 100 }
                        values={ [{amount: countryProfile.womensRepresentation || 0}] }
                        colors={ [COLOR_29] }
                        children={ donutSmallLabel(
                            (
                                countryProfile.womensRepresentation === null
                                ? NOT_APPLICABLE_TEXT
                                : `${Math.round(countryProfile.womensRepresentation)}%`
                            ),
                            'Women\'s representation',
                            donutsSmallLabel02ClassName
                        ) }/>
                </div>
            </Cell>
        </Grid>
    );
};

export default CountryProfileDonuts;
