import React, { useState } from 'react';
import { classes } from 'typestyle';
import Checkbox from '../checkbox/checkbox.component';
import SearchField from '../form/search-field.component';
import {
    taxonomyListCheckboxContainerNameClassName,
    taxonomyListClassName,
    taxonomyListGroupedBodyClassName,
    taxonomyListGroupedBodyLeftClassName,
    taxonomyListGroupedBodyRightClassName,
    taxonomyListGroupedCoreClassName,
    taxonomyListGroupedGroupClassName,
    taxonomyListGroupedLetterButtonClassName,
    taxonomyListGroupedLetterClassName,
    taxonomyListGroupedLettersClassName,
    taxonomyListGroupedLettersMovingClassName,
    taxonomyListListClassName,
    taxonomyListListItemClassName,
    taxonomyListNameClassName,
    taxonomyListNameClickableClassName,
    taxonomyListSectionClassName,
    taxonomyListGroupedClassName,
    taxonomyListNameMaxSelectedDisabledClassName
} from './taxonomy-list.style';
import { TaxonomyListGroupedProps } from './taxonomy-list.types';
import { filterTaxonomies, groupTaxonomies } from './taxonomy-list.utils';

const TaxonomyListGrouped: React.FC<TaxonomyListGroupedProps> = ({
    list,
    selectedIds,
    onChange,
    searchField,
    children,
    onClickItem,
    maxSelections
}) => {
    const [textFilter, changeTextFilter] = useState<string>('');
    const taxonomyList = textFilter.length > 1 ? filterTaxonomies(list, textFilter.toLowerCase()) : list;
    const [groupOfTaxonomies, letters] = groupTaxonomies(taxonomyList);

    const elementRef = React.createRef<HTMLDivElement>();
    const scrollElementRef = React.createRef<HTMLDivElement>();
    const verticalLettersWrapperRef = React.createRef<HTMLDivElement>();
    const verticalLettersMovingRef = React.createRef<HTMLDivElement>();

    const maxSelected: boolean = (selectedIds && onChange && maxSelections)
        ? selectedIds.length >= maxSelections
        : false

    const handleChangeTextFilter = (newValue: string) => {
        changeTextFilter(newValue);

        if (scrollElementRef.current) {
            scrollElementRef.current.scrollTop = 0;
        }
    };

    const handleClickLetterButton = (letter: string) => {
        if (elementRef.current && scrollElementRef.current) {
            const targetElement = elementRef.current.querySelector<HTMLDivElement>(`[data-letter="${letter}"]`);
            
            if (targetElement) {
                scrollElementRef.current.scrollTop = targetElement.offsetTop;
            }
        }
    };

    const handleScrollCore = () => {
        if (
            scrollElementRef.current
            && verticalLettersWrapperRef.current
            && verticalLettersMovingRef.current
        ) {
            const lettersWrapperHeight = verticalLettersWrapperRef.current.offsetHeight;
            const lettersMovingHeight = verticalLettersMovingRef.current.offsetHeight;

            if (lettersMovingHeight > lettersWrapperHeight) {
                const maxNegativeScrollAdjustment = lettersWrapperHeight - lettersMovingHeight;
                const currentNegativeContentScroll = Math.max(-scrollElementRef.current.scrollTop, maxNegativeScrollAdjustment);

                verticalLettersMovingRef.current.style.top = `${currentNegativeContentScroll}px`;
                
            }
        }
    };

    const handleChangeCheckbox = (
        isSelected: boolean,
        selectedIds: number[],
        taxonomyDataId: number
    ) => {
        if (onChange) {
            if (isSelected) {
                if (maxSelected) {
                    return;
                }

                onChange(
                    [...selectedIds, taxonomyDataId]
                );
            }
            else {
                onChange(
                    selectedIds.filter(id => id !== taxonomyDataId)
                );
            }
        }
    };

    return (
        <div className={ classes(taxonomyListClassName, taxonomyListGroupedClassName) } ref={ elementRef }>
            { searchField && (
                <div className={ taxonomyListSectionClassName }>
                    <SearchField
                        value={ textFilter }
                        placeholder={ typeof searchField === 'string' ? searchField : '' }
                        onChange={ handleChangeTextFilter }/>
                </div>
            ) }
            { children && (
                <div className={ taxonomyListSectionClassName }>
                    { children }
                </div>
            ) }
            <div className={ taxonomyListGroupedBodyClassName }>
                <div className={ taxonomyListGroupedBodyLeftClassName }>
                    <div className={ taxonomyListGroupedLettersClassName } ref={ verticalLettersWrapperRef }>
                        <div className={ taxonomyListGroupedLettersMovingClassName } ref={ verticalLettersMovingRef }>
                            { letters.map((letter, index) => (
                                <div
                                    key={ index }
                                    className={ taxonomyListGroupedLetterButtonClassName }
                                    onClick={ () => handleClickLetterButton(letter) }
                                    children={ letter }/>
                            )) }
                        </div>
                    </div>
                </div>
                <div className={ taxonomyListGroupedBodyRightClassName }>
                    <div data-list className={ taxonomyListGroupedCoreClassName } ref={ scrollElementRef } onScroll={ handleScrollCore }>
                        { groupOfTaxonomies.map((taxonomyGroup, i) => (
                            <div key={ i } className={ taxonomyListGroupedGroupClassName }>
                                <div className={ taxonomyListGroupedLetterClassName } data-letter={ taxonomyGroup.letter  }>
                                    { taxonomyGroup.letter }
                                </div>
                                <ul className={ taxonomyListListClassName }>
                                    { taxonomyGroup.taxonomies.map((taxonomyData, index) => {
                                        const isSelected = Boolean(selectedIds && selectedIds.indexOf(taxonomyData.id) !== -1);

                                        return (
                                            <li key={ index } className={ taxonomyListListItemClassName }>
                                                { selectedIds && (
                                                    <div className={ taxonomyListCheckboxContainerNameClassName }>
                                                        <Checkbox
                                                            value={ isSelected }
                                                            disabled={ maxSelected && !isSelected }
                                                            onChange={ newIsSelected => handleChangeCheckbox(
                                                                newIsSelected, selectedIds, taxonomyData.id
                                                            ) }/>
                                                    </div>
                                                ) }
                                                <div className={ classes(
                                                    taxonomyListNameClassName,
                                                    maxSelected && !isSelected && taxonomyListNameMaxSelectedDisabledClassName
                                                ) }>
                                                    <span className={ taxonomyListNameClickableClassName } onClick={ event => {
                                                        event.preventDefault();

                                                        if (selectedIds) {
                                                            handleChangeCheckbox(
                                                                !isSelected,
                                                                selectedIds,
                                                                taxonomyData.id
                                                            );
                                                        }

                                                        onClickItem && onClickItem(taxonomyData);
                                                    } }>
                                                        { taxonomyData.name }
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    }) }
                                </ul>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaxonomyListGrouped;
