import React, { useState } from 'react';
import { AppContext } from './context';
import { initialState } from './initial-state';
import { AppProviderProps } from './types';

const AppProvider: React.FC<AppProviderProps> = ({
    children
}) => {
    const [state, changeState] = useState(initialState);

    return (
            <AppContext.Provider value={ {
                state,
                changeState
            } }>
                { children }
            </AppContext.Provider>
    );
};

export default AppProvider;
