import React from 'react';
import {
    QUOTA_TYPE_CANDIDATE_QUOTA_ID,
    QUOTA_TYPE_RESERVED_SEATS_ID
} from '../../../constants';
import { CountryProfile } from '../../../state/types';
import { quotaTypeNotesClassName } from './quota-type-notes.style';

interface QuotaTypeNotesProps {
    profiles: CountryProfile[],
    countryCount: number
}

const QuotaTypeNotes: React.FC<QuotaTypeNotesProps> = ({
    profiles,
    countryCount
}) => {
    let bothQuotaTypeCount: number = 0;

    profiles.forEach(profile => {
        if (
            (profile.typeOfQuotaIds.indexOf(QUOTA_TYPE_CANDIDATE_QUOTA_ID) !== -1)
            &&
            (profile.typeOfQuotaIds.indexOf(QUOTA_TYPE_RESERVED_SEATS_ID) !== -1)
        ) {
            bothQuotaTypeCount++;
        }
    });

    return (
        <div className={ quotaTypeNotesClassName }>
            Based on { countryCount } countries<br />
            { bothQuotaTypeCount !== 0 && (
                <>
                    *{ bothQuotaTypeCount } { bothQuotaTypeCount === 1 ? 'country' : 'countries' } have both reserved seats and candidate quotas
                </>
            ) }
            
        </div>
    );
};

export default QuotaTypeNotes;
