import { NestedCSSProperties } from 'typestyle/lib/types';
import { COLOR_12, FONT_MAIN, FONT_WEIGHT_REGULAR } from './constants';

export const mixinFont = (
    size: number | string,
    fontWeight: number = FONT_WEIGHT_REGULAR,
    lineHeight?: number | string,
    color: string | false  = COLOR_12
): NestedCSSProperties => {
    return {
        fontFamily: FONT_MAIN,
        fontSize: (typeof size === 'number') ? `${size}px` : size,
        lineHeight: (typeof lineHeight === 'number') ? (lineHeight ? (lineHeight + 'px') : undefined) : lineHeight,
        fontWeight,
        color: color === false ? undefined : color
    };
};

export const mixinAbsoluteFill = (): NestedCSSProperties => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%'
});

export const mixinSize = (widthAndHeight: number | string): NestedCSSProperties => {
    const value: string = typeof widthAndHeight === 'number' ? `${widthAndHeight}px` : widthAndHeight;

    return {
        width: value,
        height: value
    }
};
