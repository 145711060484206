import { style } from 'typestyle';
import {COLOR_05, FONT_WEIGHT_REGULAR, MARGINS_PX} from '../../../constants';
import { tabletStyles } from '../../../utils';

export const countryComparisonTitleAndFiltersClassName = style({
    display: 'block'
}, tabletStyles({
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap'
}));

export const countryComparisonTitleAndFiltersTopClassName = style({
    display: 'block'
});

export const countryComparisonTitleAndFiltersTopDescriptionClassName = style({
    marginTop: `${MARGINS_PX}px`,
    marginBottom: `${MARGINS_PX*1.5}px`,
    color: COLOR_05,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: '14px',
    lineHeight: '21px'
});




export const countryComparisonTitleAndFiltersBottomClassName = style({
    display: 'block',
    padding: `${MARGINS_PX}px 0 0 0`
}, tabletStyles({
    padding: `0 ${MARGINS_PX}px 0 0`,
    minWidth: '300px'
}));
