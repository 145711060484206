import { style } from 'typestyle';
import {
    COLOR_05,
    COLOR_12,
    COLOR_BLACK,
    FONT_MAIN,
    FONT_WEIGHT_BLACK,
    FONT_WEIGHT_LIGHT,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';
import { mixinFont } from '../../../style.mixin';
import { tabletStyles } from '../../../utils';
import { buttonDefaultStyles, buttonStyles } from '../button/button.style';

export const sectionInfoClassName = style({
    display: 'block'
}, tabletStyles({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
}));

export const sectionInfoImageClassName = style({
    display: 'block',
    padding: `${MARGINS_PX}px 0`,

    $nest: {
        '> img': {
            display: 'block',
            width: '100%',
            height: 'auto'
        },
        '> span': {
            display: 'block',
            fontFamily: FONT_MAIN,
            fontWeight: FONT_WEIGHT_LIGHT,
            fontSize: '10px',
            lineHeight: '12px',
            color: COLOR_BLACK,
            margin: `${MARGINS_PX / 2}px 0 0 0`
        }
    }
}, tabletStyles({
    width: '40%',
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: MARGINS_PX + 'px'
}));

export const sectionInfoTextClassName = style({
    display: 'block',
    padding: `${MARGINS_PX}px 0`,

    $nest: {
        'h2': {
            margin: `0 0 ${MARGINS_PX}px 0`,
            ...mixinFont(30, FONT_WEIGHT_BLACK, 38, COLOR_05)
        },
        'p': {
            fontFamily: FONT_MAIN,
            fontSize: '14px',
            lineHeight: '18.2px',
            color: COLOR_12,
            fontWeight: FONT_WEIGHT_LIGHT,
            margin: `${(MARGINS_PX / 3) * 2}px 0`,

            $nest: {
                '> strong': {
                    fontWeight: FONT_WEIGHT_MEDIUM,
                    color: COLOR_05
                },
                'a': {
                    color: '#00466D',
                    textDecoration: 'underline',

                    $nest: {
                        '&:hover': {
                            color: 'inherit'
                        },
                        '&:focus': {
                            color: 'inherit'
                        },
                        '&:visited': {
                            color: 'inherit'
                        }
                    }
                }
            }
        },
        'button': {
            ...buttonStyles,
            ...buttonDefaultStyles
        }
    }
});
