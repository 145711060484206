import { style } from 'typestyle';
import { COLOR_06, COLOR_26, COLOR_WHITE } from '../../../constants';
import { mixinAbsoluteFill, mixinSize } from '../../../style.mixin';

export const donutClassName = style({
    display: 'block',
    position: 'relative',
    width: '100%',
    height: 0,
    padding: '0 0 100% 0'
});

export const donutInnerClassName = style({
    display: 'block',
    ...mixinAbsoluteFill()
});

export const donutCoreClassName = style({
    display: 'block',
    width: '100%',
    height: '100%',
    background: COLOR_06,
    borderRadius: '50%'
});

export const donutOverlayClassName = style({
    ...mixinAbsoluteFill(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none'
});

export const donutOverlayCenterClassName = style({
    ...mixinSize('75%'),
    background: COLOR_WHITE,
    borderRadius: '50%',
    position: 'relative'
});

export const donutOverlayContentClassName = style({
    ...mixinSize('100%'),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center'
});
