import React, { createRef, useEffect } from 'react';
import { COLOR_25, COLOR_26 } from '../../../constants';
import { useAppState } from '../../../state/hook';
import { CountryProfile } from '../../../state/types';
import { getTaxonomyName } from '../../../utils';
import Donut from '../donut/donut.component';
import { Cell, Grid } from '../grid/grid.component';
import {
    globalQuotaTypeChartClassName,
    globalQuotaTypeChartLabelNumberClassName,
    globalQuotaTypeChartLabelTextClassName,
    globalQuotaTypeChartCaptionClassName,
    globalQuotaTypeChartCaptionNumberClassName,
    globalQuotaTypeChartCaptionCircleClassName,
    globalQuotaTypeChartCaptionTextClassName
} from './quota-type-chart.style';

interface QuotaTypeChartProps {
    profiles: CountryProfile[],
    countryCount?: number,
    customBackground?: [bgDonutColor: string, bgCenterColor: string]
}

const QuotaTypeChart: React.FC<QuotaTypeChartProps> = ({
    profiles,
    countryCount,
    customBackground
}) => {
    const [{taxonomies}] = useAppState();
    const elementRef = createRef<HTMLDivElement>();
    const countryProfilesWithReservedSeats: CountryProfile[] = [];
    const countryProfilesWithCandidateQuota: CountryProfile[] = [];

    profiles.map(countryProfile => {
        countryProfile.typeOfQuotaIds.forEach(typeOfQuotaId => {
            switch (typeOfQuotaId) {
                case 14: return countryProfilesWithCandidateQuota.push(countryProfile);
                case 15: return countryProfilesWithReservedSeats.push(countryProfile);
            }
        });
    });

    const countryProfilesWithReservedSeatsCountryNames: string[] = countryProfilesWithReservedSeats
        .map(profile => getTaxonomyName(taxonomies, 'countries', profile.countryId));

    const countryProfilesWithCandidateQuotaCountryNames: string[] = countryProfilesWithCandidateQuota
        .map(profile => getTaxonomyName(taxonomies, 'countries', profile.countryId));

    countryProfilesWithReservedSeatsCountryNames.sort((a, b) => a > b ? 1 : -1);
    countryProfilesWithCandidateQuotaCountryNames.sort((a, b) => a > b ? 1 : -1);

    useEffect(() => {
        const fitFontSize = () => {
            setTimeout(() => {
                const element = elementRef.current;

                if (element) {
                    element.style.fontSize = `${element.offsetWidth / 2.7}px`;
                }
            }, 50);
        };

        fitFontSize();

        window.addEventListener('resize', fitFontSize);

        return () => {
            window.removeEventListener('resize', fitFontSize)
        };
    }, []);

    const caption = (
        amount: number,
        text: string,
        color: string
    ) => (
        <div className={ globalQuotaTypeChartCaptionClassName }>
            <div className={ globalQuotaTypeChartCaptionNumberClassName } style={ {color: color} }>
                <span className={ globalQuotaTypeChartCaptionCircleClassName } style={ {backgroundColor: color} }/>
                { amount }
            </div>
            <div className={ globalQuotaTypeChartCaptionTextClassName }>
                { text }
            </div>
        </div>
    );

    return (
        <div ref={ elementRef } className={ globalQuotaTypeChartClassName }>
            <Donut
                maxValue={ countryCount }
                values={ [{
                    amount: countryProfilesWithReservedSeats.length,
                    tooltipData: countryProfilesWithReservedSeatsCountryNames.join(', ')
                }, {
                    amount: countryProfilesWithCandidateQuota.length,
                    tooltipData: countryProfilesWithCandidateQuotaCountryNames.join(', ')
                }] }
                colors={ [COLOR_25, COLOR_26, ...(customBackground ? [customBackground[0]] : [])] }
                centerColor={ customBackground && customBackground[1] }
                tooltipId='quotaTypeChart'
            >
                <div className={ globalQuotaTypeChartLabelNumberClassName }>
                    { profiles.length }
                </div>
                <div className={ globalQuotaTypeChartLabelTextClassName }>
                    Countries with<br/>gender quotas*
                </div>
            </Donut>
            <Grid>
                <Cell size={ 6 }>
                    { caption(countryProfilesWithReservedSeats.length, 'Countries with reserved seats', COLOR_25) }
                </Cell>
                <Cell size={ 6 }>
                    { caption(countryProfilesWithCandidateQuota.length, 'Countries with candidate quotas', COLOR_26) }
                </Cell>
            </Grid>
        </div>
    )
};

export default QuotaTypeChart;
