import React from 'react';
import { classes } from 'typestyle';
import { logoUNWomen } from '../../../assets';
import Content from '../content/content.component';
import {
    bottomContentClassName,
    pageFooterCenterClassName,
    pageFooterClassName,
    pageFooterImgClassName,
    pageFooterLinksClassName,
    pageFooterLinkItemClassName,
    pageFooterLinkClassName,
    pageFooterSidesClassName,
    pageFooterTextClassName,
    pageFooterCopyrightClassName,
    pageFooterTitleClassName
} from './site-footer.style';
import { ROUTE_PAGE_PRIVACY_NOTICE, ROUTE_PAGE_TERMS_OF_USE } from '../../../constants';
import { NavLink } from 'react-router-dom';

interface SiteFooterLinkProps {
    name: string,
    link: string
}

const SiteFooter: React.FC = () => (
    <div className={ bottomContentClassName }>
        <Content className={ pageFooterClassName }>
            <div className={ pageFooterSidesClassName }>
                <img
                    className={ pageFooterImgClassName }
                    src={ logoUNWomen }
                    alt='UN Women'/>
                <span className={ classes(pageFooterTextClassName, pageFooterTitleClassName) }>
                    Hosted by UN Women
                </span>
            </div>
            <div className={ pageFooterCenterClassName }>
                <ul className={ pageFooterLinksClassName }>
                    <SiteFooterNavLink name='Terms of Use' link={ ROUTE_PAGE_TERMS_OF_USE }/>
                    <SiteFooterNavLink name='Privacy Notice' link={ ROUTE_PAGE_PRIVACY_NOTICE }/>
                </ul>
            </div>
            <div className={ pageFooterSidesClassName }>
                <span className={ classes(pageFooterTextClassName, pageFooterCopyrightClassName) }>
                    <a href={"https://www.unwomen.org/en/copyright"} target="_blank">Copyright</a> © <a href={"https://www.unwomen.org/en"} target="_blank">UN Women</a> 2023
                </span>
            </div>
        </Content>
    </div>
);

const SiteFooterNavLink: React.FC<SiteFooterLinkProps> = ({
    name,
    link
}) => (
    <li className={ classes(pageFooterTextClassName, pageFooterLinkItemClassName) }>
        <NavLink className={ classes(pageFooterTextClassName, pageFooterLinkClassName) } to={ link }>
            { name }
        </NavLink>
    </li>
);

export default SiteFooter;
