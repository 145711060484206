import React from 'react';
import { LEGAL_SOURCE_TRUE_ID } from '../../../constants';
import { CountryProfile } from '../../../state/types';
import CollapsibleContent from '../collapsible-content/collapsible-content.component';

interface LegalProvisionsProps {
    countryProfile: CountryProfile,
    showAll?: boolean,
    initExpanded?: boolean
}

const LegalProvisions: React.FC<LegalProvisionsProps> = ({
    countryProfile,
    showAll = false,
    initExpanded = false
}) => {
    const getItem = (
        name: string,
        content: React.ReactNode
    ) => (showAll ? true : content) && (
        <CollapsibleContent line={ showAll } title={ name } initExpanded={ initExpanded }>
            { content }
        </CollapsibleContent>
    );

    return <>
        { getItem('Constitution', (countryProfile.constitutionProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) && (
            <>
                <p style={ {fontWeight: 'bold'} } dangerouslySetInnerHTML={ {__html: countryProfile.constitutionLegalSource} }/>
                <p dangerouslySetInnerHTML={ {__html: countryProfile.constitutionLegalProvisions} }/>
            </>
        )) }
        { getItem('Electoral law', (countryProfile.electoralProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) && (
            <>
                <p style={ {fontWeight: 'bold'} } dangerouslySetInnerHTML={ {__html: countryProfile.electoralLegalSource} }/>
                <p dangerouslySetInnerHTML={ {__html: countryProfile.electoralLegalProvisions} }/>
            </>
        )) }
        { getItem('Party law', (countryProfile.partyProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) && (
            <>
                <p style={ {fontWeight: 'bold'} } dangerouslySetInnerHTML={ {__html: countryProfile.partyLegalSource} }/>
                <p dangerouslySetInnerHTML={ {__html: countryProfile.partyLegalProvisions} }/>
            </>
        )) }
        { getItem('Parity law', (countryProfile.parityProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) && (
            <>
                <p style={ {fontWeight: 'bold'} } dangerouslySetInnerHTML={ {__html: countryProfile.parityLegalSource} }/>
                <p dangerouslySetInnerHTML={ {__html: countryProfile.parityLegalProvisions} }/>
            </>
        )) }
        { getItem('Financing law', (countryProfile.financingProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) && (
            <>
                <p style={ {fontWeight: 'bold'} } dangerouslySetInnerHTML={ {__html: countryProfile.financingLegalSource} }/>
                <p dangerouslySetInnerHTML={ {__html: countryProfile.financingLegalProvisions} }/>
            </>
        )) }
        { getItem('Political agreement', (countryProfile.politicalProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) && (
            <>
                <p style={ {fontWeight: 'bold'} } dangerouslySetInnerHTML={ {__html: countryProfile.politicalLegalSource} }/>
                <p dangerouslySetInnerHTML={ {__html: countryProfile.politicalLegalProvisions} }/>
            </>
        )) }
    </>
};

export default LegalProvisions;
