import React from 'react';
import { classes } from 'typestyle';
import { getLargeDate } from '../../../utils';
import Button from '../button/button.component';
import Select from '../form/select.component';
import { Cell, Grid } from '../grid/grid.component';
import Icon from '../icon/icon.component';
import {
    resutsButtonpadSelectClassName,
    resutsButtonpadButtonClassName,
    resutsButtonpadIconClassName,
    resutsButtonpadIconDropdownClassName
} from './results-buttonpad.style';
import { buttonClassName, buttonLightClassName } from '../button/button.style';
import { downloadPDFHandler } from '../../../services/pdf.service';
import { linkCSVTable } from '../../../services/csv.service';
import { useAppState } from '../../../state/hook';
import { Option } from '../../../state/types';
import { LABEL_FOR_CURRENT_DATE } from '../../../constants';

interface ResultsButtonpadProps {
    downloadData?: {
        fileName: string,
        fileTitle: string,
        headers: string[],
        content: ((string | number)[])[]
    },
    pdf?: boolean | {
        columnSizePercentages?: number[]
    },
    csv?: boolean,
    date?: string | null,
    onChangeDate?: (newDate: string | null) => void
}

const ResultsButtonpad: React.FC<ResultsButtonpadProps> = ({
    downloadData,
    pdf, csv,
    date, onChangeDate
}) => {
    const [{taxonomies: {dates}}] = useAppState();
    const dateValue = (!date || (date === null)) ? LABEL_FOR_CURRENT_DATE : date;
    const dateLabel = (!date || (date === null)) ? LABEL_FOR_CURRENT_DATE : getLargeDate(date);

    const handleChangeDate = (selectedValue: string) => {
        onChangeDate && onChangeDate(
            selectedValue === LABEL_FOR_CURRENT_DATE
            ? null
            : selectedValue
        );
    };

    const csvLinkData = (csv && downloadData) ? linkCSVTable({
        title: downloadData.fileTitle,
        headers: downloadData.headers,
        content: downloadData.content,
        dataDate: dateLabel
    }) : null;

    const pdfDownloadHandler = (pdf && downloadData) ? downloadPDFHandler(
        downloadData.fileTitle,
        downloadData.fileName,
        downloadData.headers,
        downloadData.content,
        dateLabel,
        (typeof pdf !== 'boolean') ? pdf.columnSizePercentages : undefined
    ) : null;

    const getDateOptions = (): Option[] => [
        {
            value: LABEL_FOR_CURRENT_DATE,
            label: LABEL_FOR_CURRENT_DATE
        },
        ...dates.map(taxonomyDate => ({
            value: taxonomyDate.name,
            label: getLargeDate(taxonomyDate.name)
        }))
    ];

    return (
        <Grid>
            <Cell size={ 'fill' }>
                { dateValue && dateLabel && (
                    <Select
                        options={ getDateOptions() }
                        value={ dateValue }
                        onChange={ handleChangeDate }
                    >
                        <div className={ resutsButtonpadSelectClassName }>
                            { dateLabel }
                            <Icon
                                name='dropdown'
                                className={ classes(
                                    resutsButtonpadIconClassName,
                                    resutsButtonpadIconDropdownClassName
                                ) }/>
                        </div>
                    </Select>
                ) }
            </Cell>
            { pdfDownloadHandler && (
                <Cell size={ 'stack' }>
                    <Button format='light' onClick={ pdfDownloadHandler } className={ resutsButtonpadButtonClassName }>
                        Download .pdf <Icon name='download' className={ resutsButtonpadIconClassName }/>
                    </Button>
                </Cell>
            ) }
            { (csvLinkData && downloadData) && (
                <Cell size={ 'stack' }>
                    <a href={ csvLinkData } target='_blank' download={ downloadData.fileName + '.csv' } className={ classes(buttonClassName, buttonLightClassName, resutsButtonpadButtonClassName) }>
                        Download .csv <Icon name='download' className={ resutsButtonpadIconClassName }/>
                    </a>
                </Cell>
            ) }
        </Grid>
    );
};

export default ResultsButtonpad;
