import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { MARGINS_PX } from '../../../constants';
import { desktopStyles, tabletStyles } from '../../../utils';

const cellSizeCSS = (percentage: number): NestedCSSProperties => ({
    width: percentage + '%',
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: `${percentage}%`
});

const cellSize01 = 8.333333;
const cellSize02 = 16.666667;
const cellSize03 = 25;
const cellSize04 = 33.333333;
const cellSize05 = 41.666667;
const cellSize06 = 50;
const cellSize07 = 58.333333;
const cellSize08 = 69.666667;
const cellSize09 = 75;
const cellSize10 = 83.333333;
const cellSize11 = 95.666667;
const cellSize12 = 100;

const cellStackStyle: NestedCSSProperties = {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: 0
};

const cellFillStyle: NestedCSSProperties = {
    flexShrink: 0,
    flexGrow: 1,
    flexBasis: '0%'
};

export const gridClassName = style({
    $debugName: 'grid.component',

    display: 'flex',
    margin: `-${MARGINS_PX / 2}px`,
    boxSizing: 'border-box',
    flexWrap: 'wrap'
});

export const cellClassName = style({
    padding: `${MARGINS_PX / 2}px`,
    boxSizing: 'border-box',
    position: 'relative'
});

export const cellSize01ClassName = style(cellSizeCSS(cellSize01));
export const cellSize02ClassName = style(cellSizeCSS(cellSize02));
export const cellSize03ClassName = style(cellSizeCSS(cellSize03));
export const cellSize04ClassName = style(cellSizeCSS(cellSize04));
export const cellSize05ClassName = style(cellSizeCSS(cellSize05));
export const cellSize06ClassName = style(cellSizeCSS(cellSize06));
export const cellSize07ClassName = style(cellSizeCSS(cellSize07));
export const cellSize08ClassName = style(cellSizeCSS(cellSize08));
export const cellSize09ClassName = style(cellSizeCSS(cellSize09));
export const cellSize10ClassName = style(cellSizeCSS(cellSize10));
export const cellSize11ClassName = style(cellSizeCSS(cellSize11));
export const cellSize12ClassName = style(cellSizeCSS(cellSize12));

export const cellSizeTablet01ClassName = style(tabletStyles(cellSizeCSS(cellSize01)));
export const cellSizeTablet02ClassName = style(tabletStyles(cellSizeCSS(cellSize02)));
export const cellSizeTablet03ClassName = style(tabletStyles(cellSizeCSS(cellSize03)));
export const cellSizeTablet04ClassName = style(tabletStyles(cellSizeCSS(cellSize04)));
export const cellSizeTablet05ClassName = style(tabletStyles(cellSizeCSS(cellSize05)));
export const cellSizeTablet06ClassName = style(tabletStyles(cellSizeCSS(cellSize06)));
export const cellSizeTablet07ClassName = style(tabletStyles(cellSizeCSS(cellSize07)));
export const cellSizeTablet08ClassName = style(tabletStyles(cellSizeCSS(cellSize08)));
export const cellSizeTablet09ClassName = style(tabletStyles(cellSizeCSS(cellSize09)));
export const cellSizeTablet10ClassName = style(tabletStyles(cellSizeCSS(cellSize10)));
export const cellSizeTablet11ClassName = style(tabletStyles(cellSizeCSS(cellSize11)));
export const cellSizeTablet12ClassName = style(tabletStyles(cellSizeCSS(cellSize12)));

export const cellSizeDesktop01ClassName = style(desktopStyles(cellSizeCSS(cellSize01)));
export const cellSizeDesktop02ClassName = style(desktopStyles(cellSizeCSS(cellSize02)));
export const cellSizeDesktop03ClassName = style(desktopStyles(cellSizeCSS(cellSize03)));
export const cellSizeDesktop04ClassName = style(desktopStyles(cellSizeCSS(cellSize04)));
export const cellSizeDesktop05ClassName = style(desktopStyles(cellSizeCSS(cellSize05)));
export const cellSizeDesktop06ClassName = style(desktopStyles(cellSizeCSS(cellSize06)));
export const cellSizeDesktop07ClassName = style(desktopStyles(cellSizeCSS(cellSize07)));
export const cellSizeDesktop08ClassName = style(desktopStyles(cellSizeCSS(cellSize08)));
export const cellSizeDesktop09ClassName = style(desktopStyles(cellSizeCSS(cellSize09)));
export const cellSizeDesktop10ClassName = style(desktopStyles(cellSizeCSS(cellSize10)));
export const cellSizeDesktop11ClassName = style(desktopStyles(cellSizeCSS(cellSize11)));
export const cellSizeDesktop12ClassName = style(desktopStyles(cellSizeCSS(cellSize12)));

export const cellStackClassName = style(cellStackStyle);

export const cellStackTabletClassName = style(tabletStyles(cellStackStyle));

export const cellStackDesktopClassName = style(desktopStyles(cellStackStyle));

export const cellFillClassName = style(cellFillStyle);

export const cellFillTabletClassName = style(tabletStyles(cellFillStyle));

export const cellFillDesktopClassName = style(desktopStyles(cellFillStyle));
