import React from 'react';
import { getLargeDate } from '../../../utils';
import Comments from '../comments/comments.component';

interface InfoDateProps {
    dates: {
        date: string,
        ipuDate: string
    }
}

const InfoDate: React.FC<InfoDateProps> = ({
    dates
}) => (
    <Comments>
        { dates.date && (
            <p><strong>Last updated: { getLargeDate(dates.date) }.</strong></p>
        ) }
        { dates.ipuDate && (
            <p><strong>Data source for women's representation: Inter-Parliamentary Union. As of { getLargeDate(dates.ipuDate) }.</strong></p>
        ) }
    </Comments>
);

export default InfoDate;
