export const counter = (
    finalValue: number, 
    seconds: number, 
    callback: (value: number, finish: boolean) => void
) => {
    const startTime = (new Date).getTime();
    const milliseconds = seconds*1000;
  
    (function update() {
        var currentTime = (new Date).getTime();
        var value = finalValue * (currentTime - startTime) / milliseconds;

        if (value >= finalValue) {
            value = finalValue;
        }
        else {
            setTimeout(update, 0);
        }

        callback(value, value === finalValue);

        return () => {
            value = finalValue;
        };
    })();
  
  }