import React from 'react';
import CountryResults from '../../partials/quota-analysis-results/quota-analysis-results.component';
import Filters from '../../partials/quota-analysis-filters/quota-analysis-filters.component.component';
import GlobalStatistics from '../../partials/quota-analysis-global-statistics/quota-analysis-global-statistics.component';
import PageHeader from '../../common/page-header/page-header.component';
import Page from '../../common/page/page.component';
import { useAppFilters, useAppState } from '../../../state/hook';

const QuotaAnalysisPage: React.FC = () => {
    const [, {
        getCountryProfileResults,
        getPage
    }] = useAppState();
    const [filters, changeFilters] = useAppFilters();
    const countryProfileResults = getCountryProfileResults(filters);
    const page = getPage('Quota Analysis');
    
    return (
        <Page>
            { page && (
                <PageHeader
                    title={ page.name }
                    description={ page.html }/>
            ) }

            <GlobalStatistics/>
            <Filters
                filters={ filters }
                onChangeFilters={ changeFilters }/>
            <CountryResults
                results={ countryProfileResults }
                filters={ filters }
                onChangeFilters={ changeFilters }/>
        </Page>
    );
}

export default QuotaAnalysisPage;
