import { style } from 'typestyle';
import {
    COLOR_12,
    COLOR_WHITE,
    FONT_MAIN,
    FONT_WEIGHT_LIGHT,
    MARGINS_PX
} from '../../../constants';

export const taxonomyListClassName = style({
    display: 'block',
    height: '100%',
    overflow: 'visible'
});

export const taxonomyListListClassName = style({
    display: 'block',
    listStyle: 'none',
    margin: '-5px 0',
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '100%'
});

export const taxonomyListListItemClassName = style({
    display: 'block',
    padding: 0,
    margin: '5px 0',
    lineHeight: '18px'
});

export const taxonomyListCheckboxContainerNameClassName = style({
    display: 'block',
    float: 'left',
    margin: '1px 8px 0 0'
});

export const taxonomyListNameClassName = style({
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_LIGHT,
    fontSize: '16px',
    color: COLOR_12,
    overflow: 'hidden'
});

export const taxonomyListNameClickableClassName = style({
    cursor: 'pointer'
});

export const taxonomyListNameMaxSelectedDisabledClassName = style({
    opacity: .3,
    cursor: 'not-allowed',

    $nest: {
        [`.${taxonomyListNameClickableClassName}`]: {
            cursor: 'not-allowed'
        }
    }
});

// Grouped component

export const taxonomyListGroupedClassName = style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
});

export const taxonomyListSectionClassName = style({
    margin: `0 0 ${MARGINS_PX}px 0`
});

export const taxonomyListGroupedBodyClassName = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative',
    overflow: 'hidden'
});

export const taxonomyListGroupedBodyLeftClassName = style({
    $debugName: 'countryListBodyLeftClassName',

    display: 'block',
    flexShrink: 0,
    flexGrow: 0,
    paddingRight: MARGINS_PX + 'px'
});

export const taxonomyListGroupedBodyRightClassName = style({
    $debugName: 'countryListBodyRightClassName',

    display: 'block',
    width: '100%',
    flexShrink: 1,
    flexGrow: 0
});

export const taxonomyListGroupedLettersClassName = style({
    position: 'relative',
    width: '12px',
    height: '100%',
    overflow: 'hidden'
});

export const taxonomyListGroupedLettersMovingClassName = style({
    display: 'block',
    position: 'absolute',
    width: '100%',
    overflow: 'visible'
});

export const taxonomyListGroupedLetterButtonClassName = style({
    fontWeight: 300,
    fontSize: '13px',
    color: '#333333',
    lineHeight: '20px',
    textAlign: 'center',
    cursor: 'pointer',

    $nest: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

export const taxonomyListGroupedCoreClassName = style({
    display: 'block',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%'
});

export const taxonomyListGroupedGroupClassName = style({
    $nest: {
        '&:first-child': {
            margin: '-8px 0 0 0'
        }
    }
});

export const taxonomyListGroupedLetterClassName = style({
    display: 'block',
    margin: '10px 0',
    fontWeight: '700',
    fontSize: '14px'
});
