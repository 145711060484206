import { style } from 'typestyle';
import { COLOR_WHITE, MARGINS_PX } from '../../../constants';

export const fieldWrapperClassName = style({
    display: 'block',
    position: 'relative'
});

export const inputClassName = style({
    display: 'block',
    height: '35px',
    borderRadius: '34px',
    backgroundColor: COLOR_WHITE,
    border: 'none',
    boxShadow: '0 0 10px 3px rgb(0 0 0 / 22%)',
    width: '100%',
    margin: 0,
    padding: `0 ${MARGINS_PX}px`,
    boxSizing: 'border-box'
});

export const inputIconClassName = style({
    display: 'flex',
    position: 'absolute',
    boxSizing: 'content-box',
    height: '35px',
    top: 0,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'middle',
    justifyContent: 'center',
    pointerEvents: 'none',

    $nest: {
        '> img': {
            display: 'block',
            height: '15px'
        }
    }
});

export const inputIconLeftClassName = style({
    padding: `0 0 0 ${MARGINS_PX}px`,
    left: 0,

    $nest: {
        [`+ .${inputClassName}`]: {
            paddingLeft: '40px'
        }
    }
});

export const inputIconRightClassName = style({
    padding: `0 ${MARGINS_PX}px 0 0`,
    right: 0,

    $nest: {
        [`+ .${inputClassName}`]: {
            paddingRight: '40px'
        }
    }
});

export const selectInvisibleClassName = style({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    padding: 0,
    margin: 0,
    border: 0
});

export const dropdownImgClassName = style({
    transform: 'rotate(180deg)'
});
