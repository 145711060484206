import { style } from 'typestyle';
import { COLOR_05, FONT_WEIGHT_BLACK, FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM, MARGINS_PX } from '../../../constants';
import { mixinFont } from '../../../style.mixin';
import { desktopStyles, tabletStyles } from '../../../utils';

const maxColumnHeightPx = 556;
const maxColumnWidthPx = 480;
const columnSHeightPx = 52;

export const homeContentClassName = style({
    display: 'block',
    width: '100%',
    padding: `${MARGINS_PX}px 0`
});

export const homeContentBoxLClassName = style({
    margin: '0 auto',
    maxWidth: maxColumnWidthPx + 'px'
}, desktopStyles({
    height: maxColumnHeightPx + 'px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
}));

export const homeContentBoxMClassName = style({
    margin: '0 auto',
    maxWidth: maxColumnWidthPx + 'px'
}, desktopStyles({
    height: `${maxColumnHeightPx - columnSHeightPx - MARGINS_PX}px`,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
}));

export const homeContentBoxSClassName = style({
    maxWidth: maxColumnWidthPx + 'px',
    margin: `${MARGINS_PX}px auto 0`
}, desktopStyles({
    height: columnSHeightPx + 'px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
}));

export const homeContentBoxSmallTitle = style({
    padding: `${MARGINS_PX}px ${MARGINS_PX}px 0`,
    ...mixinFont(12, FONT_WEIGHT_BOLD)
});
export const homeContentBoxTitle = style({
    padding: `5px ${MARGINS_PX}px 0`,
    ...mixinFont(19, FONT_WEIGHT_BLACK)
});
export const homeContentBoxBodyClassName = style({
    $nest: {
        'p': {
            margin: `0 0 ${MARGINS_PX / 2}px`,
            ...mixinFont(14, undefined, 21),

            $nest: {
                '&:last-child': {
                    marginBottom: 0
                }
            }
        }
    }
}, desktopStyles({
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'auto',
}));
export const homeContentBoxFooterLink = style({
    textAlign: 'right',
    padding: `0 ${MARGINS_PX}px ${MARGINS_PX}px`,

    $nest: {
        'a': {
            ...mixinFont(12, FONT_WEIGHT_BOLD),
            textDecoration: 'none',
            cursor: 'pointer'
        },
        'img': {
            transform: 'rotate(90deg)',
            marginLeft: '5px'
        }
    }
});

export const homeContentBoxFooterLinkIconClassName = style({});

export const homeContentDonutWrapperClassName = style({
}, tabletStyles({
    width: '85%',
    margin: '0 auto'
}));

export const homeContentCountryDataClassName = style({
    display: 'block',
    margin: `${MARGINS_PX * 2}px 0 0 0`,

    $nest: {
        'strong': {
            display: 'block',
            margin: `${MARGINS_PX}px 0 0 0`,
            ...mixinFont(14, FONT_WEIGHT_BOLD),
        },
        'span': {
            display: 'block',
            ...mixinFont(14)
        }
    }
});

export const homePageSpecificProfilesButtonClassName = style({
    ...mixinFont(16, FONT_WEIGHT_BOLD, undefined, COLOR_05),
    textDecoration: 'none'
});

export const homePageSpecificProfilesIconClassName = style({
    float: 'right'
});
