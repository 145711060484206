import { Taxonomy } from "./state/types";

// ROUTER PATHS
export const ROUTE_PAGE_LOGIN = '/login';
export const ROUTE_PAGE_HOME = '/';
export const ROUTE_PAGE_COUNTRY_PROFILE = '/country-profiles';
export const ROUTE_PAGE_QUOTA_ANALYSIS = '/quota-analysis';
export const ROUTE_PAGE_COUNTRY_COMPARISON = '/country-comparison';
export const ROUTE_PAGE_ABOUT = '/about';
export const ROUTE_PAGE_TERMS_OF_USE = '/terms-of-use';
export const ROUTE_PAGE_PRIVACY_NOTICE = '/privacy-notice';
export const ROUTE_PAGE_LOGOUT = '/logout';

// API
export const BASE_URL = process.env.REACT_APP_API_URL || '';
export const API_BASE_URL = BASE_URL + '/api';

// COLORS
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_BLACK = '#000000';
export const COLOR_ERROR = '#ff4b4b';
export const COLOR_01 = '#009EDB'; // UN skyblue
export const COLOR_02 = '#F9F9F9';
export const COLOR_03 = '#ABE6FA';
export const COLOR_04 = '#003858';
export const COLOR_05 = '#00466D'; // deep blue
export const COLOR_06 = '#E7F4F9'; // soft skyblue for background
export const COLOR_07 = '#56CEFB'; // light skyblue
export const COLOR_08 = '#00397B'; // repeated
export const COLOR_09 = '#99C9AC'; // table gradient 1
export const COLOR_10 = '#0075FF'; // shiny skyblue
export const COLOR_11 = '#00B6F0'; // table gradient 2
export const COLOR_12 = '#333333'; // dark gray of most texts
export const COLOR_13 = '#E3E3E3';
export const COLOR_14 = '#B1B1B1';
export const COLOR_15 = '#D4E1E5';
export const COLOR_16 = '#F3F3F3';
export const COLOR_17 = '#BCC8CC';
export const COLOR_18 = '#CECECE';
export const COLOR_19 = '#C1FBD8';
export const COLOR_20 = '#6FC59B';
export const COLOR_21 = '#DAE6E9';
export const COLOR_22 = 'rgba(7, 57, 88, 0.5)';
export const COLOR_23 = 'rgba(58, 144, 192, 0.32)'; // used for shadow of boxes
export const COLOR_24 = '#428BB4';
export const COLOR_25 = '#2F89BB';
export const COLOR_26 = '#82B6D2';
export const COLOR_27 = '#56B1E4'; // for login color overlay
export const COLOR_28 = '#073958'; // for lines between charts in global statistics
export const COLOR_29 = '#00B6FD';
export const COLOR_30 = '#DBEFF6'; // for alternate global quota donut chart

// FONTS
export const FONT_MAIN: string = '"Roboto", sans-serif';
export const FONT_WEIGHT_LIGHT: number = 300;
export const FONT_WEIGHT_REGULAR: number = 400;
export const FONT_WEIGHT_MEDIUM: number = 500;
export const FONT_WEIGHT_BOLD: number = 700;
export const FONT_WEIGHT_BLACK: number = 900;

// MOBILE DEFINITIONS (GLOBAL)
export const PAGE_MENU_HEIGHT_PX: number = 60;
export const PAGE_MENU_ZINDEX: number = 9;
export const PAGE_CONTENT_ZINDEX: number = 1;
export const PAGE_FOOTER_HEIGHT_PX: number = 240;
export const PAGE_FOOTER_ZINDEX: number = 8;
export const MODAL_ZINDEX: number = 10;
export const CONTENT_MAX_WIDTH_PX: number = 1200;
export const MARGINS_PX: number = 16;

// TABLET DEFINITIONS
export const TABLET_BREAKPOINT: number = 720;
export const TABLET_PAGE_MENU_HEIGHT_PX: number = 60;
export const TABLET_PAGE_FOOTER_HEIGHT_PX: number = 60;

// DESKTOP DEFINITIONS
export const DESKTOP_BREAKPOINT: number = 1200;
export const DESKTOP_PAGE_MENU_HEIGHT_PX: number = 90;
export const DESKTOP_PAGE_FOOTER_HEIGHT_PX: number = 88;
export const SITE_FOOTER_HEIGHT_PX_DESKTOP: number = 88;

// APP // DATA COLLECTIONS AND HARDCODED DATA
export const LOCAL_STORAGE_DATA_NAME: string = 'GenderQuotaDashboardSession';
export const MESSAGE_LOGIN_ERROR: string = 'Invalid credentials';
export const MESSAGE_NOT_AVAILABLE_IN_DATE: string = 'No gender quota for selected year';
export const MESSAGE_NO_RESULTS: string = 'No result for this search';
export const LABEL_FOR_CURRENT_DATE: string = 'Current';
export const COUNTRY_COMPARISON_RESULTS_MAX_SELECTION: number = 5;
export const COUNTRY_COUNT: number = 193;
export const COUNTRY_COUNT_AMERICAS_AND_CARIBBEAN: number = 193;
export const COUNTRY_COUNT_ARAB_STATES: number = 193;
export const COUNTRY_COUNT_ASIA_AND_PACIFIC: number = 193;
export const COUNTRY_COUNT_EAST_AND_SOUTHERN_AFRICA: number = 193;
export const COUNTRY_COUNT_EUROPE_AND_CENTRAL_ASIA: number = 193;
export const COUNTRY_COUNT_WEST_AND_CENTRAL_AFRICA: number = 193;
export const COUNTRY_COUNT_ASIA: number = 48;
export const COUNTRY_COUNT_OCEANIA: number = 14;
export const COUNTRY_COUNT_AFRICA: number = 54;
export const COUNTRY_COUNT_EUROPE: number = 43;
export const COUNTRY_COUNT_AMERICAS: number = 35;
export const LEGAL_SOURCE_TRUE_ID: number = 229;
export const LEGAL_SOURCE_FALSE_ID: number = 228;
export const NOT_APPLICABLE_TEXT: string = 'N/A';
export const QUOTA_TYPE_CANDIDATE_QUOTA_ID: number = 14;
export const QUOTA_TYPE_RESERVED_SEATS_ID: number = 15;
export const QUOTA_TYPE_EMPTY_TEXT: string = 'None';
export const TAXONOMIES_LEGAL_SOURCES: Taxonomy[] = [
    {id: 1, name: 'Constitution'},
    {id: 2, name: 'Electoral law'},
    {id: 3, name: 'Parity law'},
    {id: 4, name: 'Party law'},
    {id: 5, name: 'Financing law'},
    {id: 6, name: 'Political agreement'}
];
export const MONTH: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
