import React from 'react';
import CountryComparisonTitleAndFilters from '../../partials/country-comparison-title-and-filters/country-comparison-title-and-filters.component';
import CountryComparisonResults from '../../partials/country-comparison-results/country-comparison-results.component';
import PageHeader from '../../common/page-header/page-header.component';
import Page from '../../common/page/page.component';
import { useAppFilters, useAppState } from '../../../state/hook';

const CountryComparisonPage: React.FC = () => {
    const [, {
        getCountryProfileList,
        getPage
    }] = useAppState();
    const [filters, changeFilters] = useAppFilters();
    const countryProfileList = getCountryProfileList(filters);
    const page = getPage('Country Comparison');
    const pageTitle = 'Compare up to 5 countries';
     
    return (
        <Page>
            { page && (
                <PageHeader>
                    <CountryComparisonTitleAndFilters
                        title={ pageTitle }
                        description={ page.html }
                        filters={ filters }
                        onChangeFilters={ changeFilters }/>
                </PageHeader>
            ) }
            <CountryComparisonResults
                countryProfileList={ countryProfileList }
                filters={ filters }
                onChangeFilters={ changeFilters }/>
        </Page>
    );
};

export default CountryComparisonPage;
