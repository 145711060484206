import React, { useState } from 'react';
import {
    COLOR_06,
    COLOR_30,
    COUNTRY_COUNT,
    LEGAL_SOURCE_TRUE_ID
} from '../../../constants';
import { useAppState } from '../../../state/hook';
import {
    getTaxonomyName
} from '../../../utils';
import Content from '../../common/content/content.component';
import Select from '../../common/form/select.component';
import QuotaTypeChart from '../../common/quota-type-chart/quota-type-chart.component';
import { Cell, Grid } from '../../common/grid/grid.component';
import Icon from '../../common/icon/icon.component';
import Spacer from '../../common/spacer/spacer.component';
import {
    globalStatisticsClassName,
    globalStatisticsDropdownTitleClassName,
    globalStatisticsDropdownIconClassName,
    globalStatisticsDataNameClassName,
    globalStatisticsListClassName,
    globalStatisticsListNumberClassName,
    globalStatisticsListDescriptionClassName,
    globalStatisticsNoteClassName,
    globalStatisticsGridWrapperNameClassName,
    globalStatisticsBarsClassName,
    globalStatisticsSubtitleClassName
} from './quota-analysis-global-statistics.style';
import Bars from '../../common/bars/bars.component';
import QuotaTypeNotes from '../../common/quota-type-notes/quota-type-notes.component';

const idForGlobalOption: string = '0';

const GlobalStatistics: React.FC = () => {
    const [{
        taxonomies
    }, {
        getCountryProfileList
    }] = useAppState();
    const selectOptions = [
        {value: idForGlobalOption, label: 'Global statistics'},
        ...taxonomies.regions.map(region => ({
            value: String(region.id),
            label: 'Regional statistics: ' + region.name
        }))
    ];
    const [selectedOptionValue, changeSelectedOptionValue] = useState<string>('0');
    const selectedOption = (() => {
        for (const option of selectOptions) {
            if (option.value === selectedOptionValue) {
                return option;
            }
        }

        // this should never happen
        return selectOptions[0];
    })()
    const selectedRegion = selectedOptionValue === idForGlobalOption ? null : (() => {
        for (const region of taxonomies.regions) {
            if (region.id === Number(selectedOptionValue)) {
                return region;
            }
        }

        return null;
    })();

    const filteredCountryProfiles = getCountryProfileList({
        regionIds: selectedRegion ? [selectedRegion.id] : [],
        countryIds: [],
        typeOfQuotaIds: [],
        electoralSystemIds: [],
        legalSourcesIds: [],
        date: null
    });

    const ranges = {
        lessOrEqualThan19: {amount: 0, countryNames: Array<string>()},
        between20and29: {amount: 0, countryNames: Array<string>()},
        between30and39: {amount: 0, countryNames: Array<string>()},
        between40and49: {amount: 0, countryNames: Array<string>()},
        overOrEqualThan50: {amount: 0, countryNames: Array<string>()}
    };
    const electoralSystems: {[electoralSystemId: number]: number} = {};
    const legalSourcesData = {
        'Contitutions': 0,
        'Electoral laws': 0,
        'Parity laws': 0,
        'Party laws': 0,
        'Financing laws': 0,
        'Political agreement': 0
    };

    filteredCountryProfiles.forEach(countryProfile => {
        const {
            quotaTarget,
            countryId
        } = countryProfile;
        const countryName = getTaxonomyName(taxonomies, 'countries', countryId);

        if (quotaTarget >= 50) {
            ranges.overOrEqualThan50.amount++;
            ranges.overOrEqualThan50.countryNames.push(countryName);
        }
        else if (quotaTarget >= 40) {
            ranges.between40and49.amount++;
            ranges.between40and49.countryNames.push(countryName);
        }
        else if (quotaTarget >= 30) {
            ranges.between30and39.amount++;
            ranges.between30and39.countryNames.push(countryName);
        }
        else if (quotaTarget >= 20) {
            ranges.between20and29.amount++;
            ranges.between20and29.countryNames.push(countryName);
        }
        else {
            ranges.lessOrEqualThan19.amount++;
            ranges.lessOrEqualThan19.countryNames.push(countryName);
        }

        (Object.keys(ranges) as Array<keyof typeof ranges>).forEach(rangeKey => {
            ranges[rangeKey].countryNames.sort((a, b) => a > b ? 1 : -1);
        });

        if (electoralSystems[countryProfile.electoralSystemTypeId] === undefined) {
            electoralSystems[countryProfile.electoralSystemTypeId] = 0;
        }

        if (countryProfile.constitutionProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) {
            legalSourcesData['Contitutions']++;
        }

        if (countryProfile.electoralProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) {
            legalSourcesData['Electoral laws']++;
        }

        if (countryProfile.parityProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) {
            legalSourcesData['Parity laws']++;
        }

        if (countryProfile.partyProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) {
            legalSourcesData['Party laws']++;
        }

        if (countryProfile.financingProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) {
            legalSourcesData['Financing laws']++;
        }

        if (countryProfile.politicalProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID) {
            legalSourcesData['Political agreement']++;
        }

        electoralSystems[countryProfile.electoralSystemTypeId]++;
    });

    const electoralSystemItem = (
        amount: number,
        description: string,
        key: number = 0
    ) => (
        <tr key={ key }>
            <td className={ globalStatisticsListNumberClassName }>
                { amount }
            </td>
            <td className={ globalStatisticsListDescriptionClassName }>
                { description }
            </td>
        </tr>
    );

    return (
        <div className={ globalStatisticsClassName }>
            <Content>
                <Grid>
                    <Cell size={ 12 } sizeTablet={ 'stack' }>
                        <Select
                            options={ selectOptions }
                            value={ selectedOptionValue }
                            onChange={ changeSelectedOptionValue }>
                                <div className={ globalStatisticsDropdownTitleClassName }>
                                    { selectedOption.label }
                                    <Icon name='dropdown' className={ globalStatisticsDropdownIconClassName }/>
                                </div>
                        </Select>
                    </Cell>
                    <Cell size={ 12 } sizeTablet={ 'fill' }>
                        <div className={ globalStatisticsSubtitleClassName }>
                            Select global or regional statistics
                        </div>
                    </Cell>
                </Grid>
                <Spacer x2/>
                <div className={ globalStatisticsGridWrapperNameClassName }>
                    <Grid>
                        <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                            <div className={ globalStatisticsDataNameClassName }>
                                Quota type
                            </div>
                            <div style={ {padding: '0 30px'} }>
                                <QuotaTypeChart
                                    profiles={ filteredCountryProfiles }
                                    countryCount={ selectedRegion?.countryCount || COUNTRY_COUNT }
                                    customBackground={ [COLOR_30, COLOR_06] }/>
                            </div>
                            <QuotaTypeNotes
                                profiles={ filteredCountryProfiles }
                                countryCount={ selectedRegion?.countryCount || COUNTRY_COUNT }/>
                        </Cell>
                        <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                            <div className={ globalStatisticsDataNameClassName }>
                                Quota target range (% women)
                            </div>
                            <Bars
                                className={ globalStatisticsBarsClassName }
                                data={ [
                                    [ranges.lessOrEqualThan19.amount, '< 20', ranges.lessOrEqualThan19.countryNames.join(', ')],
                                    [ranges.between20and29.amount, '20 - 29', ranges.between20and29.countryNames.join(', ')],
                                    [ranges.between30and39.amount, '30 - 39', ranges.between30and39.countryNames.join(', ')],
                                    [ranges.between40and49.amount, '40 - 49', ranges.between40and49.countryNames.join(', ')],
                                    [ranges.overOrEqualThan50.amount, '50', ranges.overOrEqualThan50.countryNames.join(', ')]
                                ] }/>
                            <div className={ globalStatisticsNoteClassName }>
                                Number of countries with legislated quotas
                            </div>
                        </Cell>
                        <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                            <div className={ globalStatisticsDataNameClassName }>
                                Electoral systems
                            </div>
                            <table className={ globalStatisticsListClassName }>
                                <tbody>
                                    { Object.keys(electoralSystems).map((electoralSystemId, index) => (
                                        electoralSystemItem(
                                            electoralSystems[Number(electoralSystemId)],
                                            getTaxonomyName(taxonomies, 'electoralSystemTypes', Number(electoralSystemId)),
                                            index
                                        )
                                    )) }
                                </tbody>
                            </table>
                            <div className={ globalStatisticsNoteClassName }>
                                Note: Number of countries with legislated quotas.
                            </div>
                        </Cell>
                        <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                            <div className={ globalStatisticsDataNameClassName }>
                                Legal sources
                            </div>
                            <table className={ globalStatisticsListClassName }>
                                <tbody>
                                    { (Object.keys(legalSourcesData) as (keyof typeof legalSourcesData)[]).map((legalSourceName, index) => (
                                        electoralSystemItem(
                                            legalSourcesData[legalSourceName],
                                            legalSourceName,
                                            index
                                        )
                                    )) }
                                </tbody>
                            </table>
                        </Cell>
                    </Grid>
                </div>
            </Content>
        </div>
    )
};

export default GlobalStatistics;
