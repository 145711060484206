import {
    useContext,
    useEffect,
    useState
} from 'react';
import { initActions } from './actions';
import { AppContext } from './context';
import { AppState, CountryProfileFilters } from './types';

export const useAppState = () => {
    const {state, changeState} = useContext(AppContext);
    const actions = initActions(state, changeState);

    return [state, actions] as [AppState, typeof actions];
};

export const useAppFilters = (
    userInitialFilters: Partial<CountryProfileFilters> = {}
) => {
    const [initialFilters, changeInitialFilters] = useState<Partial<CountryProfileFilters>>({...userInitialFilters});
    const [filters, changeFilters] = useState<CountryProfileFilters>({
        countryIds: [],
        typeOfQuotaIds: [],
        electoralSystemIds: [],
        legalSourcesIds: [],
        regionIds: [],
        date: null,
        ...userInitialFilters
    });

    const changeFiltersFunction = (
        changes: Partial<CountryProfileFilters>
    ) => changeFilters({
        ...filters,
        ...changes
    });

    const restoreFilters = () => changeFilters({
        countryIds: [],
        typeOfQuotaIds: [],
        electoralSystemIds: [],
        legalSourcesIds: [],
        regionIds: [],
        date: null,
        ...initialFilters
    });

    return [
        filters,
        changeFiltersFunction,
        restoreFilters
    ] as [
        typeof filters,
        typeof changeFiltersFunction,
        typeof restoreFilters
    ];
};
