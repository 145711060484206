import React from 'react';
import { useAppState } from '../../../state/hook';
import Content from '../../common/content/content.component';
import Page from '../../common/page/page.component';
import SectionInfo from '../../common/section-info/section-info.component';

const AboutPage: React.FC = () => {
    const [, {getPage}] = useAppState();
    const pageData = getPage('About');

    return (
        <Page vCentered>
            <Content>
                { pageData && (
                    <SectionInfo imageUrl={ pageData.imageUrl } imageText={ 'Illustration: Nadzeya Dzivakova' }>
                        <span dangerouslySetInnerHTML={ {__html: pageData.html} }/>
                    </SectionInfo>
                ) }
            </Content>
        </Page>
    );
};

export default AboutPage;
