import { style } from 'typestyle';
import {
    COLOR_01,
    COLOR_12,
    COLOR_BLACK,
    FONT_MAIN,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';

export const taxonomyFilterClassName = style({
    $debugName: 'taxonomyFilter.component',

    height: '260px',
    display: 'block',
    position: 'relative',
    overflow: 'hidden'
});

export const taxonomyFilterHeader = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: '48px',
    lineHeight: '48px',
    padding: `0 ${MARGINS_PX}px`,
    borderBottom: `solid 1px #D9D9D9`,
    boxSizing: 'border-box'
});

export const taxonomyFilterHeaderLeft = style({
    display: 'block',
    overflow: 'hidden',
    margin: `0 ${MARGINS_PX}px 0 0`,
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_BOLD,
    fontSize: '16px',
    color: COLOR_BLACK
});

export const taxonomyFilterHeaderRight = style({
});

export const taxonomyFilterClearButtonClassName = style({
    display: 'inline',
    border: 'none',
    background: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: '12px',
    color: COLOR_01
});

export const taxonomyFilterClearButtonDisabledClassName = style({
    pointerEvents: 'none',
    opacity: .25,
    color: COLOR_12
});

export const taxonomyFilterBodyClassName = style({
    display: 'block',
    padding: MARGINS_PX + 'px',
    boxSizing: 'border-box',
    height: `calc(100% - 48px)`,
    position: 'relative'
});
