import { style } from 'typestyle';
import {
    COLOR_01,
    COLOR_10,
    FONT_WEIGHT_BLACK,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';
import { mixinFont } from '../../../style.mixin';

const headersWidthPx: number = 125;

export const countryComparisonResultsClassName = style({
    display: 'block',
    padding: `${MARGINS_PX * 2}px 0`
});

export const countryComparisonContentClassName = style({
    display: 'block',
    padding: `${MARGINS_PX * 2}px 0 0 0`
});

export const countryComparisonResultsTableHeadCellClassName = style({
    width: headersWidthPx + 'px',
    boxSizing: 'border-box'
});

export const countryComparisonResultsCountryNameClassName = style({
    color: COLOR_10,
    fontSize: '16px',
    fontWeight: FONT_WEIGHT_BLACK
});

export const countryComparisonResultsCountryNameCaptionClassName = style({
    display: 'block',
    margin: '4px 0 0 0',
    ...mixinFont(12, FONT_WEIGHT_MEDIUM)
});

export const countryComparisonResultsListClassName = style({
    display: 'block',
    listStyle: 'none',
    padding: 0,
    margin: 0,

    $nest: {
        'li': {
            display: 'block',
            margin: '0 0 10px 0',
            padding: '0 0 0 20px',
            position: 'relative',

            $nest: {
                '&::before': {
                    display: 'inline-block',
                    content: '""',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    margin: '2px 10px 0 0',
                    position: 'absolute',
                    left: 0,
                    top: 0
                },
                '&:nth-child(2n + 1)::before': {
                    background: COLOR_10
                },
                '&:nth-child(2n)::before': {
                    background: COLOR_01
                },
                '&:last-child': {
                    margin: 0
                }
            }
        }
    }
});

export const countryComparisonResultsBar1ClassName = style({
    backgroundColor: COLOR_10
});

export const countryComparisonResultsBar2ClassName = style({
    backgroundColor: COLOR_01
});

export const countryComparisonResultsQuotaValueCellClassName = style({
    fontSize: '10px',
    whiteSpace: 'nowrap',
    paddingLeft: 0
});
