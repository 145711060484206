import { style } from 'typestyle';
import { COLOR_23, COLOR_WHITE, MARGINS_PX } from '../../../constants';

export const boxClassName = style({
    boxShadow: '0px 0px 6px ' + COLOR_23,
    borderRadius: '8px',
    backgroundColor: COLOR_WHITE,
    overflow: 'hidden',
    boxSizing: 'border-box',
    minHeight: MARGINS_PX + 'px'
});

export const boxTransparentClassName = style({
    backgroundColor: 'transparent'
});
