import { style } from 'typestyle';
import {
    COLOR_05,
    FONT_WEIGHT_BLACK,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';
import { mixinFont, mixinSize } from '../../../style.mixin';

export const globalQuotaTypeChartClassName = style({
    display: 'block',
    margin: '0 auto',
    maxWidth: '360px',
    fontSize: '110px'
});

export const globalQuotaTypeChartLabelNumberClassName = style({
    ...mixinFont('100%', FONT_WEIGHT_BLACK, '115%', COLOR_05)
});

export const globalQuotaTypeChartLabelTextClassName = style({
    ...mixinFont('15%', FONT_WEIGHT_MEDIUM, '115%')
});

export const globalQuotaTypeChartCaptionClassName = style({
    padding: `${MARGINS_PX}px 0 0 0`,
    maxWidth: '140px',
    margin: '0 auto'
});

export const globalQuotaTypeChartCaptionNumberClassName = style({
    ...mixinFont(32, FONT_WEIGHT_BOLD, '115%')
});

export const globalQuotaTypeChartCaptionCircleClassName = style({
    display: 'inline-block',
    ...mixinSize(24),
    backgroundColor: 'gainsboro',
    borderRadius: '50%',
    marginRight: '8px'
});

export const globalQuotaTypeChartCaptionTextClassName = style({
    ...mixinFont('13%', FONT_WEIGHT_MEDIUM, '115%')
});
