import { style } from 'typestyle';
import {
    PAGE_FOOTER_HEIGHT_PX,
    PAGE_FOOTER_ZINDEX,
    MARGINS_PX,
    TABLET_PAGE_FOOTER_HEIGHT_PX,
    COLOR_05,
    SITE_FOOTER_HEIGHT_PX_DESKTOP,
    FONT_WEIGHT_LIGHT,
    FONT_MAIN,
    COLOR_WHITE,
    FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR
} from '../../../constants';
import {
    desktopStyles,
    tabletStyles
} from '../../../utils';

export const bottomContentClassName = style({
    height: PAGE_FOOTER_HEIGHT_PX + 'px',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: PAGE_FOOTER_ZINDEX,
    background: COLOR_05,
}, tabletStyles({
    height: TABLET_PAGE_FOOTER_HEIGHT_PX + 'px'
}), desktopStyles({
    height: SITE_FOOTER_HEIGHT_PX_DESKTOP + 'px'
}));

export const pageFooterClassName = style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    boxSizing: 'border-box',
    padding: `${MARGINS_PX}px`
}, tabletStyles({
    flexDirection: 'row'
}));

export const pageFooterTextClassName = style({
    color: COLOR_WHITE,
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_LIGHT,
    textTransform: 'uppercase'
}, tabletStyles({
    fontSize: '12px',
    lineHeight: '14px'
}), desktopStyles({
    fontSize: '16px',
    lineHeight: '18px'
}));

export const pageFooterSidesClassName = style({
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
}, tabletStyles({
    height: '100%',
    flexShrink: 1,
    justifyContent: 'flex-start'
}));

export const pageFooterCenterClassName = style({
    height: 'auto',
    flexGrow: 1,
    flexShrink: 0
}, tabletStyles({
    height: '100%',
    flexGrow: 1,
    flexShrink: 1,
    padding: `0 ${MARGINS_PX}px`
}));

export const pageFooterImgClassName = style({
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
});

export const pageFooterLinksClassName = style({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none'
}, tabletStyles({
    flexDirection: 'row',
    justifyContent: 'flex-end'
}), desktopStyles({
    flexDirection: 'row'
}));

export const pageFooterLinkItemClassName = style({
    margin: '2px 0'
}, tabletStyles({
    marginLeft: MARGINS_PX + 'px'
}), desktopStyles({
    marginLeft: MARGINS_PX + 'px'
}));

export const pageFooterLinkClassName = style({
    $nest: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

export const pageFooterTitleClassName = style({
    marginLeft: MARGINS_PX + 'px'
});

export const pageFooterCopyrightClassName = style({
    fontWeight: FONT_WEIGHT_LIGHT,
    $nest: {
        'a': {
          textDecoration: 'none',
          color: '#FFF'
        },
        'a:hover': {
            textDecoration: 'underline'
        }
    }
});
