import React from 'react';
import { classes } from 'typestyle';
import {
    titleClassName,
    titleEllipsisClassName
} from './title.style';

interface TitleProps {
    children?: React.ReactNode,
    className?: string,
    ellipsis?: boolean
}

const Title: React.FC<TitleProps> = ({
    children,
    className,
    ellipsis = false
}) => (
    <div
        className={ classes(
            titleClassName,
            ellipsis && titleEllipsisClassName,
            className
        ) }
        children={ children }/>
);

export default Title;
