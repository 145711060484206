import { style } from 'typestyle';
import {
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_LIGHT,
    FONT_WEIGHT_REGULAR,
    MARGINS_PX
} from '../../../constants';
import { mixinFont } from '../../../style.mixin';

export const commentsClassName = style({
    ...mixinFont(12, FONT_WEIGHT_LIGHT),
    margin: `${MARGINS_PX}px 0 0 0`,

    $nest: {
        'p': {
            ...mixinFont(12, FONT_WEIGHT_REGULAR),
            margin: '5px 0'
        },
        'strong': {
            fontWeight: FONT_WEIGHT_BOLD
        }
    }
});
