import React from 'react';
import { classes } from 'typestyle';
import {
    buttonClassName,
    buttonDefaultClassName,
    buttonLightClassName,
    buttonBlockClassName
} from './button.style';

interface ButtonProps {
    onClick?(): void,
    link?: string,
    format?: ButtonFormat,
    block?: boolean,
    children?: React.ReactNode,
    className?: string
}

type ButtonFormat = 'default' | 'light';

const Button: React.FC<ButtonProps> = ({
    onClick,
    link,
    format = 'default',
    block = false,
    children,
    className
}) => (
    <button
        type='button'
        className={ classes(
            className,
            buttonClassName,
            (() => {
                switch(format) {
                    case 'default': return buttonDefaultClassName;
                    case 'light': return buttonLightClassName;
                }
            })(),
            block && buttonBlockClassName
        ) }
        onClick={ event => {
            event.preventDefault();
            onClick && onClick();
            link && (() => {

            })();
        } }
        children={ children }/>
);

export default Button;
