import React from 'react';
import { classes } from 'typestyle';
import { Taxonomy } from '../../../state/types';
import Box from '../box/box.component';
import TaxonomyListGrouped from '../taxonomy-list/taxonomy-list-grouped.component';
import TaxonomyList from '../taxonomy-list/taxonomy-list.component';
import Title from '../title/title.component';
import {
    taxonomyFilterHeader,
    taxonomyFilterHeaderLeft,
    taxonomyFilterHeaderRight,
    taxonomyFilterClearButtonClassName,
    taxonomyFilterBodyClassName,
    taxonomyFilterClassName,
    taxonomyFilterClearButtonDisabledClassName
} from './taxonomy-filter.style';

interface TaxonomyFilterProps {
    title: string,
    taxonomies: Taxonomy[],
    selectedIds?: number[],
    onChange?(newSelectedIds: number[]): void,
    grouped?: boolean | string,
    children?: React.ReactNode,
    className?: string,
    maxSelections?: number
}

const TaxonomyFilter: React.FC<TaxonomyFilterProps> = ({
    title,
    taxonomies,
    selectedIds,
    onChange,
    grouped = false,
    children,
    className,
    maxSelections
}) => {
    const handleClickClearButton = (event: React.MouseEvent) => {
        event.preventDefault();
        onChange && onChange([]);
    };

    return (
        <Box className={ classes(taxonomyFilterClassName, className) }>
            <div className={ taxonomyFilterHeader }>
                <div className={ taxonomyFilterHeaderLeft }>
                    { title }
                </div>
                <div className={ taxonomyFilterHeaderRight }>
                    <button
                        type='button'
                        className={ classes(
                            taxonomyFilterClearButtonClassName,
                            !(selectedIds?.length) ? taxonomyFilterClearButtonDisabledClassName : undefined
                        ) }
                        onClick={ handleClickClearButton }
                    >
                        CLEAR
                    </button>
                </div>
            </div>
            <div className={ taxonomyFilterBodyClassName }>
                { grouped ? (
                    <TaxonomyListGrouped
                        list={ taxonomies }
                        selectedIds={ selectedIds }
                        onChange={ onChange }
                        searchField={ grouped }
                        children={ children }
                        maxSelections={ maxSelections }/>
                ) : (
                    <TaxonomyList
                        list={ taxonomies }
                        selectedIds={ selectedIds }
                        onChange={ onChange }
                        children={ children }
                        maxSelections={ maxSelections }/>
                )}
            </div>
        </Box>
    );
};

export default TaxonomyFilter;
