import { style } from 'typestyle';
import {
    COLOR_05,
    COLOR_WHITE,
    FONT_MAIN,
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    MARGINS_PX
} from '../../../constants';
import { mixinFont, mixinSize } from '../../../style.mixin';

const itemMinHeightPx: number = 12;
const rangeNumberWidthPx: number = 20;
const linesColor: string = COLOR_WHITE;

export const barsClassName = style({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%'
});

export const barsSideClassName = style({
    display: 'block',
    position: 'relative'
});

export const barsSideLeftClassName = style({
    flexGrow: 1,
    flexShrink: 1
});

export const barsSideRightClassName = style({
    flexGrow: 0,
    flexShrink: 0,
    padding: `0 0 0 ${MARGINS_PX}px`
});

export const barsLinesClassName = style({
    display: 'block',
    position: 'absolute',
    borderBottom: 'solid 1px ' + linesColor,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100% - ${rangeNumberWidthPx}px)`,
    pointerEvents: 'none'
});

export const barsLineClassName = style({
    display: 'block',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '1px',
    background: linesColor
});

export const barsItemsClassName = style({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    height: `calc(100% - ${rangeNumberWidthPx}px)`
});

export const barsItemWrapperClassName = style({
    flexGrow: 0,
    flexShrink: 0,
    boxSizing: 'border-box',
    padding: `10px 0`,
    overflow: 'hidden'
});

export const barsItemClassName = style({
    height: '100%',
    width: '100%',
    minHeight: itemMinHeightPx + 'px',
    minWidth: itemMinHeightPx + 'px'
});

export const barsProgressBarClassName = style({
    height: '100%',
    minHeight: itemMinHeightPx + 'px'
});

export const barsLabelClassName = style({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center'
});

export const barsLabelInnerClassName = style({
    fontSize: '12px',
    fontFamily: FONT_MAIN,
    fontWeight: FONT_WEIGHT_MEDIUM,
    color: COLOR_05,
    textAlign: 'left'
});

export const barsRangeClassName = style({
    display: 'block',
    position: 'relative',
    overflow: 'visible',
    height: rangeNumberWidthPx + 'px',
    width: '100%'
});

export const barsRangeValueClassName = style({
    ...mixinSize(rangeNumberWidthPx),
    position: 'absolute',
    top: 0,
    margin: `0 0 0 -${rangeNumberWidthPx / 2}px`,
    textAlign: 'center',
    ...mixinFont(10, FONT_WEIGHT_BOLD, rangeNumberWidthPx, COLOR_05)
});
