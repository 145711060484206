import { style, keyframes } from 'typestyle';
import { COLOR_05 } from '../../../constants';

const scaleUpKeyframe = keyframes({
    '0%': {
        transform: 'translate(-50%, -50%) scale(0)'
    },
    '60%, 100%': {
        transform: 'translate(-50%, -50%)  scale(1)'
    }
});

const pulseKeyframe = keyframes({
    '0%, 60%, 100%': {
        transform: 'scale(1)'
    },
    '80%': {
        transform: 'scale(1.2)'
    }
});

export const spinnerWrapper = style({
    display: 'block',
    position: 'relative',
    textAlign: 'center'
});

export const spinnerClassName = style({
    width: '48px',
    height: '48px',
    border: '5px solid ' + COLOR_05,
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    position: 'relative',
    animation: pulseKeyframe + ' 1s linear infinite',
    opacity: .5,

    $nest: {
        '&::after': {
            content: "''",
            position: 'absolute',
            width: '48px',
            height: '48px',
            border: '5px solid ' + COLOR_05,
            borderRadius: '50%',
            display: 'inline-block',
            boxSizing: 'border-box',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            animation: scaleUpKeyframe + ' 1s linear infinite'
        }
    }
});
