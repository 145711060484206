import React, { useState } from 'react';
import { useAppState } from '../../../state/hook';
import { ComponentWithFilterHandlersProps } from '../../../state/types';
import Button from '../../common/button/button.component';
import { Cell, Grid } from '../../common/grid/grid.component';
import PageSection from '../../common/page-section/page-section.component';
import TaxonomyFilter from '../../common/taxonomy-filter/taxonomy-filter.component';

import {
    filterButtonPadClassName,
    filterCountryRegionToggleButtonClassName,
    filterCountryRegionOptionsContainerClassName,
    filterCountryRegionSelectAllCountriesContainerClassName,
    filterCountryRegionToggleClassName
} from './quota-analysis-filters.style';

interface FiltersProps extends ComponentWithFilterHandlersProps {
}

const Filters: React.FC<FiltersProps> = ({
    filters,
    onChangeFilters
}) => {
    const [{taxonomies}] = useAppState();
    const [filterByRegion, changeFilterByRegion] = useState<boolean>(false);
    const [filterCountryIds, changeFilterCountryIds] = useState<number[]>(filters.countryIds);
    const [filterTypeOfLegislatedQuotaIds, changeFilterTypeOfLegislatedQuotaIds] = useState<number[]>(filters.typeOfQuotaIds);
    const [filterElectoralSystemIds, changeFilterElectoralSystemIds] = useState<number[]>(filters.electoralSystemIds);
    const [filterLegalSourcesIds, changeFilterLegalSourcesIds] = useState<number[]>(filters.legalSourcesIds);
    const [filterRegionIds, changeFilterRegionIds] = useState<number[]>(filters.regionIds);

    const allCountriesSelected: boolean = filterCountryIds.length === taxonomies.countries.length;

    const handleClickApplyFilters = () => {
        onChangeFilters({
            ...filters,
            countryIds: filterCountryIds,
            typeOfQuotaIds: filterTypeOfLegislatedQuotaIds,
            electoralSystemIds: filterElectoralSystemIds,
            legalSourcesIds: filterLegalSourcesIds,
            regionIds: filterRegionIds
        });
    };

    const handleClickCountryRegionToggle = (event: React.MouseEvent) => {
        event.preventDefault();

        if (filterByRegion) {
            changeFilterRegionIds([]);
        }
        else {
            changeFilterCountryIds([]);
        }

        changeFilterByRegion(!filterByRegion);
    };

    const handleChangeSelectAllCountriesCheckbox = (checked: boolean) => {
        if (checked) {
            changeFilterCountryIds(taxonomies.countries.map(countryTaxonomy => countryTaxonomy.id));
        }
        else {
            changeFilterCountryIds([]);
        }
    };

    const countryRegionToggleButton = (
        <div className={ filterCountryRegionOptionsContainerClassName }>
            <div className={ filterCountryRegionSelectAllCountriesContainerClassName }>
                { !filterByRegion && (
                    <span onClick={ () => handleChangeSelectAllCountriesCheckbox(!allCountriesSelected) }>
                        { allCountriesSelected ? 'Unselect' : 'Select' } all countries
                    </span>
                ) }
            </div>
            <div className={ filterCountryRegionToggleClassName }>
                <button
                    type='button'
                    className={ filterCountryRegionToggleButtonClassName }
                    onClick={ handleClickCountryRegionToggle }
                    children={ `Select by ${filterByRegion ? 'country' : 'region'} >` }/>
            </div>
        </div>
    );

    return (
        <PageSection title='Select parameters for own analysis'>
            <Grid>
                <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                    { filterByRegion ? (
                        <TaxonomyFilter
                            title='Region'
                            taxonomies={ taxonomies.regions }
                            selectedIds={ filterRegionIds }
                            onChange={ changeFilterRegionIds }
                            children={ countryRegionToggleButton }/>
                    ) : (
                        <TaxonomyFilter
                            title='Country'
                            taxonomies={ taxonomies.countries }
                            selectedIds={ filterCountryIds }
                            onChange={ changeFilterCountryIds }
                            grouped='Search for a country...'
                            children={ countryRegionToggleButton }/>
                    ) }
                </Cell>
                <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                    <TaxonomyFilter
                        title='Type of Legislated Quota'
                        taxonomies={ taxonomies.typesOfQuota }
                        selectedIds={ filterTypeOfLegislatedQuotaIds }
                        onChange={ changeFilterTypeOfLegislatedQuotaIds }/>
                </Cell>
                <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                    <TaxonomyFilter
                        title='Electoral System'
                        taxonomies={ taxonomies.electoralSystemTypes }
                        selectedIds={ filterElectoralSystemIds }
                        onChange={ changeFilterElectoralSystemIds }/>
                </Cell>
                <Cell size={ 12 } sizeTablet={ 6 } sizeDesktop={ 3 }>
                    <TaxonomyFilter
                        title='Legal Sources'
                        taxonomies={ taxonomies.legalSources }
                        selectedIds={ filterLegalSourcesIds }
                        onChange={ changeFilterLegalSourcesIds }/>
                </Cell>
            </Grid>
            <div className={ filterButtonPadClassName }>
                <Button onClick={ handleClickApplyFilters }>
                    Confirm Selection
                </Button>
            </div>
        </PageSection>
    );
};

export default Filters;
