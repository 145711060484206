export const getRange = (
    max: number
): number[] => {
    const result: number[] = [];
    const steps: number = 5;
    const stepSize: number = max / (steps - 1);

    for (let currentStep = 0; currentStep < steps; currentStep++) {
        result.push(currentStep * stepSize);
    }

    return result;
};

export const getPercentageValue = (
    max: number,
    value: number
): number => (value * 100) / max;
