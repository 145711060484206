import React from 'react';
import {
    iconSearch,
    iconDropdown,
    iconDownload,
    iconPrint,
    iconCheck
} from '../../../assets';

interface IconProps {
    name: IconName,
    alt?: string,
    width?: string,
    height?: string,
    className?: string
}

type IconName = 'magnifyingGlass' | 'download' | 'print' | 'dropdown' | 'check';

const Icon: React.FC<IconProps> = ({
    name,
    alt,
    width,
    height,
    className
}) => (
    <img
        alt={ alt }
        width={ width }
        height={ height }
        className={ className }
        src={ (() => {
            switch(name) {
                case 'download': return iconDownload;
                case 'magnifyingGlass': return iconSearch;
                case 'print': return iconPrint;
                case 'dropdown': return iconDropdown;
                case 'check': return iconCheck;
            }
        })() }/>
);

export default Icon;
