import { style } from 'typestyle';
import { COLOR_10, COLOR_12, FONT_WEIGHT_BOLD, MARGINS_PX } from '../../../constants';

export const collapsibleContentLineClassName = style({
    display: 'block',
    content: '""',
    height: '1px',
    width: '100%',
    background: COLOR_10,
    opacity: .25
});

export const collapsibleContentClassName = style({
    $nest: {
        [`&:last-of-type .${collapsibleContentLineClassName}`]: {
            display: 'none'
        }
    }
});

export const collapsibleContentTitleClassName = style({
    display: 'block',
    cursor: 'pointer',
    color: COLOR_10,
    padding: '5px 0',
    position: 'relative'
});

export const collapsibleContentContentClassName = style({
    padding: `3px 0 ${MARGINS_PX}px 0`
});

export const collapsibleContentIconClassName = style({
    display: 'block',
    width: '8px',
    height: '8px',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',

    $nest: {
        '> span': {
            display: 'block',
            position: 'absolute',
            background: COLOR_10,
            borderRadius: '1px',

            $nest: {
                '&:first-child': {
                    width: '100%',
                    height: '2px',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)'
                },
                '&:last-child': {
                    width: '2px',
                    height: '100%',
                    left: '50%',
                    top: 0,
                    transform: 'translateX(-50%)'
                }
            }
        }
    }
});

export const collapsibleContentTitleOpenContentClassName = style({
    fontWeight: FONT_WEIGHT_BOLD,

    $nest: {
        [`.${collapsibleContentIconClassName}`]: {
            transform: 'translateY(-50%) rotate(45deg)',
        }
    }
});

export const collapsibleContentTitleDisabledClassName = style({
    cursor: 'not-allowed',
    color: COLOR_12 + '!important',
    opacity: .5
});