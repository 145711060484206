import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    COUNTRY_COUNT,
    ROUTE_PAGE_ABOUT,
    ROUTE_PAGE_COUNTRY_PROFILE,
    ROUTE_PAGE_QUOTA_ANALYSIS
} from '../../../constants';
import { useAppFilters, useAppState } from '../../../state/hook';
import { getTaxonomyName } from '../../../utils';
import Box from '../../common/box/box.component';
import Content from '../../common/content/content.component';
import CountryProfileDonuts from '../../common/country-profile-donuts/country-profile-donuts.component';
import QuotaTypeChart from '../../common/quota-type-chart/quota-type-chart.component';
import { Cell, Grid } from '../../common/grid/grid.component';
import Icon from '../../common/icon/icon.component';
import Page from '../../common/page/page.component';
import Spacer from '../../common/spacer/spacer.component';
import {
    homeContentBoxBodyClassName,
    homeContentBoxFooterLink,
    homeContentBoxFooterLinkIconClassName,
    homeContentBoxLClassName,
    homeContentBoxMClassName,
    homeContentBoxSClassName,
    homeContentBoxSmallTitle,
    homeContentBoxTitle,
    homeContentClassName,
    homeContentCountryDataClassName,
    homeContentDonutWrapperClassName,
    homePageSpecificProfilesButtonClassName,
    homePageSpecificProfilesIconClassName
} from './home-page.style';
import QuotaTypeNotes from '../../common/quota-type-notes/quota-type-notes.component';

const HomePage: React.FC = () => {
    const [{
        taxonomies,
        featuredCountryId
    }, {
        getPage,
        getCountryProfile,
        getCountryProfileList,
        setRandomFeaturedCountryId
    }] = useAppState();
    const [filters] = useAppFilters();

    const profiles = getCountryProfileList(filters);
    const featuredCountryProfile = featuredCountryId ? getCountryProfile(featuredCountryId, filters.date) : null
    const pageData = getPage('Home');

    useEffect(() => {
        setRandomFeaturedCountryId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const smallTitle = (text: string) => (
        <div className={ homeContentBoxSmallTitle }>
            { text }
        </div>
    );

    const title = (text: string) => (
        <div className={ homeContentBoxTitle }>
            { text }
        </div>
    );

    const body = (children: React.ReactNode) => (
        <div className={ homeContentBoxBodyClassName }>
            <Spacer>
                { children }
            </Spacer>
        </div>
    );

    const footerLink = (
        text: string,
        link: string
    ) => (
        <div className={ homeContentBoxFooterLink }>
            <Link to={ link }>
                { text }
                <Icon
                    name='dropdown'
                    className={ homeContentBoxFooterLinkIconClassName }/>
            </Link>
        </div>
    );

    return (
        <Page vCentered>
            <div className={ homeContentClassName }>
                <Content>
                    <Grid>
                        <Cell size={ 12 } sizeDesktop={ 4 }>
                            <Box className={ homeContentBoxLClassName }>
                                { pageData && (
                                    <>
                                        <img src={ pageData.imageUrl } alt='Gender Quota Portal'/>
                                        { body(
                                            <div dangerouslySetInnerHTML={ {__html: pageData.html} }/>
                                        )}
                                        { footerLink('More', ROUTE_PAGE_ABOUT) }
                                    </>
                                ) }
                            </Box>
                        </Cell>
                        <Cell size={ 12 } sizeDesktop={ 4 }>
                            <Box className={ homeContentBoxLClassName }>
                                { smallTitle('Featured analysis') }
                                { title('Quota type') }
                                { body(
                                    <div>
                                        <div className={ homeContentDonutWrapperClassName }>
                                            <QuotaTypeChart
                                                profiles={ profiles }
                                                countryCount={ COUNTRY_COUNT }/>
                                        </div>
                                        <QuotaTypeNotes
                                            profiles={ profiles }
                                            countryCount={ COUNTRY_COUNT }/>
                                    </div>
                                )}
                                { footerLink('Explore quota analysis', ROUTE_PAGE_QUOTA_ANALYSIS) }
                            </Box>
                        </Cell>
                        <Cell size={ 12 } sizeDesktop={ 4 }>
                            <Box className={ homeContentBoxMClassName }>
                                { smallTitle('Featured country profile') }
                                { featuredCountryProfile && title(getTaxonomyName(taxonomies, 'countries', featuredCountryProfile.countryId)) }
                                { featuredCountryProfile && body(
                                    <div>
                                        <CountryProfileDonuts countryProfile={ featuredCountryProfile }/>
                                        <div className={ homeContentCountryDataClassName }>
                                            <strong>Electoral system</strong>
                                            <span>{ getTaxonomyName(taxonomies, 'electoralSystemTypes', featuredCountryProfile.electoralSystemTypeId) }</span>
                                            <strong>Electoral system sub-type</strong>
                                            <span>{ featuredCountryProfile.electoralSystemSubType }</span>
                                            <strong>Type of quota</strong>
                                            { featuredCountryProfile.typeOfQuotaIds.map((typeOfQuotaId, index) => (
                                                <span key={ index }>
                                                    { getTaxonomyName(taxonomies, 'typesOfQuota', typeOfQuotaId) }
                                                </span>
                                            )) }
                                        </div>
                                    </div>
                                )}
                                { featuredCountryProfile && footerLink('View full country profile', ROUTE_PAGE_COUNTRY_PROFILE + '?countryId=' + featuredCountryProfile.countryId) }
                            </Box>
                            <Box className={ homeContentBoxSClassName } transparent>
                                { body(
                                    <Link to={ ROUTE_PAGE_COUNTRY_PROFILE } className={ homePageSpecificProfilesButtonClassName }>
                                        Explore country specific profiles
                                        <Icon name='magnifyingGlass' className={ homePageSpecificProfilesIconClassName }/>
                                    </Link>
                                ) }
                            </Box>
                        </Cell>
                    </Grid>
                </Content>
            </div>
        </Page>
    );
};

export default HomePage;
