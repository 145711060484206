import React from 'react';
import Content from '../content/content.component';
import { pageSectionClassName, pageSectionHeadAsideClassName, pageSectionHeadClassName, pageSectionHeadTitleClassName, pageSectionInnerClassName } from './page-section.style';

interface PageSectionProps {
    title?: React.ReactNode,
    aside?: React.ReactNode,
    children?: React.ReactNode
}

const PageSection: React.FC<PageSectionProps> = ({
    title,
    aside,
    children
}) => (
    <div className={ pageSectionClassName }>
        { (title || aside) && (
            <Content>
                <div className={ pageSectionHeadClassName }>
                    { title && (
                        <div className={ pageSectionHeadTitleClassName }>
                            { title }
                        </div>
                    ) }

                    { aside && (
                        <div className={ pageSectionHeadAsideClassName }>
                            { aside }
                        </div>
                    ) }
                </div>
            </Content>
        ) }
        <Content>
            <div className={ pageSectionInnerClassName }>
                { children }
            </div>
        </Content>
    </div>
);

export default PageSection;
