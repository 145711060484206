import { style } from 'typestyle';
import { MARGINS_PX } from '../../../constants';

export const spacerClassName = style({
    minHeight: MARGINS_PX + 'px'
});

export const spacerContentClassName = style({
    padding: MARGINS_PX + 'px'
});

export const spacer2XClassName = style({
    minHeight: (MARGINS_PX * 2) + 'px'
});

export const spacer2XContentClassName = style({
    padding: (MARGINS_PX * 2) + 'px'
});
