import {
    LEGAL_SOURCE_TRUE_ID,
    LOCAL_STORAGE_DATA_NAME,
    MESSAGE_NOT_AVAILABLE_IN_DATE
} from '../constants';
import {
    encode,
    getRandomElementFromArray,
    filterMostRecentCountryProfiles,
    getMostRecentDate,
    filterCountryResult
} from '../utils';
import {
    AppState,
    CountryProfile,
    CountryProfileFilters,
    CountryProfileResult
} from './types';

export const initActions = (
    state: AppState,
    changeState: React.Dispatch<React.SetStateAction<AppState>>
) => {
    const setState = (stateUpdates: Partial<AppState>) => {
        changeState({
            ...state,
            ...stateUpdates
        });
    };

    const setSession = (
        username: string,
        token: string
    ) => {
        window.localStorage.setItem(
            LOCAL_STORAGE_DATA_NAME,
            encode({username, token})
        );

        changeState({
            ...state,
            ...{
                session: {
                    username,
                    token
                }
            }
        });
    };

    const setRandomFeaturedCountryId = () => {
        const featuredCountry = getRandomElementFromArray(state.taxonomies.countries);

        if (featuredCountry) {
            changeState({
                ...state,
                featuredCountryId: featuredCountry.id
            });
        }
    };

    const clearSession = () => {
        window.localStorage.removeItem(LOCAL_STORAGE_DATA_NAME);

        changeState({
            ...state,
            session: null
        });
    };

    const getPage = (pageName: string) => {
        for (const page of state.pages) {
            if (page.name === pageName) {
                return page;
            }
        }

        return null;
    };

    const getCountryProfile = (countryId: number, date: string | null): CountryProfile | null => {
        if (date === null) {
            return filterMostRecentCountryProfiles(state.countryProfiles, getMostRecentDate(state.taxonomies.dates.map(date => date.name)))
                .filter(profile => profile.countryId === countryId)[0] || null;
        }
        
        return state.countryProfiles
            .filter(profile => (profile.countryId === countryId) && (profile.date === date))[0] || null;
    };

    const getCountryProfileList = (filters?: CountryProfileFilters): CountryProfile[] => {
        let result: CountryProfile[] = [...state.countryProfiles];

        if (filters && Object.keys(filters).length) {
            if (filters.date) {
                result = result.filter(countryResult => countryResult.date === filters.date);
            }
            else if (filters.date === null) {
                result = filterMostRecentCountryProfiles(result, getMostRecentDate(state.taxonomies.dates.map(date => date.name)));
            }

            if (filters.countryIds.length) {
                result = result.filter(countryResult => filters.countryIds.indexOf(countryResult.countryId) !== -1);
            }
        
            if (filters.regionIds.length) {
                result = result.filter(countryResult => filters.regionIds.indexOf(countryResult.regionId) !== -1);
            }
    
            if (filters.electoralSystemIds.length) {
                result = result.filter(countryResult => filters.electoralSystemIds.indexOf(countryResult.electoralSystemTypeId) !== -1);
            }
        
            if (filters.typeOfQuotaIds.length) {
                result = result.filter(countryResult => {
                    for (const typeOfQuotaId of filters.typeOfQuotaIds) {
                        if (countryResult.typeOfQuotaIds.indexOf(typeOfQuotaId) !== -1) {
                            return true;
                        }
                    }
    
                    return false;
                });
            }
        
            if (filters.legalSourcesIds.length) {
                result = result.filter(countryResult => {
                    for (const id of filters.legalSourcesIds) {
                        if ((id === 1) && (countryResult.constitutionProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                        if ((id === 2) && (countryResult.electoralProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                        if ((id === 3) && (countryResult.financingProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                        if ((id === 4) && (countryResult.parityProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                        if ((id === 5) && (countryResult.partyProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                        if ((id === 6) && (countryResult.politicalProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                    }
        
                    return false;
                });
            }
        }

        return result;
    };

    const getCountryProfileResults = (filters: CountryProfileFilters): CountryProfileResult[] => {        
        // FIRST STEP: Get the list of country ids for this search
        let selectedCountryIdList = filters.countryIds.length
            ? filters.countryIds
            : state.taxonomies.countries.map(countryTaxonomy => countryTaxonomy.id);

        // SECOND STEP: Filter country profiles by countryId
        let resultCountryProfileList = state.countryProfiles
            .filter(countryProfile => selectedCountryIdList.indexOf(countryProfile.countryId) !== -1);

        // THIRD STEP: Filter country profiles by date
        if (filters.date === null) {
            resultCountryProfileList = filterMostRecentCountryProfiles(
                resultCountryProfileList,
                getMostRecentDate(state.taxonomies.dates.map(date => date.name))
            );
        }
        else {
            resultCountryProfileList = resultCountryProfileList
                .filter(countryProfile => countryProfile.date === filters.date);
        }

        // FOURTH STEP: Build final results and set message for not available countries in selected date
        const resultCountryProfileDict: {[countryId: number]: CountryProfile} = {};
        resultCountryProfileList.forEach(countryProfile => {
            resultCountryProfileDict[countryProfile.countryId] = countryProfile
        });

        let results: CountryProfileResult[] = selectedCountryIdList
            .map(countryId => ({
                countryId,
                countryProfile: resultCountryProfileDict[countryId] || null,
                message: resultCountryProfileDict[countryId] ? null : MESSAGE_NOT_AVAILABLE_IN_DATE
            }));

        // FIFTH STEP: Remove not available country results under specific condition
        if (
            (selectedCountryIdList.length === state.taxonomies.countries.length)
            && (filters.countryIds.length < state.taxonomies.countries.length)
            && (
                filters.regionIds.length
                || filters.electoralSystemIds.length
                || filters.typeOfQuotaIds.length
                || filters.legalSourcesIds.length
            )
        ) {
            console.log('CHECK CHECK')
            results = results.filter(countryResult => countryResult.countryProfile);
        }



        // results = results.filter(countryResult => {
        //     const countryIdIsAskedFromFilters = filters.countryIds.indexOf(countryResult.countryId) !== -1;
        //     const existsCountryFilters = Boolean(filters.countryIds.length);
        //     const existsOtherFilters = Boolean(
        //         filters.regionIds.length
        //         || filters.electoralSystemIds.length
        //         || filters.typeOfQuotaIds.length
        //         || filters.legalSourcesIds.length
        //     );

        //     return true;
        // });

        // SIXTH STEP: Remove not available country results if some filters exists


        // SEVENTH STEP: Filter results by all the other filters and return it
        if (filters.regionIds.length) {
            results = results.filter(filterCountryResult(
                countryProfile => filters.regionIds.indexOf(countryProfile.regionId) !== -1
            ));
        }

        if (filters.electoralSystemIds.length) {
            results = results.filter(filterCountryResult(
                countryProfile => filters.electoralSystemIds.indexOf(countryProfile.electoralSystemTypeId) !== -1
            ));
        }
    
        if (filters.typeOfQuotaIds.length) {
            results = results.filter(filterCountryResult(countryProfile => {
                for (const typeOfQuotaId of filters.typeOfQuotaIds) {
                    if (countryProfile.typeOfQuotaIds.indexOf(typeOfQuotaId) !== -1) {
                        return true;
                    }
                }

                return false;
            }));
        }
    
        if (filters.legalSourcesIds.length) {
            results = results.filter(filterCountryResult(countryResult => {
                for (const id of filters.legalSourcesIds) {
                    if ((id === 1) && (countryResult.constitutionProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                    if ((id === 2) && (countryResult.electoralProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                    if ((id === 3) && (countryResult.financingProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                    if ((id === 4) && (countryResult.parityProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                    if ((id === 5) && (countryResult.partyProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                    if ((id === 6) && (countryResult.politicalProvisionsGenderQuotaId === LEGAL_SOURCE_TRUE_ID)) return true;
                }
    
                return false;
            }));
        }

        return results;
    };

    return {
        setState,
        setSession,
        setRandomFeaturedCountryId,
        clearSession,
        getPage,
        getCountryProfile,
        getCountryProfileList,
        getCountryProfileResults
    };
};
